@charset "utf-8";
/* CSS Document */
.uldown {
	padding-left: 0px;
	min-width: 325px;
	width: 100%;
	text-align: left;
	position: absolute; 
	z-index: 99;
}
.lidown {
	display: block;
	padding: 5px;
	border: 0.5px dashed rgb(255, 165, 0);
	width: 100%;
	background-color: #fff;
	cursor: pointer;
	min-height: 50px;
	height: auto;
	font-size: 12px;
	line-height: 20px;
}
.lidown:hover {
	background-color: #D3CECF;
	cursor: pointer;
}
