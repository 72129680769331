/* 
.Header__AccountDetails__optIn__fc .Header__AccountDetails__message__fc,.Header__AccountDetails__optIn__fc p {
    text-align: left
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__info__fc,.Header__AccountDetails__login__fc .Header__AccountDetails__info__fc,.Header__AccountDetails__optIn__fc .Header__AccountDetails__info__fc {
    margin: 22px 0 33px;
    padding: 0 40px;
    font-weight: 400;
    font-size: 14px;
    color: #676767;
    text-align: center;
    line-height: 20px
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__msg__fc,.Header__AccountDetails__login__fc .Header__AccountDetails__msg__fc,.Header__AccountDetails__optIn__fc .Header__AccountDetails__msg__fc {
    display: none;
    content: "";
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #fff;
    background: #d6101b;
    padding: 9px 10px 7px
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__msg__fc:after,.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__msg__fc:before,.Header__AccountDetails__login__fc .Header__AccountDetails__msg__fc:after,.Header__AccountDetails__login__fc .Header__AccountDetails__msg__fc:before,.Header__AccountDetails__optIn__fc .Header__AccountDetails__msg__fc:after,.Header__AccountDetails__optIn__fc .Header__AccountDetails__msg__fc:before {
    position: absolute;
    left: 14px;
    border: 14px solid transparent;
    content: ""
}
@media(max-width: 600px) {
    .Header__AccountDetails__forgot_password__fc .Header__AccountDetails__value__fc,.Header__AccountDetails__login__fc .Header__AccountDetails__value__fc,.Header__AccountDetails__optIn__fc .Header__AccountDetails__value__fc {
        font-size:16px
    }
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__value__fc:focus,.Header__AccountDetails__login__fc .Header__AccountDetails__value__fc:focus,.Header__AccountDetails__optIn__fc .Header__AccountDetails__value__fc:focus {
    border-bottom-color: #0072c6
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__error__fc,.Header__AccountDetails__forgot_password__fc label.Header__AccountDetails__error__fc,.Header__AccountDetails__login__fc .Header__AccountDetails__error__fc,.Header__AccountDetails__login__fc label.Header__AccountDetails__error__fc,.Header__AccountDetails__optIn__fc .Header__AccountDetails__error__fc,.Header__AccountDetails__optIn__fc label.Header__AccountDetails__error__fc {
    z-index: 9;
    position: relative
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__link__fc,.Header__AccountDetails__login__fc .Header__AccountDetails__link__fc,.Header__AccountDetails__optIn__fc .Header__AccountDetails__link__fc {
    cursor: pointer;
    width: 50%;
    text-align: right;
    display: inline-block;
    color: #0072c6
}

.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__link__fc,.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__link__fc:hover,.Header__AccountDetails__forgot_password__fc .Header__AccountDetails__link__fc:visited,.Header__AccountDetails__login__fc .Header__AccountDetails__link__fc,.Header__AccountDetails__login__fc .Header__AccountDetails__link__fc:hover,.Header__AccountDetails__login__fc .Header__AccountDetails__link__fc:visited,.Header__AccountDetails__optIn__fc .Header__AccountDetails__link__fc,.Header__AccountDetails__optIn__fc .Header__AccountDetails__link__fc:hover,.Header__AccountDetails__optIn__fc .Header__AccountDetails__link__fc:visited {
    text-decoration: none
}

.Header__AccountDetails__langMenu__fc li:hover {
    background: #f7f5f5
}

#Header__WatchThisTripDialog__app__fc,#Header__WatchThisTripDialog__app__fc input {
    box-sizing: border-box
}

#modal.Header__WatchThisTripDialog__wtt__fc {
    background-color: rgba(0,0,0,.8)
}

.Header__WatchThisTripDialog__dialog__fc {
    margin: auto;
    position: relative;
    padding-bottom: 20px;
    width: 320px
}

.Header__WatchThisTripDialog__alert__fc {
    text-align: center;
    vertical-align: middle;
    background: #fff;
    color: #676767;
    font-size: 16px;
    display: inline-block;
    width: 450px;
    overflow: auto;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 100vh
}

.Header__WatchThisTripDialog__alert__fc * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

@media(min-width: 320px) and (max-width:414px) {
    .Header__WatchThisTripDialog__alert__fc {
        max-height:calc(100vh - 80px)
    }
}

.Header__WatchThisTripDialog__body__fc {
    padding: 0 30px
}

@media(max-width: 639px) {
    .Header__WatchThisTripDialog__body__fc {
        padding:0 20px
    }
}

.Header__WatchThisTripDialog__disclosure__fc {
    padding: 0 20px
}

.Header__WatchThisTripDialog__disclosure__fc .Header__WatchThisTripDialog__hr__fc {
    position: relative;
    margin: 17px 0 24px
}

.Header__WatchThisTripDialog__disclosure__fc .Header__WatchThisTripDialog__hr__fc:after {
    content: "";
    height: 1px;
    background: #e4e6e7;
    clear: both;
    top: 0;
    left: -20px;
    position: absolute;
    right: -20px
}

.Header__WatchThisTripDialog__disclosure__fc .Header__WatchThisTripDialog__agreement__fc {
    color: #676767;
    line-height: 21px
}

.Header__WatchThisTripDialog__disclosure__fc label {
    position: relative;
    width: calc(100% - 45px);
    display: block;
    font-size: 12px;
    color: #676767;
    line-height: 1.3;
    margin-left: 10px
}

.Header__WatchThisTripDialog__disclosure__fc label input[type=checkbox] {
    margin-right: 20px!important
}

.Header__WatchThisTripDialog__disclosure__fc p {
    margin-top: 0
}

.Header__WatchThisTripDialog__disclosure__fc,.Header__WatchThisTripDialog__disclosure__fc a {
    font-size: 13px;
    color: #8b8f9f;
    line-height: 18px;
    text-align: left
}

.Header__WatchThisTripDialog__disclosure__fc a {
    text-decoration: underline
}

.Header__WatchThisTripDialog__disclosure__fc .Header__WatchThisTripDialog__ca__fc:after,.Header__WatchThisTripDialog__disclosure__fc .Header__WatchThisTripDialog__cb__fc:before {
    content: "";
    display: table;
    clear: both
}

#Header__HeaderModalDialog__app__fc,#Header__HeaderModalDialog__app__fc input {
    box-sizing: border-box
}

.Header__HeaderModalDialog__frame__fc {
    background: #fff;
    -webkit-box-shadow: 0 3px 6px 0 hsla(0,0%,54%,.7);
    box-shadow: 0 3px 6px 0 hsla(0,0%,54%,.7);
    max-height: 100vh;
    overflow: auto;
    display: -ms-flexbox;
    display: flex
}

.Header__HeaderModalDialog__frame__fc:after {
    display: table;
    clear: both;
    content: ""
}

.Header__HeaderModalDialog__wrap__fc {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-weight: 100;
    position: relative
}

.Header__HeaderModalDialog__modal__fc,.Header__HeaderModalDialog__wrap__fc,.Header__HeaderModalDialog__wrap__fc * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.Header__HeaderModalDialog__modal__fc {
    display: table;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: auto;
    padding-top: 60px
}

.Header__HeaderModalDialog__title_bar__fc.Header__HeaderModalDialog__default_caption__fc {
    padding: 35px 0 12px;
    border-bottom: 1px solid #e4e6e7
}

.Header__HeaderModalDialog__title_bar__fc:after {
    display: table;
    clear: both;
    content: ""
}

.Header__HeaderModalDialog__title_bar__fc.Header__HeaderModalDialog__hide_close__fc:after {
    display: none
}

#modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: hsla(0,0%,100%,.5);
    display: none;
    z-index: 12
}

#modal.Header__HeaderModal__active__fc {
    display: block;
}

.Header__ErrorBoundary__errorPage__fc {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    background: #f7f5f5
}




.WatchThisTrip__header__fc {
    background: url(../images/banner/popup.jpg) no-repeat;
    background-size: 450px;
    width: 450px;
    height: 210px;
    display: block;
    margin-bottom: 15px;
    position: relative
}

.WatchThisTrip__header__fc button.WatchThisTrip__close__fc:before {
    transform: rotate(45deg)
}

.WatchThisTrip__header__fc button.WatchThisTrip__close__fc:after {
    transform: rotate(-45deg)
}

.WatchThisTrip__header__fc h3 {
    text-shadow: 0 1px 4px #00508b;
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    margin: 0;
    padding-top: 40px
}

.WatchThisTrip__header__fc .WatchThisTrip__alarm__fc {
    /* background: url(../images/banner/price-alert.2024-3-4.gif) no-repeat 50%; 
    display: inline-block;
    position: absolute;
    width: 53px;
    background-size: cover;
    height: 44px;
    bottom: 26px;
    left: calc(50% - 25px)
}

.WatchThisTrip__button__fc button {
    cursor: pointer;
    background: #d1265c;
    border: 1px solid #b61044;
    font-family: Roboto,sans-serif;
    line-height: 46px;
    font-weight: 400;
    font-size: 17px;
    color: #fff;
    width: calc(100% - 56px);
    margin: 0 28px
}

.WatchThisTrip__button__fc button:focus {
    border: none;
    outline: none
}

.WatchThisTrip__button__fc.WatchThisTrip__inProgress__fc button {
    background: #f7f5f5;
    color: #2c84cc;
    border-color: #e4e6e7
}

.WatchThisTrip__info__fc {
    font-weight: 100;
    font-size: 18px;
    color: #0072c6;
    line-height: 23px;
    margin: 0 100px 15px;
    display: inline-block
}

.WatchThisTrip__trip__fc {
    border-top: 1px solid #e4e6e7;
    border-bottom: 1px solid #e4e6e7;
    margin-bottom: 1em;
    white-space: nowrap
}

.WatchThisTrip__icon__fc {
    background: url(../images/banner/plane.2024-3-4.png) no-repeat;
    background-size: 44px;
    width: 44px;
    height: 40px;
    margin: 19px 16px 0 19px;
    display: inline-block
}

.WatchThisTrip__depart__fc {
    text-align: right
}

.WatchThisTrip__depart__fc,.WatchThisTrip__return__fc {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
    vertical-align: top
}

.WatchThisTrip__return__fc {
    text-align: left;
    font-size: 0
}

.WatchThisTrip__code__fc {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color: #333;
    display: block
}

.WatchThisTrip__date__fc {
    display: block;
    font-weight: 400;
    font-size: 15px;
    color: #676767
}
.call-btn a {
    background: #005dba;
    color: #fff;
    padding: 5px 5px;
    text-align: center;
    display: block;
    border-radius: 27px;
    font-size: 20px;
    margin-bottom: 5px;
    text-decoration: none;
    width:50%;
   margin-left: 115px;
}
.call-btn a:hover {
    color: gray;
    text-decoration: none;
}
.closeBtn-banner {
    border-radius: 40px;
    background: aliceblue;
    display: block;
    height: 26px;
    margin: 0 auto;
    margin-left: 899px;
    overflow: hidden;
    position: sticky;
    right: -18px;
    text-indent: 0;
    top: -10px;
    width: 29px;
    cursor: pointer;
}
.timer{
    font-size: 13px;
}

@media screen and (max-width: 768px) {
    .Header__HeaderModalDialog__wrap__fc {
        display: contents;
        vertical-align: middle;
        font-weight: 100;
        position: relative
    }
    .Header__WatchThisTripDialog__alert__fc {
        text-align: center;
    vertical-align: middle;
    background: #fff;
    color: #676767;
    font-size: 16px;
    display: inline-block;
    width: 381px;
    overflow: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
    }
    .WatchThisTrip__header__fc {
        background: url(../images/banner/popup.jpg) no-repeat;
        background-size: 382px;
        width: 382px;
        height: 188px;
        display: block;
        margin-bottom: 15px;
        position: relative;
    }
    .closeBtn-banner {
        border-radius: 40px;
        background: aliceblue;
        display: block;
        height: 26px;
        margin: 0 auto;
        margin-left: 350px;
        overflow: hidden;
        position: sticky;
        right: -18px;
        text-indent: 0;
        top: -10px;
        width: 29px;
        cursor: pointer;
    }
    .call-btn a {
        background: #005dba;
        color: #fff;
        padding: 5px 5px;
        text-align: center;
        display: block;
        border-radius: 27px;
        font-size: 20px;
        margin-bottom: 5px;
        text-decoration: none;
        width: 50%;
        margin-left: 100px;
    }
    .count-top-icon{
        display: none !important
    }
}


@media only screen and (min-width:1370px) and (max-width:2700px) {
    .Header__WatchThisTripDialog__alert__fc {
        text-align: center;
        vertical-align: middle;
        background: #fff;
        color: #676767;
        font-size: 16px;
        display: inline-block;
        width: 610px;
        overflow: auto;
        margin-top: auto;
        margin-bottom: auto;
        max-height: 100vh;
    }
    .WatchThisTrip__header__fc {
        background: url(../images/banner/popup.jpg) no-repeat;
        background-size: 610px;
        width: 610px;
        height: 289px;
        display: block;
        margin-bottom: 15px;
        position: relative;
    }
    .WatchThisTrip__header__fc .WatchThisTrip__alarm__fc {
        /* background: url(../images/banner/price-alert.2024-3-4.gif) no-repeat 50%; 
        display: inline-block;
        position: absolute;
        width: 74px;
        background-size: cover;
        height: 75px;
        bottom: 26px;
        left: calc(50% - 37px);
    }
    .WatchThisTrip__info__fc {
        font-weight: 100;
        font-size: 27px;
        color: #0072c6;
        line-height: 23px;
        margin: 0 100px 15px;
        display: inline-block;
    }
    .WatchThisTrip__icon__fc {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAoCAMAAACyy+glAAABdFBMVEX///9vr98Cc8YAcsYNecmHveT9/v+11u4DdMcEdMfE3vL+/v/4+/0SfMoui9D3+/1dpdsBc8aQwuay1O4Tfcrn8vr6/P4UfcpapNqu0u2nzusXf8vv9vvt9fsHdshtrt5EmNXJ4fOOweY4kdPY6faRwuaDuuN0suB/uOLu9vswjdEJd8hBltVImtaLv+VAldSPwebf7fioz+zc7PcFdccKeMjS5vUxjdEeg83z+PwvjNEti9D7/f70+f3g7vhprN1qrd7Z6vfh7vh3tOEYf8sdgswWfssQe8pRn9iq0Oz8/f672fA2kNL1+f0LeMgyjtGw0+1Dl9Xl8flrrd4khs4oiM8jhc4fg80agMwPeskpic++2/BzsuDR5vXe7fgmh84ritChy+rV6Pa82vBgp9t+uOJToNmjzOqWxeiizOr5/P4Re8rk8PnN4/Spz+yCuuNLm9c3kNKbyOkniM9QnthwsN/y+PxPntjw9/zX6fZorN2YxujGyGN3AAABh0lEQVR4AY3OCV9MURzH4Z/zZdw60+SgiSTLjCGTKDMYItmyDpKy72TPvr55zZFuPnN9/J9X8Gi5FY6Vq3KyWZ1AR6eXSb4LKHTLZk0A1nqZrEuA9T0yKfYCbPD2B26jl0WfA9jUL4vNA7Rs8bLYGgA6tslie6Cl5JXFey1XDkQ7lKWyc9egUtpNVC0rw1BgT1Gp4UC016vdPgfDOS0ZcUSMqt3+GtQPKHWQiEMNtTsc4EjDey+NHR3sOTbOouPlJRMncopOBuDU6TOT1YS/hcgtSJKus+fOS7rgsAjJxRHpUhObUJD6B7AJl6UrDpNwdUq6FiBMF67PzN7ovVmvkSm5NX47J/kZoJTXH/4Oiyr537oXjHkvSXcddN1T6j4RD4pqUwm4h0o9IiI8VrtSkjyRUk+JeKYMc89fvFRK00SvXuv/3hC5tzKYJ3oni/e01CdkMOUAwrwsPtDyMS+LTwC1Tlk0mkD4LJM5gNkvsvBfAdcnk29VCENeJqNA87tM/A+Y/Kl/+wVmJS8HxsGh0QAAAABJRU5ErkJggg==) no-repeat;
        background-size: 44px;
        width: 46px;
        height: 40px;
        margin: 19px 38px 0 42px;
        display: inline-block;
    }
    .closeBtn-banner {
        border-radius: 40px;
        background: aliceblue;
        display: block;
        height: 26px;
        margin: 0 auto;
        margin-left: 67%;
        overflow: hidden;
        position: sticky;
        right: -18px;
        text-indent: 0;
        top: -10px;
        width: 29px;
        cursor: pointer;
    }
    .timer{
        font-size: 20px;
    }
}












.count-top-icon {
    position: fixed;
    bottom: 0px;
    border-radius: 3px;
    padding: 0px 10px 0 2px;
    color: #fff;
    /* background: #0a2d72; 
    background: #ef5a5a;
    font-weight: 600;
    transition: width 2s;
    -webkit-transition: width 2s;
    left: 10px;
    cursor: pointer;
    z-index: 2;
}
.count-top-icon .strip-content {
    font-size: 18px;
    text-align: left;
} */









body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    /*background-color: #fff;*/
  }
#mask {
    position: fixed !important;
    left: 0;
    top: 0;
    background: #b9b9b5a8;
    /* display: none; */
    height: 100% !important;
    width: 100% !important;
    z-index: 9;
}

#boxed .window {
    /* display: none; */
    left: 37%;
    position: fixed;
    top: 90px !important;
    z-index: 10;
    width: 450px;
}

.text-success2 {
    color: #0b4075;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: solid 1px #ee372b;
    padding-bottom: 6px;
}

.closed {
    display: block;
    height: 30px;
    margin: 0 auto;
    /*opacity: .75;*/
    overflow: hidden;
    position: absolute;
    right: -18px;
    text-indent: 0;
    top: -10px;
    width: 30px;
}
 
/*******************************************************************/
.wish12 {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 2;
    width: 400px;
}


.content_bxmain {
    height: 100%; /*calc(86vh - 80px)*/
    background-color: #fff;
}

.content_left {
    width: 100%;
    float: left;
}

.content_right {
    width: 100%;
    float: left;
}

/* .visible-xs {
    /* display: none !important; 
} */

.count-content-list {
    padding: 6px 15px 0;
    color: #3b3b3b;
}

.block-time .logos img {width: 140px;}

.block-time {
    font-size: 13px;
    margin-bottom: 20px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block-time .time-limited {
        color: #333;
    }

/* .Enddeal {
    /*display: flex;
    align-items: center;
} */
.Enddeal p {
    margin: 0px;
}

.block-time .timerr {
    font-size: 24px;
    font-weight: 700;
    min-height: 23px;
    color: #cf2a2f;
}

.fare-special {
    margin: 15px 0;
}

.fare-special .price-hot {
        font-size: 12px;
        float: right;
        text-align: right;
        line-height: 2em;
    }

.fare-special .txt-special {
        color: #404040;
        font-size: 15px;
        font-weight: 700;
    }

.type-tripclass {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left;
    
}

.section-itenerary {
    min-height: 50px;
    line-height: 1.8em;
}

.relative {
    position: relative;
}

.section-itenerary .arrow-trip-type {
    border-bottom: 1px solid #404040;
    display: block;
    width: 50%;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%,-50%);
}

.section-itenerary .icon-trip-type {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
    background: #fff;
    padding: 0 5px;
}

.section-itenerary .icon-trip-type img { width: 70px;}

.city-code2.right {
    float: right;
    text-align: right;
}

.section-itenerary .city-code2 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: #404040;
    line-height: 20px;
}

.name-city {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 13px;
}

.name-city .name-return {
        float: right;
        text-align: right;
    }

.callnow-btn a {
    background: #ff6601;
    color: #fff;
    padding: 0px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    font-size: 25px;
    text-decoration: none;
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
}
.callnow-btn a img { border-radius: 4px;}

.callnow-btn a:hover {
    background: #0482ff;
    
}
.txt-disclaimer {
    color: #000;
    text-align: center;
    font-size: 12px;
    padding: 10px 0;
}

.section-itenerary .city-code2 b {
    font-size: 20px;
    display: block;
}

.fare-special .txt-special b {
    font-size: 18px;
    display: block;
}

.fare-special .price-hot b {
    color: #3ab54a;
    display: block;
    font-size: 28px;
}

.timeoffer {margin-bottom: 0;}
.price_ {color: #ff6601; font-weight: bold;}

/******************************************************************/
/** RESONSIVE CSS START **/
@media only screen and (max-width:1025px) {
    #boxed .window {
        display: block;
        width: 100%;
        left: 0 !important;
        top: 50px !important;
    }

    #boxed {
        display: block;
    }

    .content_bxmain {
        width: 90%;
        margin: 10px auto;
    }

    .content_left {
        width: 100%;
        float: left;
    }

    .content_right {
        width: 100%;
        float: left;
    }
    .WatchThisTrip__alarm__fc {
        /* background: url(../images/banner/price-alert.2024-3-4.gif) no-repeat 50%; */
        display: inline-block;
        position: absolute;
        width: 74px;
        background-size: cover;
        height: 211px;
        bottom: 26px;
        left: calc(50% - 27px)
    }
    .count-top-icon {
        position: fixed;
        bottom: 0px;
        border-radius: 3px;
        padding: 0px 10px 0 2px;
        color: #fff;
         background: #0a2d72; 
        background: #ef5a5a;
        font-weight: 600;
        transition: width 2s;
        -webkit-transition: width 2s;
        left: 10px;
        cursor: pointer;
        z-index: 2;
    }
    .count-top-icon .strip-content {
        font-size: 18px;
        text-align: left;
    }
}

@media (max-width:767px) {
    
    .count-content-list {
        padding: 6px 15px 0;
    }			
    .closed {			
     right: 0px;			
     top: 0px;			
    }
    
    #boxed .window {
        display: block;
        width: 100%;
        left: 0 !important;
        top: 50px !important;
    }
    
    .timeoffer {margin-bottom: 0; font-size: 12px;}
    .price_ {color: #ff6601; font-weight: bold; font-size: 20px;}
    
}
.WatchThisTrip__alarm__fc {
    /* background: url(../images/banner/price-alert.2024-3-4.gif) no-repeat 50%; */
    display: inline-block;
    position: absolute;
    width: 74px;
    background-size: cover;
    /* height: 229px; */
    margin-bottom: 209px;
    bottom: 26px;
    left: calc(50% - 27px);
}
.count-top-icon {
    position: fixed;
    bottom: 0px;
    border-radius: 3px;
    padding: 0px 0px 0 2px;
    color: #fff;
     background: #0a2d72; 
    background: #ef5a5a;
    /* font-weight: 600; */
    transition: width 2s;
    -webkit-transition: width 2s;
    left: 10px;
    cursor: pointer;
    z-index: 2;
}
.count-top-icon .strip-content {
    font-size: 18px;
    text-align: left;
}


@media (max-width:767px) {
    .WatchThisTrip__alarm__fc {
        /* background: url(../images/banner/price-alert.2024-3-4.gif) no-repeat 50%; */
        display: inline-block;
        position: absolute;
        width: 74px;
        background-size: cover;
        height: 229px;
        bottom: 26px;
        left: calc(50% - 27px);
    }
    .count-top-icon .strip-content {
        display: none !important;
    }
 }