@charset "utf-8";
/* CSS Document */
html, body {margin: 0px; padding: 0px; overflow-x: hidden; background-color: #f9f9f9; }

/*********contactBox***********/
.textBox {
  width: 100%;
  padding: 40px 0px 10px 0;
  font-size: 15px;
  line-height: 30px;
}

.contactBox {
	padding-top: 0px;
}
.contactBox h3 {
	padding-bottom: 10px
}
.contactBox .rightBlock {
	padding-left: 60px
}

@media (max-width: 1023px) {
.contactBox .rightBlock {
	padding-left: 0px
}
}
.contactBox .holder {
	display: flex;
	justify-content: space-between;
	padding: 50px
}

@media (max-width: 576px) {
.contactBox .holder {
	flex-direction: column;
	padding: 40px 0
}
}
.contactBox .block {
	padding: 15px;
	width: 100%;
	margin-bottom: 4px;
	text-align: center;
	background: #efebeb;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column
}
.contactBox .block .icons {
	font-size: 22px;
	width: 40px;
	height: 40px;
	border: 1px solid #ff6601;
	text-align: center;
	margin: 0 10px 10px 0
}
.contactBox .block h4 {
	font-size: 20px
}
.contactBox .block p {
	margin-bottom: 0px
}
.contactBox .block a {
	color: #5c6272;
	font-size: 18px;
	font-weight: 600;
	text-decoration: none
}
.contactBox .block a.email {
	font-size: 18px
}

@media (max-width: 1023px) {
.contactBox .block a.email {
	font-size: 15px
}
}
/**********************/
.content_page_title {
	/* background-color: #9EA1A1; */
	/* padding: 30px 15px;
	text-align: center;
	height: 135px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-image: url(../images/about&term/About-us-2.webp); */
	background-color: rgba(0, 33, 66, .4);
    color: #fff;
    height: 200px;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    text-shadow: 1px 2px 2px #222;
    background-image: url();
    background-position: center;
    background-size: cover;
}

@media (max-width: 576px) {
.content_page_title {
	height: 80px
}
}
.content_page_title h1 {
	font-size: 40px;
	font-weight: 600;
	line-height: 44px;
	margin: 0px
}

@media (max-width: 576px) {
.content_page_title h1 {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center
}
}
.content_page_title h1 img {
	margin-right: 8px
}

@media (max-width: 576px) {
.content_page_title h1 {
	font-size: 21px;
	white-space: nowrap
}
}
.content_page_title h1:before {
	margin-right: 10px;
	display: none;
	width: 50px;
	height: 1px;
	content: "";
	vertical-align: middle;
	background: rgba(255,255,255,0.3)
}
.content_page_title h1:after {
	margin-left: 10px;
	display: none;
	width: 50px;
	height: 1px;
	content: "";
	vertical-align: middle;
	background: rgba(255,255,255,0.3)
}

/*****************/
.contactForm .form-label {
	font-weight: 600
}
.contactForm .form-control {
	padding: 10px 12px;
	border-radius: 0px
}
.contactForm .submitBtn {
	background-color: #ff6601;
	border-color: #ff6601;
	border-radius: 0px;
	padding: 10px 32px;
	color: #333;
	font-weight: 600;
	border: none
}
.contactForm .capcha-txt {
	color: #fff;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
	padding: 3px 20px 3px 12px;
	background: #3a3a3a;
	display: flex;
	align-items: center;
	justify-content: space-evenly
}

/*********sitemap**********/
.sitemap-wrap {
	display: block;
	color: #5c6272;
	margin-bottom: 30px
}
.site_map_links {
	list-style: none;
	padding: 0px;
	margin: 0;
	overflow: hidden
}
.site_map_links li {
	width: 23%;
	float: left;
	line-height: 33px;
	margin-right: 10px;
	background-color: azure;
	padding-left: 20px;
	margin-bottom: 3px;
}

@media (max-width: 1023px) {
.site_map_links li {
	width: 33.3%
}
}

@media (max-width: 576px) {
.site_map_links li {
	width: 47%;
}
}
.site_map_links li a {
	color: #333;
	position: relative;
	text-decoration: none;
	font-size: 14px;
	/*! padding: 8px; */
}

@media (max-width: 576px) {
.site_map_links li a {
	font-size: 13px
}
}
.site_map_links li a:before {
	content: '';
	width: 6px;
	height: 6px;
	background: #0080FF;
	position: absolute;
	left: -13px;
	top: 7px;
	border-radius: 100%
}



