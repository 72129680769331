@charset "utf-8";
/* CSS Document */
html, body {margin: 0px; padding: 0px; overflow-x: hidden; background-color: #f9f9f9; }

body {
	font-family: "Poppins", sans-serif;
	font-size: 14px; font-weight: normal;
}
input, button {
	font-family: "Poppins", sans-serif;
}
input:focus {
	outline: none !important;
}



/**********payment-container**start********/
.card-mainsummry {width: 65%; margin: 20px auto;}
.card-innersummry {width: 350px; float: left; font-size: 23px; color: #f60;}
.card-innersummry2 {width: 150px; float: left; font-size: 23px; color: #f60;}
.card-linewidth {background-color: #f60; height: 2px; width: 200px; float: left; margin: 20px 0px; margin-right: 10px;}

/*sticky_css*/
.wrapper-card { display: flex; }
.wrapper-card .is-sticky {top:80px !important;}
.confirm:disabled{
  background-color: #ccc;
}
/*sticky_css close*/


.card-detail-container {
	box-shadow: 0 1px 3px #dddddd, -1px 1px 3px #dddddd;
	width: 100% !important;
	margin: 1px 0% !important;
	background-color: #fff;
	padding: 10px;
    }

.new_payment_title { 
	font-size: 22px; 
	line-height: 22px; 
	background-color: #333;
    padding: 8px;
    color: #fff;
}

.new_payment_title2 { font-size: 22px; line-height: 22px; margin-top: 25px;}
.new_payment_title2 span {font-size:13px;}

.price_summary_title { 
	font-size: 22px; 
	line-height: 22px; 
	background-color: #333;
    padding: 8px;
    color: #fff;
}



.card-price-select button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #ff6701;
  width: 90%;
  max-width: 220px;
  height: 45px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;  
}

/*********************/

.dob_main .custom_select::after {
  content: "";
  border-right: 1px solid #ccc;
  position: absolute;
  right: 0;
  top: 6px;
  height: 28px;
}

.form-group {  
  padding: 2px 0px;
  font-size: 12px;
  width: 110px;
  margin-right: 0px;  
}

.form-group2 {  
  padding: 2px 0px;
  font-size: 12px;
  width: 100%;
  margin-right: 0px;
  height: 38px;
}

.devider{
	border-right: 1px solid #ddd;
    height: 34px;
    }


.form-select { 
  border: var(--bs-border-width) solid #dee2e600;
  border-radius: var(--bs-border-radius);
}

.show-error {
  color: red;
  font-size: 12px;
  font-weight: normal !important;
}
.pay-type {
  background-repeat: no-repeat;
  background-size: 55px auto;
  background-position: 98% 50%;
}

.padtop6 {padding-top: 7px;}

.margB8 {margin-bottom: 8px;}
.margB18 {margin-bottom: 18px;}
.fontshow {font-size: 18px; font-weight: 600;}

.card_title { font-size: 13px !important; font-weight: bold;}
.card_title label { margin-bottom: 5px;}
.card_title span { color: red;}

.fntsize {font-size: 12px; line-height: 16px; padding-left: 0px;}

.fntinpt {font-size: 13px; line-height: 24px;}

.cardgroup {display: flex; border: 1px solid #ddd; border-radius: 8px; width: 100%}
.zgroup { float: left; margin-bottom: 8px;}
.zgroup2 { float: left; margin-bottom: 8px;}
.zgroup3 { float: left; margin-bottom: 8px;}

/*******right side fare details*******/
.booked-amount-area ul {
	padding: 0;
	margin: 0;
}
.booked-amount-area ul li {
  display: flex;
  justify-content: space-between;
  padding: 4px 9px;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.booked-amount-area ul li:hover {
  background-color: #cdcdcd69;
}

.marb15 {margin-bottom: 15px;}

.car-sa-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
 font-size: 13px;	
}
.car-sa-row i {
  color: #008000;
  font-size: 15px;
  margin-right: 8px;
}

.booked-amount-area ul li span { font-weight: bold;}

.clonw {width: 44%;}

.note {margin-top: 10px; margin-bottom: 0px; font: normal 12px/18px "Poppins", sans-serif;}

.subTotal-booked h6 {
  font-size: 16px;
  font-weight: bold;  
  background-color: #333 !important;
  padding: 10px;
  color: #fff;
}

.subTotal-booked {
  padding-top: 1px;
  margin: 0 auto;
}

.details-submit {
  width: 100%;
  padding: 10px 2%;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  text-align: center;
  color: #303030;
}

@media (max-width: 767px) {
.card-mainsummry {
  width: 100%;
  margin: 20px auto;
}	
	
}

@media only screen and (max-width:768px) {
.zgroup { width:40%; float: left;}
.zgroup2 { width:30%; float: left;}
.zgroup3 { width:30%; float: left;}

}

.form-group label {
  font-size: 13px !important;
  font-weight: bold;
  margin-bottom: 5px;
}





