.cookie-notice {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: left;
    z-index: 10;
    background: rgba(51,51,51,.9);
    color: #fff;
    padding: 11px 50px;
}

.cookie-notice .accept {
    background: #005dba;
    padding: 5px 10px;
    color: #fff;
    font-weight: 600;
    border: none;
    margin-left: 10px;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
}