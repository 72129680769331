.tabcontent2 {
    padding: 10px 0;
    border: 1px solid #ccc;
    background: #fff;
  }
          
  .data a { line-height:35px; text-decoration:underline; }
.data a:hover { text-decoration:none; }
.note { margin-bottom:50px; }
.baggage_content2 {line-height: 25px; }
.data  img {outline: 1px solid #fff;outline-offset: -1px;border-radius: 8px; margin-right: 10px; width: 35px; margin-left: 10px;}
  .baggage-layer {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
    float: left;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #4F8FFC;
    padding: 10px;
    color: #fff;
    background: #4F8FFC;
    margin-bottom: 15px;
  }		
  .col-xs-8 {
    width: 66.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-6 {
  width: 50%;
}
  .row {
    margin-right: -15px;
    margin-left: -15px;
}       
  .airline-layer {
    float: left;
    width: 48%;
    margin-bottom: 15px;
    margin-left: 1%;
    margin-right: 1%;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
  }
  .text-center {
    text-align: center;
  }
  .data a {
    line-height: 35px;
    text-decoration: underline;
  }		
  .text-right {
    text-align: right;
}
          
  @media only screen and (min-width: 320px) and (max-width: 667px) {
    .baggage-layer {
      width: 98%;
      font-size: 13px;
    }
   .airline-layer {
      width: 98%;
    }	
      
      /* .hidden-xs {display: none;}	 */
      
  }

  @media only screen and (min-width : 668px) and (max-width : 1023px) {.baggage-layer{font-size: 14px;}}
  @media only screen and (min-width : 320px) and (max-width : 667px) {
  .baggage-layer {width: 98%;font-size: 13px; }
  .airline-layer {width: 98%;}   
  }