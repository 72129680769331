@charset "utf-8";
/* CSS Document */
/*********footer-area************/

.footer-area {
	background: #010007 url("../../resources/images/foot_banner/US-map-Footer-image.png");
	padding: 20px 0 0px 0;
	font-size: 14px;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-position-x: 50%;
    background-position-y: 65%;
}

/* .subscribe-submit:hover {
	 background-color: orange;
} */

@media (max-width: 1023px) {
.footer-area {
	padding: 10px 0 0 0
}
}

@media (max-width: 479px) {
.footer-area .social-media {
	text-align: center
}
}
.footer-area .social-media a {
	color: #3C78F1;
	display: inline-block;
	font-size: 20px;
	text-align: center;
	margin-right: 18px
}
.footer-area .social-media a img {
	width: 32px
}
.footer-area .footer-contact-links {
	margin: 0 0 20px 0;
	color: #fff;
}

@media (max-width: 1023px) {
.footer-area .footer-contact-links {
	font-size: 12px
}
}
.footer-area .footer-contact-links.mt10px {
	margin-top: 10px
}
.footer-area .footer-contact-links p {
	margin-top: 10px
}
.footer-area .footer-contact-links span {
	display: block;
	font-size: 13px
}
.footer-area .footer-contact-links .link {
	font-size: 11px
}
.footer-area .footer-contact-links .link a {
	color: #353541
}
.footer-area .footer-contact-links .link a:hover {
	color: #3C78F1
}
.footer-area .footer-contact-links .tfn a {
	font-size: 20px;
	color: #3C78F1;
	font-weight: bold;
	text-decoration: none
}

@media (max-width: 1023px) {
.footer-area .footer-contact-links .tfn a {
	font-size: 17px
}
}
.footer-area .top-footer h5 {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 10px
}

@media (max-width: 1023px) {
.footer-area .top-footer h5 {
	margin-top: 15px;
	font-size: 15px;
}
.footer-area .top-footer .quickLinks ul li {
    display: block;
    line-height: 26px;
    color: #fff;
    font-size: 13px;
}
}
.footer-area .top-footer h4 {
	color: #202020;
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 10px
}

@media (max-width: 1023px) {
.footer-area .top-footer h4 {
	margin-top: 15px;
	font-size: 20px
}
}
.footer-area .top-footer .holder {
	padding-top: 20px;
	border-top: 1px dashed #4e4e4e57
}
.footer-area .top-footer .quickLinks ul {
	list-style: none;
	margin: 0px;
	padding: 0px
}
.footer-area .top-footer .quickLinks ul li {
	display: block;
	line-height: 26px;
	color:#fff;
}
.footer-area .top-footer .quickLinks ul li a {
	color: #fff;
	text-decoration: none;
	font-size: 14px
}

@media (max-width: 576px) {
.footer-area .top-footer .quickLinks ul li a {
	font-size: 13px
}
}
.footer-area .top-footer .quickLinks ul li a:hover {
	color: #3C78F1
}
.footer-area .top-footer .quickLinks .social-media {
	padding-top: 10px
}
.footer-area .top-footer .quickLinks .link a {
	color: #fff;
	text-decoration: none;
	font-size: 13px
}
.footer-area .footer_logo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 91%
}
.footer-area .disclaimer {
	font-size: 15px;
	color: #353541;
	text-align: center;
	padding: 30px 0px 0px 0px
}

@media (max-width: 576px) {
.footer-area .disclaimer {
	font-size: 12px;
	padding: 35px 0px 0px 0px
}
}

.copyright-block {
	padding: 16px 0;
	background: #010007;
	margin-top: 5px;
	font-size: 14px
}
.copyright-block .copyright {
	text-align: center;
	margin: 0;
	color: #fff
}

/*********footer-area_close************/



