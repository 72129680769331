
.loader-box {
	position: relative;
	overflow: hidden
}
.loader-box:before {
	position: absolute;
	top: 0;
	left: -50%;
	z-index: 2;
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
	-webkit-animation: shine .75s infinite;
	animation: shine .75s infinite
}
.loader-box .img_responsive {
	width: 100%
}
.loader {
	margin: 0;
	height: 4px;
	width: 100%;
	position: relative;
	overflow: hidden
}
.loader #loading_increse {
	width: 1%;
	position: absolute;
	height: 4px;
	background-color: #4f8ffc;
	transition: all .5s
}
.circle-loader {
	margin-bottom: 3.5em;
	border: 4px solid #eee;
	border-left-color: #4f8ffc;
	border-top-color: #4f8ffc;
	animation: loader-spin 1.2s infinite linear;
	position: relative;
	display: block;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	margin: 0 auto
}

.loader_icon {
	background: url(../images/loader7.gif) center center no-repeat;
	width: 614px;
	height: 330px;
	margin: 0 auto 10px
}
.loading_block_content {
	font-size: 15px;
	color: #1d70fd;
	text-align: center;
	padding-bottom: 30px;
	line-height: 25px
}
.loading_block_content .searching {
	padding-bottom: 10px
}
@keyframes loader-spin {
0% {
transform:rotate(0deg)
}
100% {
transform:rotate(360deg)
}
}
@-webkit-keyframes shine {
100% {
left:125%
}
}
@keyframes shine {
100% {
left:125%
}
}

