@charset "utf-8";
/* CSS Document */
html, body {margin: 0px; padding: 0px; overflow-x: hidden; background-color: #f9f9f9; }

/*************Confirm*style****************/
.comfirm-summry {width: 100%; background-color: #f6f2f2; padding: 10px; }
.confirm-thanks {font-weight: 600; margin-bottom: 4px;}
.confirm-booked {margin-bottom: 4px;}
.confirm-booked span {font-weight: 600;}
/*****************************************/

.confirm-detail-container {  
  width: 94%;
  margin: 1px 3%;
  margin-bottom: 1px;
}
.cnfr-marb15 {margin-bottom: 15px;}

.confirm-boxsadow {
  background-color: #ffffff;
  border-radius: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px #dddddd, -1px 1px 3px #dddddd;
  padding: 10px;
}
.confirm-title h5 {
  background-color: #2276b2;
  color: #fff;
  margin-bottom: 0px;
  padding: 5px 8px 8px;
}

.confirm-detail-head {
	font-size: 15px;
	font-weight: 500;
	color: #303030;
	display: flex;
	justify-content: space-between;
}
.confirm-head-depar {color: #f60; font-weight: bold;}
.confirm-head-Baggage { font-size: 12px; }
.confirm-head-Baggage p { display: block; text-align: right;  font-size: 12px; }

.confirm-detail-row {	
	margin: 0px 0 15px;
	align-items: center;
	width: 100%;
	float: left;	
}

.confirm-mobl-respons {width: 27%; float: left; }
.confirm-mobl-respons-next {width: 70%; float: left; }

.confirm-detail-airline {
	display: flex;
	align-items: center;
}
.confirm-detail-airline img {
	width: 45px;
	height: auto;
}

.confirm-airline-name-fno {
	font-size: 14px;
	color: #505050;
	font-weight: 500;
	margin: 0 5px;
}

.confirm-airlineName {text-align: left; margin-bottom: 0px; font-size: 18px; font-weight: 600;}

.confirm-flightno {font-size: 12px;}
.cnfrm-arrivnext {color: #f60; font-size: 12px;}

.confirm-detail-from-to {
	display: flex;
	align-items: center;
}

.confirm-from-city {font-size: 13px; color: #505050;}
.confirm-from-time {font-size: 13px; color: #303030;}
.foncolr strong {color: #F88708;}


.confirm-detail-from, .confirm-detail-to {
	display: flex;
	flex-direction: column;
}

.confirm-ddinfo-durastop {
  align-items: center;
  margin: auto 20px;
  width: 39%;
}
.confirm-ddinfo-durastop {
  display: flex;
  flex-direction: column;
}

.confirm-duration, .payment-stop {
	font-size: 12px;
	color: #707070;
	margin: 3px;
}

.confirm-Detailsstop-graph {
  background-color: #f3b484;
  max-width: 170px;
  width: 100%;
  height: 1px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/****/
.tblwidth {margin-bottom: 10px; width: 100%;}
.tblhead {color: #929394; border-bottom: 1px solid #ddd;}
.tblpadd {padding: 10px;}
.tblrow {/*border-bottom: 1px solid #ddd*/ font-size: 14px;}

/*sticky_css*/
.wrapper-confirmation { display: flex; }
.wrapper-confirmation .is-sticky {top:80px !important;}
/*sticky_css close*/

@media (max-width: 767px) {
.confirm-detail-container {
	width: 100%;
	margin: 1px 0%;
	margin-bottom: 1px;
	}	
	
.confirm-mobl-respons {width: 100% !important; float: left; }
.confirm-mobl-respons-next {width: 100%; float: left; }
}

/*layover-divider*/
.confirm-layover-divider {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #777;
  border-bottom: 1px dashed #777;
  margin: 10px 0 20px;
}
.confirm-layover-divider {
  height: 10px;
  margin: -1px auto;
  padding: 5px auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.confirm-layover-divider span {
  background: #fff;
  padding: 0 10px;
  position: relative;
  top: 5px;
}
/*layover-divider close*/


.pay-booked h6 {
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  background-color: #2276b2 !important;
  padding: 10px;
  color: #fff;
}

.pay-booked {
  padding-top: 1px;
  margin: 0 auto;
}

.fonsmal {font-size: 12px;}

.confirm-fare-area ul {
	padding: 0;
	margin: 0;
}
.confirm-fare-area ul li {
  display: flex;
  justify-content: space-between;
  padding: 4px 9px;
  font-weight: 500;
  font-size: 15px;
  border-bottom: 1px solid #ddd;
  color: #575859;
  letter-spacing: 1px;
}
.confirm-fare-area ul li span { font-weight: 600;}

.tmcaply ul {margin: 0px; padding: 0px 20px;}
.tmcaply li{ font-size: 14px; line-height: 22px;}
/******/
.disclaimerbg {background-color: #e3eaf0; padding: 4px;}
.confirm-discseat { color: #f60; font-size: 16px;}
.confirm-seat-disc {padding: 2px 25px; font-size: 13px;}


