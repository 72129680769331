@charset "utf-8";
/* CSS Document */
/**********matrix*************/
.slick-prev {
	position: absolute;
	right: 50px;
	z-index: 1;
}
.slick-next {
	z-index: 1;
	position: absolute;
	top: 1px;
	right: 5px;
}
.matrix {
	margin: 10px 0 10px 0;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 1px 1px 3px #dddddd, -1px -1px 3px #dddddd, 1px -1px 3px #dddddd, -1px 1px 3px #dddddd;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	overflow: hidden;
}
.matrixtable {
	display: flex;
	overflow: hidden;
}
.matrix-head-row {
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.matrix-head {
	color: #222;
	font-size: 15px;
	font-weight: 500;
	margin: 0 10px;
}
.matrix-slide-button {
	border: 0;
	outline: 0;
	background-color: #f38216;
	color: #ffffff;
	height: 23px;
	padding: 0 5px;
	cursor: pointer;
	font-size: 16px;
	border-radius: 2px;
	width: 23px;
	margin: 2px;
}
.matrix-slide-button:hover {
	background-color: #0080ff;
}
.fixed-table-column {
	width: 200px;
}
.matrix-table-columns {
	display: flex;
	width: 200px;
	overflow: hidden;
	scrollbar-width: none;
}
.matrix-table-columns::-webkit-scrollbar {
 width:0;
 display:none;
}
.mt-item {
min-width: 99.66%;
	max-width: 16.66%;
}
.fare-col {
	height: 80px;
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	color: #101010;
	font-size: 13px;
	font-weight: 500;
}
.bolder {
	font-weight: 700;
}
.fare-col img {
	width: 36px;
	height: auto;
	border-radius: 5px;
	margin: 5px 0;
}
.amont-col {
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 13px;
	color: #101010;
	cursor: pointer;
	font-weight: 600;
	overflow: hidden;
}
.amont-col span {
	display: flex;
	padding: 100%;
}
.active-col {
	background-color: rgba(0,0,0,0.1);
}
.ft-item .amont-col {
	font-size: 13px;
	color: #f60;
	font-weight: 600;
	text-align: left;
}
.amont-col:hover, .fare-col:hover {
	background-color: rgba(0,0,0,0.1);
}
/**********matrix*close here************/

/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
	outline: none;
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slider .slick-track, .slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:before, .slick-track:after {
	display: table;
	content: '';
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}
.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}
[dir='rtl'] .slick-slide {
	float: right;
}
.slick-slide img {
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}
