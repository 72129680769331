.contactus {
    display: flex;
    justify-content: space-between;
    width: 94%;
    max-width: 1150px;
    margin: auto;
    padding: 20px 0;
}
.contact-details {
    display: flex;
    flex-direction: column;
    font-family: Mukta;
    color: #303030;
    width: 50%;
}
.contact-form {
    width: 45%;
    font-family: Mukta;
    color: #303030;
}
.contact-form form {
    width: 100%;
}
.flname, .phonemail {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}
.flname div, .phonemail div {
    width: 47%;
}
.flname div input, .phonemail div input {
    width: 100%;
    height: 35px;
    border: 1px solid #999;
    border-radius: 5px;
    outline: 0;
    border-radius: 1px;
}
.comment {
    margin: 30px 0;
    width: 100%;
}
.comment textarea {
    width: 100%;
    height: 150px;
    border: 1px solid #999;
    border-radius: 5px;
    outline: 0;
    border-radius: 1px;
}
.submit-form button {
    border: 0;
    border-radius: 5px;
    outline: 0;
    color: #fff;
    background-color: #f38216;
    padding: 15px;
    cursor: pointer;
}
.contact-details span i {
    color: #f38216;
    font-size: 18px;
}

.blog-head1 {
    background-color: rgba(0, 33, 66, .4);
    color: #fff;
    height: 200px;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    text-shadow: 1px 2px 2px #222;
    background-image: url(../images/sitemap&contact/Contact-us.jpg);
    background-position: center;
    background-size: cover;
}