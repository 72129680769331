@charset "utf-8";
/* CSS Document */
html, body {margin: 0px; padding: 0px; overflow-x: hidden; background-color: #f9f9f9; }

/**********payment-container**start********/
.payment-boxsadow {
  background-color: #ffffff;
  border-radius: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px #dddddd, -1px 1px 3px #dddddd;	
  padding: 10px;
}

.FareLadderPayment{
  position: fixed;
}

.CP_boxsadow {
  background-color: #333;
  color: #fff;
  margin-bottom: 0px;
  padding: 5px 8px 8px;
}

.CP_boxsadow span { float: right;}
.mar-b {margin-bottom: 0px;}

.per-p {font-size: 15px; margin-left: 5px; padding-top: 5px;}

.open-btn {
  width: 150px;
  float: left;
}

.payment-detail-footer {
	font-size: 15px;
	font-weight: 500;
	color: #303030;	
}

.form-control {
  height: 40px;
  /* padding: 10px 8px; */
  font-size: 12px;
}

.DofBgroup {display: flex; border: 1px solid #ddd; border-radius: 8px; width: 100%}
.DOB-devider {
  border-right: 1px solid #ddd;
  height: 39px;
}
.form-select {
  border: var(--bs-border-width) solid #dee2e600;
  border-radius: var(--bs-border-radius);
}
.form-group {
  padding: 2px 0px;
  font-size: 12px;
  width: 100% !important;
  margin-right: 0px;
}

.m-top {margin-top: 8px; display: inline-block;
  margin: 0px;}
.note-in { border: 1px solid #ddd; margin: 8px 0px; padding: 5px 5px; font-size: 12px;}
.note-in span {color: #f60; }
.traveler_title h4 { font-size: 15px; color: #7d7b7b;  font-weight: 600; margin-top: 5px; }
.traveler_title h4 span {color: red;}
.optional_r {margin: 15px 0; color: #f60; cursor: pointer;}
.optional_r img {width: 20px; height: 20px;}

.seat_D {text-align: center; background-color: #ff6701; width: 125px; margin: 10px auto; color: #fff;  padding: 5px; border-radius: 8px;}
.seat_D img {width: 20px; height: 20px;}

.baggage_D {text-align: center; background-color: #ff6701; width: 160px; margin: 10px auto; color: #fff;  padding: 5px; border-radius: 8px;}
.baggage_D img {width: 20px; height: 20px;}

.table_br {border: 1px solid #ddd;}
.td_br {border-right: 1px solid #ddd; padding: 10px;}
.solid_br {border: 1px solid #ddd; padding: 10px; border-radius:8px; }
.solid_br.selected {border: 1px solid #ff6701; padding: 10px; border-radius:8px;}

.seatmainbox {display: flex; justify-content: space-between}
.seatboxw { width: 33%;}
.seating { width: 41%; margin: 0 auto;}
.seating img { width: 100%; height: auto;}

.seat-reset button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #f38216;  
  height: 30px;
  font-size: 17px;
  margin: 5px 0;
  cursor: pointer;
  align-self: flex-end;
  width: 100%;
  max-width: 100px;
}
.discseat { color: #f60; font-size: 16px;}
.seat-disc {padding: 2px 25px; font-size: 13px;}




.window_btn_D.selected {width: 120px; margin: 0px auto 10px; text-align: center; border: 1px solid #f60; padding: 5px; border-radius: 5px; cursor: pointer; color: #ff6701;}
.window_btn_D {width: 120px; margin: 0px auto 10px; text-align: center; border: 1px solid #ddd; padding: 5px; border-radius: 5px; cursor: pointer;}

.seat_icon {display: flex; justify-content: space-between; width: 80%; margin: 0 auto;}
.seat_icon img { width: auto; height: 45px;}

.label_with {width: 100%;}

.payment-from-city {font-size: 13px; color: #505050;}
.payment-from-time {font-size: 13px; color: #303030;}

.paybagtd {border: 1px solid #ddd; padding: 5px;}


.paybage_label_with {  width: 50%; float: left;}

.paybage_solid_br {
	border: 1px solid #ddd; 
	padding: 10px; 
	border-radius:8px; 
	margin: 0 5px; 
	text-align: center; 
  position: relative;
}

.paybage_solid_br.selected {
	border: 1px solid #ff6701; 
	padding: 10px; 
	border-radius:8px; 
	margin: 0 5px; 
	text-align: center;
}
.paybage_solid_selected_br {
	border: 1px solid #ff6701; 
	padding: 10px; 
	border-radius:8px; 
	margin: 0 5px; 
	text-align: center;
}
.departreturne {border: 1px solid #ddd; text-align: center; padding: 5px;}
.departreturne img { width: 26px; height: 30px;}

.mainbaggage { width: 100%; font-size: 15px; color: #7d7b7b;}
.bagga_price { font-weight: 600; color: #555353; font-size: 13px;}

.bundle_D {text-align: center; background-color: #ff6701; width: 110px; margin: 10px auto; color: #fff;  padding: 5px; border-radius: 8px;}
.bundle_D img {width: 20px; height: 20px;}

.bundleprice {margin:20px 0 0px; font-weight: 600; display: flex; justify-content: space-between;}
.bundleprice span { color: #f60; font-size: 23px;}
.bundleprice p:first-child { margin-bottom: 0px; font-weight: normal; font-size: 12px;}

.paybundle_label {
  width: 100%;
  margin-bottom: 15px;
}
.bundle_price { font-weight: 600; color: #555353; font-size: 12px;}
.bundle_price  span{ font-weight: 600; color: #f60; font-size: 18px; text-decoration: line-through;}


.bundle_price2 { font-weight: 600; color: #f60; font-size: 18px;}
.bundle_price2 span { font-weight: 600; color: #555353; font-size: 12px; text-decoration: line-through;}

.addfullheding {margin: 2px 0 20px;  
  padding-top: 8px;}

.mainsummry {width: 55%; margin: 20px auto;}
.innersummry {width: 350px; float: left; font-size: 23px; color: #f60;}
.innersummry2 {width: 150px; float: left; font-size: 23px; color: #f60;}
.linewidth {background-color: #f60; height: 2px; width: 200px; float: left; margin: 20px 0px; margin-right: 10px;}

.marb15 {margin-bottom: 15px;}
.fsize12 { font-size: 12px;}
/*Select Upgrade*/
.toptitle h5 { background-color: #333; color: #fff; margin-bottom: 0px; padding:5px 8px 8px;}
.BE-title {background-color: #f60; color: #fff; margin-bottom: 0px; padding:5px 8px 8px;}
.BE-show-cabin {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
}
.BE-show-cabininr-bold {
  color: #505050;
  text-align: left;
  display: flex;
  justify-content: space-between;
 font: 700 13px/20px Arial, Helvetica, sans-serif;
}
.BE-show-cabininr-think {
  color: #505050;
  text-align: left;
  padding: 0 0 0 10px;
  display: flex;
  justify-content: space-between;
  font: 600 11px/20px Arial, Helvetica, sans-serif;
}
.BE-show-cabin button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #f60;
  width: 99%;
  height: 40px;
  font-size: 17px;
  margin: 5px 0;
  cursor: pointer;
  align-self: flex-end;
}

.BE-show-cabin .activated {
  border: 0;
  color: #333;
  outline: 0;
  border-radius: 5px;
  background-color: #ddd;
  width: 99%;
  height: 40px;
  font-size: 17px;
  margin: 5px 0;
  cursor: pointer;
  align-self: flex-end;
}

/*Select Upgrade close*/
/**********************/

/*****************************/
.payment-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.payment-info-airline {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #505050;
  font-weight: 500;
}

.payment-info-daydate {
  color: #505050;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.payment-info-airline img {
  width: 40px;
  height: auto;
  border-radius: 5px;
  margin: 0 5px;
}

.payment-info-fromtodura {
	display: flex;
	align-items: center;
	width: 65%;
}
.payment-info-from, .payment-info-durastop, .payment-info-to, .payment-price-select {
	display: flex;
	flex-direction: column;
}
.payment-info-to {
	position: relative;
}
.payment-info-airline {
	display: flex;
	align-items: center;
	font-size: 13px;
	color: #505050;
	font-weight: 500;
	width: 33%;
}
.payment-info-airline img {
	width: 36px;
	height: auto;
	border-radius: 5px;
	margin: 0 5px;
}
.payment-info-airline span {
	max-width: 100px;
}

.payment-airlineName {text-align: left; margin-bottom: 0px; font-size: 18px; font-weight: 600;}
.payment-flightNumer {text-align: left; margin-bottom: 0px;}

.payment-info-durastop {
	align-items: center;
	margin: auto 20px;
	width: 65%;
}
.payment-duration, .payment-stop {
	font-size: 12px;
	color: #707070;
	margin: 3px;
}
.payment-stop-graph {
	background-color: #f3b484;
	width: 315px;
	height: 1px;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}


.payment-price-select button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #f38216;
  width: 99%;
  height: 45px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-end;
}
.fly-details {display: none;}
.pay-showinner { width: 90%; margin: 10px auto;}

.payment-detail-container {  
  width: 94%;
  margin: 1px 3%;
  margin-bottom: 1px;
  position: relative;
}

@media only screen and (max-width:768px) {
  .FareLadderPayment {
    margin-top: 0px;
    position:relative;
  }
.payment-detail-container {	
	width: 100% !important;
	margin: 1px 0% !important;	
}
.pay-showinner { width: 100% !important; margin: 10px auto;}

.seat_icon img { width: 75px; height: auto;}

}

/****payment-Details******/

.payment-detail-show {
	cursor: pointer;
	font-size: 15px;
	color: rgb(243, 130, 22);
	font-weight: 500;
}

.payment-detail-head {
	font-size: 15px;
	font-weight: 500;
	color: #303030;
	display: flex;
	justify-content: space-between;
}
.payment-head-depar {color: #f60; font-weight: bold;}
.payment-head-Baggage { font-size: 12px; }
.payment-head-Baggage p { display: block; text-align: right;  font-size: 12px; }

.payment-flightno {font-size: 12px;}
.pay-arrivnext {color: #f60; font-size: 12px;}

/*.payment-detail-row-old {
	display: flex;
	margin: 0px 0 15px;
	align-items: center;
	justify-content: space-between;
}*/

.payment-detail-row {	
	margin: 0px 0 15px;
	align-items: center;
	width: 100%;
	float: left;
	
}
.mobile-respons {width: 27%; float: left; }
.mobile-respons-next {width: 70%; float: left; }

/*layover-divider*/
.layover-divider {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #777;
  border-bottom: 1px dashed #777;
  margin: 10px 0 20px;
}
.layover-divider {
  height: 10px;
  margin: -1px auto;
  padding: 5px auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.layover-divider span {
  background: #fff;
  padding: 0 10px;
  position: relative;
  top: 5px;
}
/*layover-divider close*/

.payment-detail-airline {
	display: flex;
	align-items: center;
}
.payment-detail-airline img {
	width: 45px;
	height: auto;
}
.payment-airline-name-fno {
	font-size: 14px;
	color: #505050;
	font-weight: 500;
	margin: 0 5px;
}
.payment-detail-from-to {
	display: flex;
	align-items: center;
}



.payment-detail-from, .payment-detail-to {
	display: flex;
	flex-direction: column;
}
.payment-detail-arrow {
	display: flex;
}

.payment-Detailsstop-graph {
  background-color: #f3b484;
  max-width: 170px;
  width: 100%;
  height: 1px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-line-flights {
  width: 100%;
  background-color: #ddd;
  height: 1px;
}
/*********************/
.payment-ddinfo-durastop {
  align-items: center;
  margin: auto 20px;
  width: 39%;
}
.payment-ddinfo-durastop {
  display: flex;
  flex-direction: column;
}


/***********tooltip**********/
.stop-dot-paymnt {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ffffff;
	border: 1px solid #f3b484;
	margin: 0 18px;
	cursor: pointer;
	position: relative;
	font-size: 14px !important;
}
.stop-tool-paymnt {
	position: absolute;
	bottom: 16px;
	left: -133px;
	z-index: 1;
	padding: 5px;
	border-radius: 2px;
	color: #222;
	background-color: #fff;
	width: 280px;
	display: none;
	transition: all 0.2s ease-in-out;
	border: 1px solid #f3b484;
	box-shadow: 1px 1px 3px #dddddd, -1px -1px 3px #dddddd, 1px -1px 3px #dddddd, -1px 1px 3px #dddddd;
}
.stop-tool-paymnt::after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-width: 10px;
	border-style: solid;
	border-color: #f3b484 transparent transparent transparent;
	bottom: -20px;
	left: 126.5px;
	display: none;
	transition: all 0.2s ease-in-out;
}
.stop-dot-paymnt:hover .stop-tool-paymnt, .stop-dot-paymnt:hover .stop-tool-paymnt::after {
	display: block;
}

/***********tooltip_closed*********/

.flight-price-select1 button {
  border: 0;
  color: #ffffff;
  outline: 0;
  border-radius: 5px;
  background-color: #ff6701;
  width: 90%;
  max-width: 220px;
  height: 45px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  z-index: 4;
  margin-bottom: 55px;
  margin-left: 25%;
}

/**right-side-fare-details-9/sep/23**/
.paymentfare_title {
  font-size: 22px;
  line-height: 22px;
  background-color: #333;
  padding: 8px;
  color: #fff;	
}

.booked-fare-area ul {
	padding: 0;
	margin: 0;
}
.booked-fare-area ul li {
  display: flex;
  justify-content: space-between;
  padding: 4px 9px;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.booked-fare-area ul li:hover {background-color: #cdcdcd69;}
.booked-fare-area ul li span { font-weight: 600;}

.pay-note {font-size: 11px; margin-top: 10px; margin-bottom: 0px;}

.paysubTotal-booked h6 {
  font-size: 16px;
  font-weight: bold;
  /*display: flex;*/
  justify-content: center;
  background-color: #333 !important;
  padding: 10px;
  color: #fff;
  /*border-radius: 8px;*/
}

.paysubTotal-booked {
  padding-top: 1px;
  /*width: 200px;*/
  margin: 0 auto;
}

.pay-sa-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
 font-size: 13px;	
}
.pay-sa-row i {
  color: #008000;
  font-size: 15px;
  margin-right: 8px;
}

/********additio_nal*******/

.additional_title h4 { padding: 5px 10px; font-size: 17px;}
.additional_title .left-nav {
  float: left;
  width: 80%;
  text-align: left;
  padding: 15px 0 0 30px;
}

.additional_title .right-nav {
  float: left;
  width: 20%;
 text-align: center;
}
.additional_title .left-nav ul {
  margin: 0 2px;
  padding: 0 2px;
}

.additional_title .left-nav ul li {
  width: 100%;
  float: left;
  background: url(../images/rounded-check.png) no-repeat left top;
  list-style: none;
  margin: 0;
  vertical-align: middle;
}

.additional_title .left-nav ul li {
  list-style-type: none;
  color: #1f5c87;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px 5px 30px;
  line-height: 19px;
}

/********radio*toolba**********/
.auto_matick {
  float: left;
  width: 100%;
}
.toolbar_radio input[type="radio"]:checked + label {
  background-color: #2276b2;
  border-color: #2276b2;
  color: #fff;
}
.toolbar_radio input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  /* font-weight: 700; */
}

.toolbar_radio label {
  display: inline-block;
  background-color: #ddd;
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 14px;
  border: 0px solid #c5c2c270;
    border-top-color: rgba(197, 194, 194, 0.44);
    border-right-color: rgba(197, 194, 194, 0.44);
    border-bottom-color: rgba(197, 194, 194, 0.44);
    border-left-color: rgba(197, 194, 194, 0.44);
  border-radius: 4px;
  width: 100%;
}
.toolbar_radio label:hover {
  background-color: #f8881f6e;
  border-color: #f8881f;
  color: #000;
}

/*****************/
.box-panel-last {
  float: left;
  width: 15%;
  padding: 0px !important;
}

.box-panel {
  float: left;
  width: 85%;
  padding: 15px;
}

.box-panel ul {
  margin: 10px 2px 20px;
  padding: 0 2px;
  width: 100%;
}

.box-panel ul li {
  background: url(../images/rounded-check.png) no-repeat left top;
  list-style: none;
  margin: 0;
  vertical-align: middle;
}

.box-panel ul li {
  list-style-type: none;
  color: #2277b2;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px 5px 30px;
  line-height: 19px !important;
  width: 50%;
  float: left;
}
@media (max-width: 767px) {
	
.mainsummry {
  width: 100%;
  margin: 20px auto;
}	
	
.box-panel-last {
  float: left;
  width: 100%;
  text-align: center;
}
	
.additional_title .left-nav {
  float: left;
  width: 100% !important;
  text-align: left;
  padding: 15px 0 0 30px;
}	
	
.additional_title .right-nav {
    float: left;
    width: 100%;
    text-align: center;
  }
	
  .box-panel ul li {
    width: 100% !important;
    float: left;
  }
	
.box-panel {
  float: left;
  width: 100%;
  padding: 15px;
}
	
.CP_boxsadow h5 { font-size: 18px;}	
.mar-b {margin-bottom: 15px !important;}	
	
.per-p {font-size: 15px; margin-left: 5px; padding-top: 0px !important;}
	
.seating { width: 80%; margin: 0 auto;}
.seating img { width: 100%; height: auto;}
	
.window_btn {
  width: 100%;
  max-width: 80px;
  margin: 0px auto 10px;
  text-align: center;
  border: 1px solid #f60;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
  color: #ff6701;
  font-size: 12px;
}
.window_btn_D {
  width: 100%;
  max-width: 80px;
  margin: 0px auto 10px;
  text-align: center;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}
.seat-disc {padding: 2px 12px; font-size: 13px;}	
	
}

/********************/
.ancelary-box {
  width: 100%;
  clear: both;
  padding: 15px;
}

.ancelary-box .add-button {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.add-button button {
  font-size: 14px;
  padding: 8px 10px;
  background: #fff;
  color: #3bb03b;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #3bb03b;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.add-button .remove-btnw {
  z-index: 2;
  border: 0;
  cursor: pointer;
  color: #121211;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #f5f5f5;
}

.add-button button.active {
  background: #2176b1;
  border: 1px solid #2176b1;
  color: #fff;
  cursor: pointer;
}

.text_note {
  font-family: open sans,sans-serif;
  border: 1px solid #3D9CF9;
  color: #5b5b5b;
  border-radius: 5px;
  padding: 6px;
  font-size: 12px;
}
.text_note strong {
  color: #005dba;
}
.text_note a {
  color: #005dba;
  text-decoration: none;
  cursor: pointer;
}
.fon13 {font-size: 13px;}

/*sticky_css*/
.wrapper-payment { display: flex; }
.wrapper-payment .is-sticky {top:80px !important;}
/*sticky_css close*/

/*********************/
@media (max-width: 767px) {
.mobile-respons {width: 100% !important; float: left; }
.mobile-respons-next {width: 100%; float: left; }
}
 
       
@media only screen and (max-width: 768px){
.flight-price-select1 {
    align-items: center;
    border-bottom: 1px dashed #ccc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0 10px;
    /* position: absolute; */
    top: 0;
    width: 95%;
}
.custom_drop {
  width: 100%;
  /* left: inherit !important; */
  right: 0 !important;
}	
.innersummry {width: 235px; float: left; font-size: 15px; color: #f60;}
.innersummry2 {width: 85px; float: left; font-size: 15px; color: #f60;}
.linewidth {background-color: #f60; height: 2px; width: 30px; float: left; margin: 20px 0px; margin-right: 10px;}
}

* {
  margin: 0;
  padding: 0;  
}

.select_button {
  font-size: 14px;
  border: 1px solid #4f8ffc;
  background: #fff;
  color: #4f8ffc;
  /* width: 150px; */
  width: 100%;
  padding: 9px;
  border-radius: 5px;
  /* display: inline-block; */
}	
/* .custom_select {
  /*position: relative;
}	 */
.custom_select ul {
  padding-bottom: 0;
}	
	
.custom_select ul {
  position: absolute;
  left: 0;
  top: 103px;
  z-index: 3;
  border: 1px solid #acacac;
  box-shadow: 0 3px 6px #00000029;
  list-style: none;
  width: 100%;
  background: #fff;
  /* display: none; */
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0;
  margin-left: 28px
}
.custom_select ul li {
  padding: 13px;
  font-weight: 500;
  border: none;
}
.custom_drop li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
	
.custom_select ul li:hover {
  background: #4f8ffc;
  color: #fff;
}
.custom_select ul li {
  padding: 13px;
  font-weight: 500;
  border: none;
}
.custom_drop {
  width: 336px;
  /* left: inherit !important; */
  right: 0 !important;
}	