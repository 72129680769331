/* Style the tab */
.tab1 {
  float: left;
  border: 1px solid #ccc;
  background-color: #FF6B0033;
  width: 100%;
}

/* Style the buttons inside the tab */
.tab1 button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 8px 16px;
  width: 25%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  float: left;
}

/* Change background color of buttons on hover */
.tab1 button:hover {
  background-color: #3c78f10d;
}

/* Create an active/current "tab button" class */
.tab1 button.active {
  background-color: #FF6B00;
  color: #fff;
}

/* Style the tab content */
.tabcontent1 {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  border-top: 0px;
  width: 100%;
  
}
.done_button {
  background: #3C78F1;
  float: right;
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px 36px;
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin-top: 20px;
}
.nav_Airlines ul {padding-left:4px;}	
.nav_Airlines ul li {
  color: #414142;
  margin-bottom: .2rem;
  list-style: none;
}

.fi-content label {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 10px 0;
  position: relative;
}

.stoprates {
  position: absolute;
  right: 0;
}	