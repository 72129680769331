.page-head
{
font-size:22px;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-weight:550;
color:#002192;
padding:15px 0;
width:94%;
margin:auto;
max-width:1200px;
}
.search-airline
{
max-width:1200px;
margin:auto;
width:94%;
}
.search-airline input
{
height:45px;
width:250px;
border:1px solid #ccc;
border-radius:5px;
text-indent:5px;
color:#303030;
margin:10px 0;
font-size:16px;
}
table,th,td
{
border:2px solid #eee;
border-collapse:collapse;
background-color:#ffffff;
}
table
{
width:94%;
margin:auto;
max-width:1200px;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
position:relative;
}
th,td
{
padding:15px;
text-align:center;
}
th
{
color:#002192;
font-weight:550;
font-size:16px;
}
thead
{
position:relative;
}
thead tr
{
position:sticky;
top:10px;
}
td a
{
font-size:15px;
color:#303030;
}
td
{
font-weight:400;
}