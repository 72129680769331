table,th,td
{
   border:1px solid #909090;
   border-collapse:collapse;
   font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
thead tr
{
   background-color:#002192;
   color:#ffffff;
   font-size:14px;
   font-weight:500;
}
thead tr th
{
  padding:5px;
}
tbody tr td
{
   color:#404040;
   font-size:13px;
   padding:5px;
   text-align:center;
}
tbody tr:nth-child(even)
{
  background-color:#f4f5f6;
}