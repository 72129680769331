@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap");
a {cursor: pointer;}

.form-switch .form-check-input.comp_check {
	height: 19px;
	width: 37px;
	position: absolute;
	right: 0px;
	top: 3px
}
.form-check-input.comp_check:checked {
	background-color: #ACB4BE;
	border-color: #ACB4BE
}
.form-check-input.comp_check:focus {
	box-shadow: none
}



.bnrheading2 {
	padding: 150px 0 10px 0;
	text-align: center;
	color: #040504;
	font-family: "Poppins", sans-serif;
	font-size: 18px
}

@media (max-width: 1023px) {
.bnrheading2 {
	padding: 90px 0 15px 0
}
}
.bnrheading2 h2 {
	font-size: 36px;
	font-weight: 400;
	margin: 0px
}

@media (max-width: 1023px) {
.bnrheading2 h2 {
	font-size: 30px
}
}

@media (max-width: 576px) {
.bnrheading2 h2 {
	font-size: 22px
}
}

@media (max-width: 479px) {
.bnrheading2 h2 {
	font-size: 18px
}
}
.bnrheading2 h1.heading {
	font-size: 18px;
	font-weight: 400;
	display: inline-block;
	text-shadow: 2px 2px #5587c9;
}

@media (max-width: 1023px) {
.bnrheading2 h1.heading {
	font-size: 16px
}
}

@media (max-width: 576px) {
.bnrheading2 h1.heading {
	font-size: 13px
}
}

@media (max-width: 479px) {
.bnrheading2 h1.heading {
	font-size: 11px;
	margin-bottom: 0px
}
}
.bnrheading2 p {
	font-size: 17px
}

@media (max-width: 1023px) {
.bnrheading2 p {
	font-size: 16px
}
}
/********done*******/


@media (max-width: 576px) {
.bnrheading2 p {
	font-size: 13px
}
}

@media (max-width: 479px) {
.bnrheading2 p {
	font-size: 11px;
	margin-bottom: 0px
}
}

.EngineBox2 {
	/*background: #fff;*/
	position: relative;
	font-family: "Poppins", sans-serif;
	z-index: 2;
	border-radius: 5px;
	width: 100%
}
.EngineBox2 [class*='col'] {
	margin: 0px;
	padding: 0
}
.EngineBox2 [class*='row'] {
	margin: 0px;
	padding: 0
}

@media (max-width: 1023px) {
.EngineBox2 {
	padding: 20px 15px 10px 15px;
	background: rgba(255,255,255,0.9)
}
}

@media (max-width: 576px) {
.EngineBox2 {
	background: none;
	padding: 0px
}
}
.EngineBox2 .traveller_icon {
	font-size: 12px;
	color: #ff6600
}
.EngineBox2 .searchTitle {
	font-size: 20px;
	color: #353541;
	font-weight: 600;
	margin-bottom: 15px
}

@media (max-width: 576px) {
.EngineBox2 .searchTitle {
	font-size: 18px;
	margin-bottom: 10px
}
}

@media (max-width: 1023px) {
.EngineBox2 .holder {
	margin-right: -10px
}
}

@media (max-width: 576px) {
.EngineBox2 .holder {
	margin-right: 0px
}
}
.EngineBox2 .input_block {
	position: relative
}
.EngineBox2 .input_block.activefield {
	z-index: 2
}
.EngineBox2 .input_block.activefield .inputField {
	border: 1px solid #ff660061;
}
.EngineBox2 .input_block.dcol-50 {
	width: 48%;
	float: left;
	margin-right: 4px;
}

@media (max-width: 1023px) {
.EngineBox2 .input_block.dcol-50 {
	margin-right: 10px;
	width: calc(50% - 10px)
}
}

@media (max-width: 576px) {
.EngineBox2 .input_block.dcol-50 {
	margin-right: 0px;
	width: 100%
}
}
.EngineBox2 .input_block.dcol-50.fromdcity {
	width: 92%;
	position: relative
}
.EngineBox2 .activefield .inputField {
	border: 1px solid #2680EB
}
.EngineBox2 .searchBox {
	padding: 0px
}
.EngineBox2 .inputField {
	padding: 20px 10px 13px 30px;
	font-size: 14px;
	color: #353541;
	border: 1px solid #ff660061;
	outline: none;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border-radius: 5px 5px;
	background-color: #fff
}

@media (max-width: 1023px) {
.EngineBox2 .inputField {
	margin-bottom: 18px;
	padding: 22px 20px 9px 33px;
	border-color: #C4CCD6
}
}

@media (max-width: 359px) {
.EngineBox2 .inputField {
	font-size: 12px
}
}
.EngineBox2 .inputField.flying {
	padding-right: 20px
}
.EngineBox2 .inputField.tocity {
	padding-left: 28px
}

@media (max-width: 1023px) {
.EngineBox2 .inputField.tocity {
	padding-left: 33px
}
}
.EngineBox2 .icons {
	position: absolute;
	left: 7px;
	top: 13px;
	z-index: 1;
	pointer-events: none;
	font-size: 20px;
	color: #ff6600
}

@media (max-width: 1023px) {
.EngineBox2 .icons {
	display: block
}
}
.EngineBox2 .icons.person-icon {
	font-size: 25px;
	top: 7px
}
.EngineBox2 .icons.bi-calendar3 {
	font-size: 18px
}
.EngineBox2 .label_text {
	font-size: 13px;
	position: absolute;
	left: 13px;
	top: 3px;
	color: #333;
	transform: translate(0, -1em) scale(0.9, 0.9);
	background-color: #fff;
}

@media (max-width: 1023px) {
.EngineBox2 .label_text {
	left: 15px
}
}
.EngineBox2 .label_text.tolabel {
	left: 26px
}

@media (max-width: 1023px) {
.EngineBox2 .label_text.tolabel {
	left: 33px
}
}
.EngineBox2 .dates {
	position: relative;
	margin-right: 3px;
}

@media (max-width: 1023px) {
.EngineBox2 .dates {
	margin-right: -5px
}
}
.EngineBox2 .dates:after {
	content: '';
	/*background: #d8dce1;*/
	position: absolute;
	left: 0px;
	top: 7px;
	height: 37px;
	display: block;
	width: 1px;
	z-index: 1
}

@media (max-width: 1023px) {
.EngineBox2 .dates:after {
	display: none
}
}
.EngineBox2 .dates:before {
	content: '';
	/*background: #d8dce1;*/
	position: absolute;
	right: 0px;
	top: 7px;
	height: 37px;
	display: block;
	width: 1px;
	z-index: 1
}

@media (max-width: 1023px) {
.EngineBox2 .dates:before {
	display: none
}
}
.EngineBox2 .dates .inputField {
	padding-right: 8px
}
.EngineBox2 .dates #departDiv:after {
	content: '';
	/*background: #d8dce1;*/
	position: absolute;
	right: 0px;
	top: 7px;
	height: 37px;
	display: block;
	width: 1px;
	z-index: 1
}

@media (max-width: 1023px) {
.EngineBox2 .dates #departDiv:after {
	display: none
}
}
.EngineBox2 .col-50 {
	width: 48%;
	float: left;
	margin-left: 4px;
}

@media (max-width: 1023px) {
.EngineBox2 .col-50 {
	margin-right: 0px;
	width: calc(50% - 10px)
}
}
.EngineBox2 .col-50.return_date {
	margin-left: -7px
}
.EngineBox2 .col-50.roundtrip {
	visibility: hidden
}
.EngineBox2 .searchButton {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	background: #ff6600;
	border: none;
	font-size: 18px;
	color: #fff;
	text-align: center;
	border-radius: 5px 5px;
	float: right;
	padding: 14px 39px;
	outline: none;
	cursor: pointer
}

.EngineBox2 .searchButton img {width: 24px; height: 24px;}
.EngineBox2 .searchButton.selected {
	cursor: pointer;
	background: #0FF;
	color: #040504
}

@media (max-width: 1023px) {
.EngineBox2 .searchButton {
	border-radius: 5px
}
}

@media (max-width: 576px) {
.EngineBox2 .searchButton {
	width: calc(100% + 40px);
	margin: 5px -20px 0 -20px;
	font-size: 16px;
	font-weight: 700;
	border-radius: 0 0 5px 5px
}
}

@media (max-width: 359px) {
.EngineBox2 .searchButton {
	width: calc(100% + 30px);
	margin: 5px -15px 0 -15px
}
}

/***********done************/

.relative2 {
	position: relative
}
.cursor {
	cursor: pointer
}
.search_overlay2, .dateoverlay, .page_overlay {
	position: fixed;
	left: 0px;
	top: 0px;
	height: 100%;
	width: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 1;
	display: none
}

@media (max-width: 576px) {
.search_overlay2, .dateoverlay, .page_overlay {
	display: none
}
}
/***********auto_complete*************/
.ui-autocomplete li {
	position: relative;
	font-size: 15px;
	border-bottom: 1px solid #DDDDE5
}
.ui-autocomplete li .parent_div {
	padding: 10px 10px 10px 36px;
	cursor: pointer
}
.ui-autocomplete li .parent_div.selected {
	background: #4F8FFC;
	color: #fff
}
.ui-autocomplete li .parent_div.selected .location_icon, .ui-autocomplete li .parent_div.selected .airports_name {
	color: #fff
}
.ui-autocomplete li .parent_div .airports_name {
	color: #979797;
	padding-left: 15px
}
.ui-autocomplete li .location_icon, .ui-autocomplete li .flight_icon {
	font-size: 20px;
	margin-right: 5px;
	color: #ACB4BE;
	position: absolute;
	left: 10px;
	top: 9px
}
.ui-autocomplete li .country_name {
	font-size: 12px;
	display: block
}
.ui-autocomplete li .code {
	padding-left: 6px
}
.ui-autocomplete li .child_div {
	position: relative;
	padding: 10px 10px 10px 60px;
	cursor: pointer
}
.ui-autocomplete li .child_div .flight_icon {
	/* background: url() left 4px no-repeat; */
	width: 19px;
	height: 23px;
	display: inline-block;
	left: 35px
}
.ui-autocomplete li .child_div.selected {
	background: #4F8FFC;
	color: #fff
}
.ui-autocomplete li .child_div.selected .location_icon {
	color: #fff
}
/* .ui-autocomplete li .child_div.selected .flight_icon {
	background: url() left 4px no-repeat
} */
.ui-autocomplete li .category_title {
	font-size: 15px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	padding: 5px 0px;
	border-bottom: 1px solid #ccc;
	margin: 0px 10px
}
/***********auto_complete*close************/


.EngineBox2 .label_text.change_label {
	top: 19px;
}
.EngineBox2 .inputField:focus ~ .label_text.change_label {
	top: -4px
}
.EngineBox2 .inputField.x ~ .label_text.change_label, .EngineBox2 .inputField.valid ~ .label_text.change_label {
	top: -4px
}
.text-error {
	color: red;
	background: #ffbaba;
	padding: 4px 10px;
	border-radius: 5px;
	text-align: center;
	margin-top: 5px;
	display: inline-block
}
.EngineBox2 .inputField.error {
	border: 2px solid #F54336
}
.EngineBox2 .inputField.error ~ .icons {
	color: #F54336
}
.error_text {
	color: #F54336;
	font-size: 13px;
	padding-top: 5px
}
.swap_icon2 {
	position: absolute;
	left: 46%;
	top: 15px;
	background: #ffffff;
	width: 25px;
	height: 25px;
	z-index: 1;
	border-radius: 100%;
	box-shadow: 0px 2px 6px #0000001a;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s
}

@media (max-width: 576px) {
.swap_icon2 {
	left: inherit;
	right: 13px;
	top: 42px
}
}
.swap_icon2 img {
	transition: all 0.5s
}

@media (max-width: 576px) {
.swap_icon2 img {
	transform: rotate(90deg)
}
}
.swap_icon2.exchange img {
	transform: rotate(360deg)
}

@media (max-width: 576px) {
.swap_icon2.exchange img {
	transform: rotate(90deg)
}
}
/* .mobile_search_popup {
	/* position: fixed; 
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 10;
}
.mobile_search_popup .blockshadow {
	background-color: #fff;
	margin-bottom: 15px;
	box-shadow: 0 2px 5px #cccc
}
.mobile_search_popup .autosuggestBox {
	top: 55px;
	background: transparent;
	height: calc(100% - 70px)
}
.mobile_search_popup .inputField {
	padding: 17px 20px 12px 33px;
	font-size: 14px;
	font-weight: 700;
	color: #353541;
	border: none;
	border-bottom: 1px solid transparent;
	outline: none;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden
}
.mobile_search_popup .inputField.active {
	border-bottom: 1px solid #4F8FFC
}
.mobile_search_popup .inputField.mobile_input {
	padding: 20px 20px 8px 33px;
	font-size: 14px;
	font-weight: 700;
	color: #353541;
	border: 1px solid #C4CCD6;
	outline: none;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border-radius: 5px;
	background-color: #fff;
	margin-bottom: 15px
}
.mobile_search_popup .inputField.mobile_input.x ~ .label_text.change_label, .mobile_search_popup .inputField.mobile_input.valid ~ .label_text.change_label {
	top: 5px
}
.mobile_search_popup .icons {
	position: absolute;
	left: 7px;
	top: 13px;
	display: block;
	z-index: 3;
	pointer-events: none;
	font-size: 20px;
	color: #353541
}
.mobile_search_popup .dates {
	margin-right: -10px
}
.mobile_search_popup .col-50 {
	width: calc(50% - 10px);
	float: left;
	margin-right: 10px;
	position: relative
}
.mobile_search_popup .col-50.roundtrip {
	width: 100%
}
.mobile_search_popup .searchButton {
	width: 100%;
	background: #0FF;
	border: none;
	font-size: 16px;
	color: #040504;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	padding: 15px 10px;
	font-weight: 500;
	outline: none
}
.mobile_search_popup .searchwindowclose {
	position: absolute;
	right: 7px;
	color: #1F1F1F;
	font-size: 33px;
	top: 4px;
	line-height: 35px;
	z-index: 3;
	display: block
}
.mobile_search_popup .label_text {
	font-size: 12px;
	position: absolute;
	left: 35px;
	top: 5px;
	color: #ACB4BE
}

@media (max-width: 576px) {
.mobile_search_popup .swap_icon2 {
	top: -20px
}
} */
.panel_block .inputField {
	border: none;
	border-radius: 0px;
	box-shadow: 0 2px 6px #ccc
}
.panel_block .icons.person-icon {
	left: 9px
}
.panel_block .searchwindowclose {
	position: absolute;
	right: 7px;
	color: #1F1F1F;
	font-size: 33px;
	top: 4px;
	line-height: 35px;
	z-index: 3;
	display: block
}
.searchcompclose {
	position: absolute;
	right: 3px;
	top: 7px;
	color: #1F1F1F;
	font-size: 33px;
	line-height: 35px;
	z-index: 2;
	display: none
}

@media (max-width: 576px) {
.searchcompclose {
	display: block
}
}
.popup_head {
	padding: 17px 20px 12px 33px;
	font-size: 14px;
	color: #353541;
	font-weight: 600
}
.inner_block {
	padding: 10px 15px
}
.inner_block h6 {
	font-size: 14px;
	color: #353541;
	font-weight: 600
}
.inner_block .recentSearches {
	list-style: none;
	margin: 0 -15px
}
.inner_block .recentSearches li {
	padding: 10px 10px 10px 36px;
	cursor: pointer;
	position: relative;
	font-size: 15px;
	border-bottom: 1px solid #DDDDE5
}
.inner_block .recentSearches li .location_icon {
	font-size: 20px;
	margin-right: 5px;
	color: #ACB4BE;
	position: absolute;
	left: 10px;
	top: 9px
}
.inner_block .recentSearches li .highlight {
	font-weight: 700;
	color: #0080FF
}
.inner_block .recentSearches li .country_name {
	font-size: 12px;
	display: block
}
.destination2_field {
	position: relative
}
.destination2_field .line {
	background: #0080FF;
	height: calc(100% - 60px);
	width: 1px;
	display: inline-block;
	position: absolute;
	left: 12px;
	top: 20px;
	z-index: 2
}
.destination2_field .line:before {
	content: '';
	position: absolute;
	left: -4px;
	top: -6px;
	width: 8px;
	height: 8px;
	border: 2px solid #0080FF;
	border-radius: 100%;
	background: #fff
}
.destination2_field .line:after {
	content: '';
	position: absolute;
	left: -4px;
	bottom: -6px;
	width: 8px;
	height: 8px;
	border: 2px solid #0080FF;
	background: #0080FF;
	border-radius: 100%
}
.searchcntr {
	position: relative;
	z-index: 1;
}
.inputSet.compare_list {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	margin: 0px;
	padding: 20px 0px;
	color: #fff
}

@media (max-width: 576px) {
.inputSet.compare_list {
	display: none
}
}
.inputSet.compare_list label {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center
}


.block_search2 {
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 5px;
  box-shadow: 0px 10px 5px #aaaaaa;
  border: 1px solid #ddd; margin-top: 10px;
}

@media (max-width: 576px) {
.block_search2 {
	background: rgba(255,255,255,0.9);
	padding: 20px 20px 0 20px;
	border-radius: 5px;
	border: 1px solid #ddd; margin-top: 10px;	
}
}

@media (max-width: 359px) {
.block_search2 {
	padding: 15px 15px 0 15px
}
}
.block_search2 h4 {
	font-size: 17px;
	font-weight: 700;
	display: none
}

@media (max-width: 576px) {
.block_search2 h4 {
	display: block
}
}
.traveller_click {
	width: calc(100% - 175px);
	float: left
}

@media (max-width: 576px) {
.traveller_click {
	width: 100%
}
}
.traveller_block2 {
	padding: 15px 20px 20px;
	position: absolute;
	top: 62px;
	right: 0px;
	width: 424px;
	min-height: 50px;
	background: #fff;
	z-index: 9;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 6px
}

@media (max-width: 576px) {
.traveller_block2 {
	width: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	border-radius: 0px;
	padding: 0px;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden
}
}

@media (max-width: 576px) {
.traveller_block2 .innerSet {
	padding: 10px
}
}
.traveller_block2 .traveller_label {
	color: #333;
	font-weight: 600;
	font-size: 20px;
	margin: 8px 0;
	display: block
}
.traveller_block2 ul.traveller_list {
	list-style: none;
	padding: 0px
}
.traveller_block2 ul.traveller_list li {
	font-size: 14px;
	color: #353541;
	font-weight: 500;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between
}
.traveller_block2 ul.traveller_list li .small {
	font-size: 12px;
	font-weight: 400
}
.traveller_block2 ul.traveller_list .actionbtn {
	display: flex;
	align-items: center;
	justify-content: space-between
}
.traveller_block2 ul.traveller_list .actionbtn .addicons {
	width: 30px;
	height: 30px;
	display: inline-block;
	border: 1px solid #ACB4BE;
	border-radius: 100%;
	text-align: center;
	line-height: 30px;
	color: #353541;
	cursor: pointer
}
.traveller_block2 ul.traveller_list .actionbtn .addicons.minus {
	font-size: 24px
}
.traveller_block2 ul.traveller_list .actionbtn .addicons.active, .traveller_block ul.traveller_list .actionbtn .addicons:hover {
	background: #0080FF;
	border-color: #0080FF;
	color: #fff
}
.traveller_block2 ul.traveller_list .actionbtn .number {
	width: 40px;
	border: none;
	background: none;
	font-size: 14px;
	color: #353541;
	font-weight: 600;
	text-align: center
}
.traveller_block2 .cabinClass {
	margin-right: -20px;
	list-style: none;
	padding: 0px;
	width: 327px;
}
.traveller_block2 .cabinClass li {
	padding: 3px 0px;
	float: left;
	margin: 4px;
    width: 150px;
    border-radius: 15px;
	border: 1px solid orange;
	font-weight: 500;
	cursor: pointer;
	font-size: 13px
}
.traveller_block2 .done_button {
	background: #F60;
	float: right;
	border-radius: 3px;
	border: none;
	float: right;
	color: #fff;
	cursor: pointer;
	padding: 10px 10px;
	font-size: 14px;
	font-weight: 400;
	display: block;
	margin-top: -13px;
}
.traveller_block2 .done_button:hover {
	border: none;
	outline: none
}
.traveller_block2 .searchwindowclose {
	position: absolute;
	right: 3px;
	top: 7px;
	color: #1F1F1F;
	font-size: 33px;
	line-height: 35px;
	z-index: 1;
	display: none
}

@media (max-width: 576px) {
.traveller_block2 .searchwindowclose {
	display: block
}
}

@media (max-width: 576px) {
.traveller_block2 .inputField {
	padding: 13px 20px 12px 33px;
	margin-bottom: 15px;
	border: none;
	box-shadow: 0 2px 5px #cccc;
	border-radius: 0px
}
}

/***************checked_css******************/

.inputSet label {
	margin: 0px;
	cursor: pointer;
	font-weight: 500;
	line-height: 22px;
	padding-left: 20px;
	position: relative;
	color: #1F1F1F
}
.inputSet {
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-weight: 500
}
.inputSet input {
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0
}
.checkmark {
	display: inline-block;
	position: relative;
	margin-right: 6px;
	height: 18px;
	width: 18px;
	border: 1px solid #C4CCD6;
	border-radius: 2px;
	background: #fff
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none
}

.checked:after {
	left: 5px;
	top: 1px;
	width: 6px;
	height: 10px;
	border: solid #333;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg)
}

.modifySearchBox {
	box-shadow: 0 4px 3px #00000005;
	padding-bottom: 14px;
	background: #fff
}
.modifySearchBox .activefield {
	position: relative;
	z-index: 2
}

@media (max-width: 576px) {
.modifySearchBox .activefield {
	z-index: 1
}
}
.modifySearchBox .activefield .inputField {
	border: 1px solid #5E5E5E !important
}
.modifySearchBox.open {
	display: block
}

@media (max-width: 1023px) {
.modifySearchBox {
	padding: 20px 20px;
	z-index: 9;
	width: 100%
}
}

@media (max-width: 576px) {
.modifySearchBox {
	padding: 20px 10px
}
}
.modifySearchBox .title_mobile {
	font-size: 16px;
	margin-bottom: 27px;
	display: none;
	background: #0FF;
	padding: 10px;
	margin: 0px -20px 16px -20px
}

@media (max-width: 1023px) {
.modifySearchBox .title_mobile {
	display: block;
	margin: 0px -40px 16px -20px;
	padding: 10px 40px
}
}

@media (max-width: 576px) {
.modifySearchBox .title_mobile {
	display: block;
	margin: 0px -20px 16px -20px;
	padding: 10px 40px
}
}
.modifySearchBox .center_modify {
	max-width: 1070px;
	margin: 0px auto
}
.modifySearchBox .tripType {
	width: auto;
	background: none;
	padding: 0 0 10px 0
}
.modifySearchBox .label_text {
	display: none;
	font-weight: 600
}

@media (max-width: 1023px) {
.modifySearchBox .label_text {
	padding-bottom: 5px
}
}
.modifySearchBox .search_holder {
	display: flex
}

@media (max-width: 1023px) {
.modifySearchBox .search_holder {
	display: block
}
}
.modifySearchBox .search_holder.oneway .column1 {
	width: 52%;
	display: flex
}
.modifySearchBox .search_holder.oneway .column2 {
	width: 17%;
	margin: 0 5px
}
.modifySearchBox .column1 {
	width: 40%;
	display: flex
}

@media (max-width: 1023px) {
.modifySearchBox .column1 {
	width: 100%;
	margin-bottom: 13px
}
}

@media (max-width: 576px) {
.modifySearchBox .column1 {
	flex-direction: column;
	position: relative
}
}
.modifySearchBox .column2 {
	width: 26%;
	margin: 0 5px
}

@media (max-width: 1023px) {
.modifySearchBox .column2 {
	width: calc(50% - 10px);
	float: left
}
}

@media (max-width: 576px) {
.modifySearchBox .column2 {
	width: 100%;
	margin: 0px
}
}
.modifySearchBox .column3 {
	width: calc(34% - 145px);
	margin: 0 5px 0 0
}

@media (max-width: 1199px) {
.modifySearchBox .column3 {
	width: calc(30% - 130px)
}
}

@media (max-width: 1023px) {
.modifySearchBox .column3 {
	width: 50%;
	margin-right: 0px;
	float: left
}
}

@media (max-width: 576px) {
.modifySearchBox .column3 {
	width: 100%;
	margin-top: 11px
}
}
.modifySearchBox .column4 {
	width: 145px
}

@media (max-width: 1199px) {
.modifySearchBox .column4 {
	width: 130px
}
}

@media (max-width: 1023px) {
.modifySearchBox .column4 {
	width: 100%;
	margin-top: 20px;
	float: left
}
}
.modifySearchBox .dfrom_input, .modifySearchBox .dto_input {
	width: calc(50% - 22px);
	float: left
}

@media (max-width: 576px) {
.modifySearchBox .dfrom_input, .modifySearchBox .dto_input {
	width: 100%
}
}

@media (max-width: 576px) {
.modifySearchBox .dfrom_input {
	margin-bottom: 10px
}
}
.modifySearchBox .modifyswap_icon2 {
	float: left;
	width: 45px;
	height: 44px;
	margin: 0px 5px 0 5px;
	border: 1px solid #D5DDE2;
	background-color: #E6ECFF8F;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s
}

@media (max-width: 1299px) {
.modifySearchBox .modifyswap_icon2 {
	height: 41px;
	width: 41px;
	border-radius: 100%;
	background: #fff;
	border: none;
	box-shadow: 0px 2px 6px #0000001a
}
}

@media (max-width: 1023px) {
.modifySearchBox .modifyswap_icon2 {
	margin-top: 0px
}
}

@media (max-width: 576px) {
.modifySearchBox .modifyswap_icon2 {
	margin-top: 0px;
	position: absolute;
	right: 27px;
	top: 22px;
	z-index: 2
}
}
.modifySearchBox .modifyswap_icon2 img {
	transition: all 0.5s
}

@media (max-width: 576px) {
.modifySearchBox .modifyswap_icon2 img {
	transform: rotate(90deg)
}
}
.modifySearchBox .modifyswap_icon2.exchange img {
	transform: rotate(360deg)
}
.modifySearchBox .inputField {
	padding: 10px;
	font-size: 14px;
	font-weight: 600;
	color: #353541;
	border: 1px solid #D5DDE2;
	outline: none;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border-radius: 3px;
	background-color: #E6ECFF8F
}
.modifySearchBox .inputField:hover {
	border-color: #5E5E5E;
	background-color: #E2E9FFE3
}

@media (max-width: 1299px) {
.modifySearchBox .inputField {
	font-size: 13px
}
}
.modifySearchBox .inputField.traveller {
	padding-left: 35px
}
.modifySearchBox .traveller_click {
	width: 100%;
	position: relative
}
.modifySearchBox .traveller_click .traveller_icon {
	position: absolute;
	left: 10px;
	top: 11px;
	color: #0080FF;
	z-index: 2
}
.modifySearchBox .dates {
	display: flex;
	background: #E6ECFF8F;
	border: 1px solid #D5DDE2;
	border-radius: 3px
}
.modifySearchBox .dates .input_block {
	position: relative;
	width: 100%
}
.modifySearchBox .dates .inputField {
	padding-left: 35px;
	border: 1px solid transparent
}
.modifySearchBox .dates .inputField:hover {
	border-color: #5E5E5E
}
.modifySearchBox .icons {
	position: absolute;
	left: 8px;
	top: 4px;
	display: block;
	pointer-events: none;
	font-size: 20px;
	color: #353541;
	z-index: 4
}
.modifySearchBox .searchButton {
	font-family: "Poppins", sans-serif;
	background: #0FF;
	border: none;
	font-size: 14px;
	color: #040504;
	text-align: center;
	border-radius: 5px;
	padding: 11px 20px;
	font-weight: 500;
	outline: none;
	cursor: pointer;
	width: 100%;
	margin-top: 0px
}

@media (max-width: 1199px) {
.modifySearchBox .searchButton {
	padding: 11px 15px;
	font-size: 13px
}
}

@media (max-width: 576px) {
.modifySearchBox .searchButton {
	font-size: 15px
}
}
.modifySearchBox .modify_close_mobile {
	position: absolute;
	right: 10px;
	color: #1F1F1F;
	font-size: 33px;
	top: 2px;
	line-height: 35px;
	display: none;
	z-index: 1
}

@media (max-width: 1023px) {
.modifySearchBox .modify_close_mobile {
	display: block;
	top: 57px
}
}

@media (max-width: 576px) {
.modifySearchBox .modify_close_mobile {
	display: block
}
}

@media (max-width: 576px) {
.innerset {
	padding: 15px
}
}
.modifySearchInfoBox {
	display: none;
	padding: 8px 10px;
	background: #0080FF;
	font-size: 10px;
	white-space: nowrap
}
.modifySearchInfoBox .modifySearchBtn {
	background: #f60;
	width: 36px;
	height: 36px;
	border-radius: 3px;
	float: right;
	border: none;
	color: #fff;
	font-size: 20px
}
.modifySearchInfoBox .searchInfo {
	color: #fff;
	font-weight: 400;
	line-height: 20px
}

@media (max-width: 359px) {
.modifySearchInfoBox .searchInfo {
	font-size: 10px
}
}
.modifySearchInfoBox .searchInfo .code {
	font-size: 16px;
	font-weight: 700
}
.modifySearchInfoBox .searchInfo .date {
	border-left: 1px solid #fff;
	padding-left: 6px;
	margin-left: 6px
}
.modifySearchInfoBox .searchInfo .arrow {
	margin: 0 8px
}

@media (max-width: 576px) {
.modifySearchInfoBox {
	display: block !important;
}
}
.modifySearchBox .inputField.error {
	border: 1px solid red
}
.modifySearchdesktop {
	white-space: nowrap;
	/* cursor: pointer; */
	padding: 12px 10px;
	font-size: 12px;
	color: #353541;
	font-weight: 500;
	margin: 10px auto;
	width: 90%;
	display: flex;
	background: #fff;
	border: 1px solid #D5DDE2;
	border-radius: 5px;
	align-items: center;	
	/*justify-content: center;*/
	justify-content: space-between;
	
	
}

@media (max-width: 1299px) {
.modifySearchdesktop {
	left: 46%
}
}

@media (max-width: 1199px) {
.modifySearchdesktop {
	position: static;
	transform: none;
	margin-top: 7px
}
}

@media (max-width: 576px) {
.modifySearchdesktop {
	display: none
}
}
.modifySearchdesktop .destination2info {
	padding: 0 10px 0 20px;
	width: 48%;
	display: flex
}
.modifySearchdesktop .destination2info .column {
	position: relative;
	width: 135px;
	background: #fff;
	border: 1px solid #D5DDE2;
	border-radius: 3px;
	padding: 4px 8px;
	font-size: 12px
}
.modifySearchdesktop .destination2info .swap_icon2info {
	margin: 0 6px;
	width: 31px;
	background: #fff;
	border: 1px solid #D5DDE2;
	border-radius: 3px;
	padding: 4px 8px
}
.modifySearchdesktop .destination2info .swap_icon2info img {
	width: 16px
}
.modifySearchdesktop .inputClear {
	top: 6px;
	right: 6px
}
.modifySearchdesktop .travellerinfo {
	text-overflow: ellipsis;
	overflow: hidden
}
.modify_open_div {
	display: block;
	position: fixed;
	left: 0px;
	top: 0px;
	overflow-y: auto;
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	z-index: 3
}
.modify_open_div .header {
	padding: 8px 0;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between
}

@media (max-width: 1023px) {
.modify_open_div .header {
	margin-top: -23px
}
}

@media (max-width: 576px) {
.modify_open_div .header {
	margin-top: -14px;
	margin-left: -10px;
	margin-right: -10px;
	padding: 0 0 10px 0
}
}

@media (max-width: 1023px) {
.modify_open_div .header .phoneNumber {
	padding-top: 0px;
	margin-top: 0px
}
}



/****************checked_css*****************/
.page_title2 {
	padding: 20px 0px;
	font-size: 24px;
	color: #040504;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	text-align: center;
	position: relative
}

@media (max-width: 576px) {
.page_title2 {
	font-size: 20px
}
}
.page_title2:before {
	content: '';
	width: 70px;
	height: 2px;
	background: #353541;
	display: block;
	position: absolute;
	left: 50%;
	bottom: 17px;
	transform: translateX(-50%)
}


.addbannerBox {
	padding: 50px 0 10px 0
}
.addbannerBox img {
	width: 100%
}

.dealBox_image2 {
	padding: 20px 0;
	width: 100%;
	overflow: hidden
}

@media (max-width: 1023px) {
.dealBox_image2 {
	overflow: inherit
}
}
.dealBox_image2 .page_title2 {
	font-size: 34px;
	color: #002d5b;
	font-weight: 700;
	text-align: left;
}

@media (max-width: 1199px) {
.dealBox_image2 .page_title2 {
	font-size: 30px
}
}

@media (max-width: 1023px) {
.dealBox_image2 .page_title2 {
	font-size: 26px;
	padding: 10px 0px
}
}

@media (max-width: 576px) {
.dealBox_image2 .page_title2 {
	font-size: 20px
}
}

@media (max-width: 479px) {
.dealBox_image2 .page_title2 {
	font-size: 16px;	
}
.heading h2 {font-size: 15px !important;}
	
}
.dealBox_image2 .text {
	font-size: 15px;
	color: #353541;
	line-height: 29px;
	text-align: center;
	margin: 0px auto
}

@media (max-width: 576px) {
.dealBox_image2 .text {
	font-size: 14px;
	line-height: 24px
}
}

@media (max-width: 479px) {
.dealBox_image2 .text {
	font-size: 12px;
	line-height: 20px
}
}

@media (max-width: 576px) {
.dealBox_image2 .text br {
	display: none
}
}
.dealBox_image2 .holder {
	padding-top: 30px;
	overflow: hidden;
	margin-right: -20px
}

@media (max-width: 1023px) {
.dealBox_image2 .holder {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden
}
}
.dealBox_image2 .column {
	width: calc(25% - 20px);
	margin-right: 20px;
	float: left
}

@media (max-width: 1023px) {
.dealBox_image2 .column {
	width: auto
}
}
.dealBox_image2 .block {
	width: 100%;
	margin-bottom: 20px;
	cursor: pointer;
	background: #F6F6F6;
	border: 1px solid #CFCFCF;
	color: #353541;
	border-radius: 5px;
	overflow: hidden
}

@media (max-width: 1023px) {
.dealBox_image2 .block {
	width: 250px
}
}
.dealBox_image2 .block .detail {
	padding: 0 12px 12px 12px
}
.dealBox_image2 .block h4 {
	font-size: 20px;
	font-weight: 700
}

@media (max-width: 1199px) {
.dealBox_image2 .block h4 {
	font-size: 18px
}
}

@media (max-width: 576px) {
.dealBox_image2 .block h4 {
	font-size: 16px
}
}
.dealBox_image2 .block p {
	margin: 0px;
	font-size: 16px;
	font-weight: 700
}

@media (max-width: 1199px) {
.dealBox_image2 .block p {
	font-size: 14px
}
}
.dealBox_image2 .block p small {
	margin-top: 5px;
	font-size: 13px;
	color: #656565;
	font-weight: 400;
	display: block
}
.dealBox_image2 .block .price {
	font-size: 24px;
	color: #00B405;
	float: right
}

@media (max-width: 1199px) {
.dealBox_image2 .block .price {
	font-size: 20px
}
}
.dealBox_image2 .note {
	font-size: 13px;
	color: #353541;
	line-height: 21px
}

@media (max-width: 1023px) {
.dealBox_image2 .note {
	font-size: 11px;
	padding: 10px 0px;
	line-height: 17px
}
}
.dealBox_image2.international {
	border-top: 1px solid #e9e9e9;
	padding-top: 10px
}
.box-text2 {
	width: 100%;
	padding: 40px 0px 10px 0;
	font-size: 15px;
	line-height: 30px
}

@media (max-width: 1199px) {
.box-text2 {
	font-size: 14px
}
}

@media (max-width: 576px) {
.box-text2 {
	font-size: 12px
}
}

@media (max-width: 1023px) {
.box-text2 {
	padding-top: 26px
}
}
.box-text2 h2 {
	font-size: 24px;
	font-weight: 600;
	text-align: left;
	margin-bottom: 10px
}

@media (max-width: 1199px) {
.box-text2 h2 {
	font-size: 20px;
	margin-bottom: 20px
}
}

@media (max-width: 1023px) {
.box-text2 h2 {
	font-size: 18px
}
}

@media (max-width: 576px) {
.box-text2 h2 {
	font-size: 16px
}
}

@media (max-width: 479px) {
.box-text2 h2 {
	font-size: 14px
}
}
.box-text2 p {
	padding: 0px;
}
.box-text2.grayBg2 {
	background: #f5f6f7
}


.scroll-top {
	background-color: #0080FF;
	border: medium;
	bottom: 70px;
	color: #fff;
	cursor: pointer;
	display: none;
	font-size: 30px;
	height: 40px;
	line-height: 12px;
	opacity: .7;
	outline: medium;
	padding: 0;
	position: fixed;
	right: 20px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	z-index: 99
}
.scroll-top:hover {
	opacity: .99
}
.bottom-call-bar {
	padding: 5px;
	background: #00b67a;
	bottom: -66px;
	left: 0px;
	color: #fff;
	position: fixed;
	font-size: 15px;
	text-align: center;
	width: 100%;
	z-index: 3
}
.bottom-call-bar a span {
	width: 50px;
	height: 50px;
	float: left;
	font-size: 30px;
	text-align: center;
	line-height: 50px;
	background: #fff;
	color: #00b67a;
	border-radius: 50%;
	margin: 0 0 5px 15px
}
.bottom-call-bar .call-text {
	font-size: 12px
}
.bottom-call-bar .phone {
	font-size: 27px;
	line-height: 1.3em;
	font-weight: 700
}
.bottom-call-bar a {
	color: #fff;
	text-decoration: none;
	display: block
}


/************subscribe-Box***************/
.Boxsubscribe {
	padding-bottom: 0px;
}
.Boxsubscribe .left {
	display: flex;
	align-items: center
}

@media (max-width: 576px) {
.Boxsubscribe .left {
	padding: 20px 10px
}
}
.Boxsubscribe .left h4 {
	margin: 0px;
	font-size: 20px;
	font-weight: 600
}

@media (max-width: 1023px) {
.Boxsubscribe .left h4 {
	font-size: 14px;
	font-weight: 700
}
}

@media (max-width: 576px) {
.Boxsubscribe .left h4 {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 5px
}
}
.Boxsubscribe .left p {
	margin: 0px;
	font-size: 14px
}

@media (max-width: 1023px) {
.Boxsubscribe .left p {
	font-size: 12px
}
}
.Boxsubscribe .bell-icon {
	margin-right: 20px;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px
}
.Boxsubscribe .bell-icon img {width: 100%;}	

@media (max-width: 1023px) {
.Boxsubscribe .bell-icon {
	width: 70px;
	height: 70px
}
.Boxsubscribe .bell-icon img {width: 100%;}	
}

@media (max-width: 576px) {
.Boxsubscribe .bell-icon {
	width: 50px;
	height: 50px
}
.Boxsubscribe .bell-icon img {width: 100%;}	
	
}

@media (max-width: 576px) {
.Boxsubscribe .bell-icon img {
	width: 100%;
}
}
.subscribe {
	padding: 5px 0
}

@media (max-width: 576px) {
.subscribe {
	padding: 20px 10px
}
}
.subscribe .text-red {
	padding-top: 12px;
	color: red;
	font-size: 13px;
	width: 100%;
	float: left
}
.subscribe .column {
	float: left;
	width: 43%;
	margin-right: 13px
}

@media (max-width: 576px) {
.subscribe .column {
	width: 100%;
	margin: 0px;
	float: none
}
}
.subscribe .column2 {
	display: flex;
	position: relative
}

@media (max-width: 576px) {
.subscribe .column2 {
	width: 100%;
	margin: 0px;
	display: block
}
}
.subscribe .column2 select {
	border-radius: 5px 0px 0px 5px;
	border-right: 0px
}

@media (max-width: 576px) {
.subscribe .column2 select {
	border-radius: 5px;
	border-right: 1px solid #D1D1D1
}
}
.subscribe .nofill {
	font-size: 13px;
	font-weight: 400;
	margin: 8px 0 0 0
}

@media (max-width: 1023px) {
.subscribe .nofill {
	font-size: 11px
}
}
.subscribe .nofill a {
	color: #3c78f1;
	text-decoration: none;
	display: inline-block
}
.subscribe-submit {
	background: #1889f7;
	color: #fff;
	height: 45px;
	line-height: 20px;
	border: none;
	font-family: "Poppins", sans-serif;
	border-radius: 0px 5px 5px 0px;
	font-weight: 500;
	width: 160px
}

@media (max-width: 576px) {
.subscribe-submit {
	margin-top: 10px;
	width: 100%;
	border-radius: 5px;
	font-weight: 600
}
}
.subscribe-submit:hover, .subscribe-submit:focus, .subscribe-submit:active {
	color: #353541;
	background-color: #f60;
}
.inputform {
	margin-bottom: 10px;
	width: 100%;
	font-weight: 500;
	border: 1px solid #d1d1d1;
	border-radius: 5px 0px 0px 5px;
	padding: 6px 10px;
	height: 45px;
	color: #1F1F1F
}

@media (max-width: 1023px) {
.inputform {
	font-size: 13px;
	border-radius: 5px 5px;
}
}

@media (max-width: 576px) {
.inputform {
	height: 40px;
	border-radius: 5px 5px;
}
}

.sucessfullMsg .thanks {
	font-size: 20px;
	line-height: 75px;
	color: #d2d4d6
}
.sucessfullMsg .thanks img {
	margin-right: 10px;
	width: 100px
}
.sucessfullMsg .msg {
	font-size: 17px;
	color: #d2d4d6;
	padding: 20px 0px
}
.sucessfullMsg .text {
	font-size: 13px;
	color: #d2d4d6
}
.mobileFilterBox {
	display: none;
	padding: 5px 10px;
	border: 1px solid #E9E9E9
}
.mobileFilterBox .filter_text {
	padding-top: 5px;
	float: left;
	font-weight: 500;
	padding-right: 11px
}
.mobileFilterBox ul {
	margin: 0;
	padding: 4px 0;
	overflow-x: scroll;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: flex;
	list-style: none
}
.mobileFilterBox ul li {
	margin-right: 3px;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 500;
	display: inline-block;
	padding: 0px 7px 0 9px;
	height: 24px;
	line-height: 23px;
	border-radius: 13px
}
.mobileFilterBox ul li a {
	color: #5B5B5B;
	text-decoration: none
}
.mobileFilterBox ul li .resetFilter {
	display: none;
	font-size: 18px;
	line-height: 15px;
	position: relative;
	top: 3px;
	cursor: pointer;
	color: #0080FF
}
.mobileFilterBox ul li.is-active {
	background: #d9e7ff
}
.mobileFilterBox ul li.is-active a {
	color: #0080FF
}
.mobileFilterBox ul li.is-active .resetFilter {
	display: inline-block
}

@media (max-width: 1199px) {
.mobileFilterBox {
	display: block
}
}
.filterBox {
	font-family: "Poppins", sans-serif;
	width: 100%;
	margin-bottom: 25px;
	padding: 15px 5px 20px
}

@media (max-width: 1199px) {
.filterBox {
	padding: 15px 15px 20px
}
}
.filterBox .head {
	margin-bottom: 5px;
	padding-bottom: 8px;
	display: flex;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	position: relative
}
.filterBox h3 {
	font-weight: 600;
	font-size: 14px;
	margin: 0px
}

@media (max-width: 576px) {
.filterBox h3 {
	color: #0080FF
}
}
.filterBox .all__btn {
	cursor: pointer;
	font-size: 13px;
	font-weight: 500;
	border: none;
	color: #0080FF;
	padding: 0px;
	margin-left: 5px;
	position: absolute;
	right: 0px;
	top: 0px;
	outline: none;
	background: none
}
.filterBox .inputSet label {
	padding-left: 0px;
	line-height: 18px;
	font-weight: 400;
	display: flex;
	align-items: center
}
.filterBox .checkmark {
	float: left;
	border-radius: 4px;
	border-color: #ACB4BE;
	background: #fff
}
.filterBox .inputSet input[type="checkbox"]:checked ~ .checkmark, .filterBox .checked {
	border: 1px solid #0080FF;
	background-color: #0080FF
}
.filterBox .inputSet .checkmark:after, .filterBox .inputSet .checkmark.checked:after {
	border-color: #fff
}
.filterBox ul {
	list-style: none;
	padding: 0px;
	margin: 0px
}
.filterBox ul li {
	font-family: "Poppins", sans-serif;
	margin: 10px 0;
	font-size: 13px;
	padding: 0px
}
.filterBox ul li .filter_price {
	float: right;
	font-weight: 500
}
.filterBox ul li .text-overflow {
	padding-top: 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis
}
.filterBox .filter-item {
	border-bottom: 1px solid #a9a9a9;
	padding-bottom: 6px;
	margin-bottom: 15px;
	font-size: 13px;
	position: relative
}
.filterBox .filter-item.filterdisable:before {
	content: '';
	background: rgba(255,255,255,0.5);
	width: calc(100% + 15px);
	height: 88%;
	position: absolute;
	left: -5px;
	top: 24px;
	z-index: 2
}

@media (max-width: 576px) {
.filterBox .filter-item {
	border-bottom: none
}
}
.filterBox .filter-item:last-child {
	border: none;
	margin-bottom: 0px
}
.filterBox .ui-slider-horizontal {
	height: 3px;
	background: #333333;
	border: none;
	z-index: 0;
	margin: 17px 5px
}
.filterBox .ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
	background: #0080FF
}
.filterBox .ui-slider-horizontal .ui-slider-handle {
	top: -.6em;
	margin-left: -.6em;
	background: #fff;
	border: 3px solid #0080FF;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer
}
.filterBox .duration_time {
	font-size: 12px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 9px 0px;
	font-weight: 600
}
.filterBox .showmore {
	color: #0080FF;
	text-decoration: none;
	font-weight: 500
}
.filterBox ul.time_filter {
	list-style: none;
	margin-right: -9px;
	overflow: hidden
}
.filterBox ul.time_filter li {
	width: calc(50% - 9px);
	min-height: 75px;
	margin-right: 9px;
	margin-bottom: 9px;
	float: left;
	font-size: 12px;
	border: 1px solid #CED0D4;
	border-radius: 5px;
	text-align: center;
	padding: 0 9px 9px;
	cursor: pointer
}
.filterBox ul.time_filter li .icons {
	width: 34px;
	height: 34px;
	display: block;
	margin: 0px auto
}
/* .filterBox ul.time_filter li .icons.icon_earlymorning {
	background: url() center center no-repeat
}
.filterBox ul.time_filter li .icons.icon_morning {
	background: url() center center no-repeat
}
.filterBox ul.time_filter li .icons.icon_afternoon {
	background: url() center center no-repeat
}
.filterBox ul.time_filter li .icons.icon_evening {
	background: url() center center no-repeat
} */
.filterBox ul.time_filter li.selected {
	background: #0080FF;
	border-color: #0080FF;
	color: #fff
}
/* .filterBox ul.time_filter li.selected .icon_earlymorning {
	background: url() center center no-repeat
}
.filterBox ul.time_filter li.selected .icon_morning {
	background: url() center center no-repeat
}
.filterBox ul.time_filter li.selected .icon_afternoon {
	background: url() center center no-repeat
}
.filterBox ul.time_filter li.selected .icon_evening {
	background: url() center center no-repeat
} */
.filterBox ul.time_filter li strong {
	display: block
}
.filterBox ul.time_filter li .time {
	font-weight: 500
}
.clearAllFilterBtn {
	margin-top: 10px;
	font-size: 14px;
	color: #fff;
	text-decoration: none;
	background: #333333;
	display: block;
	text-align: center;
	border: 2px solid #333333;
	padding: 9px;
	border-radius: 3px
}
.clearAllFilterBtn:hover {
	color: #0080FF;
	border-color: #0080FF;
	background: transparent
}
.filter-main-head {
	background: #0FF;
	height: 48px;
	color: #040504;
	font-size: 16px;
	padding: 12px 15px;
	display: none
}

@media (max-width: 1199px) {
.filter-main-head {
	display: block
}
}
.filter-main-head .filter_close_mobile {
	position: absolute;
	right: 5px;
	color: #040504;
	font-size: 33px;
	top: 4px;
	line-height: 42px;
	width: 42px;
	text-align: center
}

@media (max-width: 576px) {
.tab-pane {
	display: none
}
}

@media (max-width: 576px) {
.tab-pane.active {
	display: block
}
}
.total_results_mobile {
	font-size: 13px;
	color: #1F1F1F;
	font-weight: 600;
	margin: 0px 15px;
	padding: 15px 0;
	border-bottom: 1px solid #ccc;
	display: none
}

@media (max-width: 576px) {
.total_results_mobile {
	display: block
}
}
.filterTabs {
	width: auto;
	margin: 0;
	padding: 0px;
	list-style: none;
	background: #fff;
	overflow: hidden;
	flex-wrap: nowrap;
	overflow-x: auto;
	display: none
}

@media (max-width: 576px) {
.filterTabs {
	display: flex
}
}
.filterTabs li {
	position: relative;
	border-bottom: 1px solid #0080FF
}
.filterTabs li a {
	display: block;
	padding: 11px 15px;
	color: #0080FF;
	font-size: 13px;
	cursor: pointer;
	text-decoration: none;
	width: 110px;
	border-right: 1px solid #0080FF;
	text-align: center
}
.filterTabs li.active a {
	background: #D9E7FF
}
.mobile_apply_filter {
	background: #0080FF;
	font-size: 16px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
	margin: 0 10px;
	border-radius: 5px;
	position: fixed;
	left: -200%;
	width: 95%;
	bottom: 10px;
	text-align: center;
	padding: 13px;
	cursor: pointer
}
.mobile_apply_filter.disable {
	background: #a9a6a6
}

@media (max-width: 576px) {
.leftCntr.show-component-mobile.open .mobile_apply_filter {
	left: 0px
}
}
#dept-slide-range, #ret-slide-range, #dept-layover-slide-range, #ret-layover-slide-range, #dept-layover-slide, #ret-layover-slide {
	background: #4F8FFC;
	height: 3px;
	position: absolute
}
.graph_div {
	overflow: hidden;
	margin-bottom: 15px;
	background: #FFFFFF;
	padding: 10px 13px 10px 13px;
	position: relative;
	border-radius: 5px;
	border: 1px solid #CECECE
}
.graph_div .inner_graph {
	position: relative;
	overflow: hidden
}
.graph_div .time {
	margin: 6px 5px 0 0;
	font-size: 9px;
	float: right;
	width: 64%
}
.graph_div .time span {
	width: 20%;
	display: inline-block;
	text-align: center;
	position: relative
}
.graph_div .time span:before {
	content: '';
	width: 1px;
	height: 9px;
	display: block;
	background: #9f9f9f;
	position: absolute;
	right: 0px;
	top: 2px
}
.graph_div .time span:last-child:before {
	display: none
}
.graph_div .redline {
	width: 1px;
	display: block;
	height: 100%;
	background: #FF7A2A;
	position: absolute;
	left: 60px;
	top: 0px
}
.filterBox ul.graph {
	list-style: none;
	display: flex;
	width: 64%;
	position: absolute;
	right: 5px;
	bottom: 0px;
	align-items: baseline
}
.filterBox ul.graph li {
	width: 5px;
	height: 85px;
	background: #0FF;
	margin: 0 1px
}
.filterBox ul.graph li.fade {
	background: #CCCCCC;
	opacity: 1
}
.graph-row {
	padding: 3px 0;
	position: relative
}
.graph-row:before {
	content: '';
	display: block;
	width: 64%;
	height: 1px;
	background: #e3ecff;
	position: absolute;
	right: 0px;
	top: 13px
}
.filterTabs::-webkit-scrollbar, .ulcompare_list::-webkit-scrollbar {
display:none
}
.filterTabs, .ulcompare_list {
	-ms-overflow-style: none;
	scrollbar-width: none
}

@media (max-width: 576px) {
.iphone_div, .mobile_height {
	height: 80px;
	width: 100%
}
}
.clearfitlerMobile {
	background: #3c78f1;
	color: #fff;
	padding: 5px 11px;
	border-radius: 5px;
	font-size: 13px;
	text-decoration: none
}
.sortingBox {
	position: relative;
	margin: 0px 0 14px 0;
	background: #fff;
	box-shadow: 0 0px 6px #0000004D;
	border-radius: 5px
}

@media (max-width: 1199px) {
.sortingBox {
	width: calc(100% - 86px);
	float: left
}
}

@media (max-width: 479px) {
.sortingBox {
	width: calc(100% - 46px);
	float: left
}
}
.sortingBox ul.sorting {
	list-style: none;
	padding: 0px;
	margin: 0px
}

@media (max-width: 576px) {
.sortingBox ul.sorting {
	white-space: nowrap;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden
}
}
.sortingBox ul.sorting li {
	width: calc(33.3% - 30px);
	margin: 0 15px;
	border-bottom: 4px solid transparent;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-size: 12px;
	font-weight: 400;
	float: left;
	padding: 12px 10px 10px 20px;
	position: relative
}

@media (max-width: 479px) {
.sortingBox ul.sorting li {
	width: auto;
	margin: 0 10px;
	padding: 12px 10px 10px 10px;
	font-size: 13px
}
}

@media (max-width: 359px) {
.sortingBox ul.sorting li {
	margin: 0 5px;
	padding: 12px 8px 10px 8px;
	font-size: 12px
}
}
.sortingBox ul.sorting li .icons {
	position: absolute;
	left: 24px;
	top: 5px
}

@media (max-width: 576px) {
.sortingBox ul.sorting li .icons {
	display: none
}
}
.sortingBox ul.sorting li .price {
	font-size: 13px;
	color: #353541;
	font-weight: 400;
	display: block
}
.sortingBox ul.sorting li .price b {
	font-weight: 600
}

@media (max-width: 576px) {
.sortingBox ul.sorting li .price {
	font-size: 12px
}
}

@media (max-width: 479px) {
.sortingBox ul.sorting li .price {
	font-size: 10px
}
}
.sortingBox ul.sorting li.selected {
	border-color: #0080FF
}
.sortingBox ul.sorting li:before {
	content: "";
	background: #ACB4BE;
	width: 1px;
	height: 28px;
	position: absolute;
	left: 0px;
	top: 16px
}
.sortingBox ul.sorting li:first-child:before {
	display: none
}
.sortingBox ul.sorting li:first-child {
	text-align: center
}
.sortingBox ul.sorting .all_results {
	padding: 21px 20px 21px 50px;
	position: relative;
	font-weight: 600
}
.sortingBox ul.sorting .all_results:after {
	width: 16px;
	height: 12px;
	display: block;
	content: '';
	/* background: url() left top no-repeat; */
	position: absolute;
	left: 20px;
	top: 26px
}

@media (max-width: 576px) {
.sortingBox ul.sorting .all_results:after {
	left: 10px
}
}

@media (max-width: 576px) {
.sortingBox ul.sorting .all_results {
	padding: 20px 9px 6px 30px
}
}
.sortingBox ul.sorting .all_results.selected {
	border-color: #0080FF;
	padding: 12px 20px 7px 50px;
	font-weight: 400
}

@media (max-width: 576px) {
.sortingBox ul.sorting .all_results.selected {
	padding-left: 32px
}
}
.sortingBox ul.sorting .all_results.selected:after {
	top: 20px
}
.sortingBox ul.sorting .all_results.selectedsort {
	padding: 12px 20px 7px 50px;
	font-weight: 400
}

@media (max-width: 576px) {
.sortingBox ul.sorting .all_results.selectedsort {
	padding-left: 32px
}
}
.sortingBox ul.sorting .all_results.selectedsort:after {
	top: 20px
}
.sortingBox ul.sorting .all_results #sortingText {
	font-size: 13px;
	white-space: nowrap;
	font-weight: 400
}
.sorting_dropdown {
	position: absolute;
	right: 0px;
	top: 65px;
	z-index: 2;
	width: 230px;
	padding: 0px;
	border-radius: 5px;
	background: #fff;
	border: 1px solid #0080FF;
	box-shadow: 0 0 8px #0000004F
}
.sorting_dropdown ul {
	padding: 0;
	margin: 0px;
	height: 200px;
	overflow-y: auto;
	overflow-x: hidden
}
.sorting_dropdown ul li {
	font-size: 14px;
	color: #353541;
	line-height: 20px;
	padding: 10px 16px
}
.sorting_dropdown ul li:first-child:hover {
	border-radius: 5px 5px 0 0
}
.sorting_dropdown ul li:last-child:hover {
	border-radius: 0px 0px 5px 5px
}
.sorting_dropdown ul li:hover {
	background-color: #e6ebef;
	cursor: pointer
}
.filter_icon_mobile {
	width: 75px;
	float: left;
	overflow: hidden;
	margin: 0px 0 14px 10px;
	background: #fff;
	position: relative;
	box-shadow: 0 4px 3px #00000005;
	border-radius: 5px;
	height: 60px;
	display: none;
	align-items: center;
	justify-content: center;
	font-size: 28px
}

@media (max-width: 1199px) {
.filter_icon_mobile {
	display: flex
}
}

@media (max-width: 479px) {
.filter_icon_mobile {
	width: 40px;
	margin-left: 6px;
	font-size: 24px
}
}

@media (max-width: 359px) {
.filter_icon_mobile {
	height: 60px
}
}
.filter_icon_mobile .filterCount {
	background: #01b10e;
	color: #fff;
	width: 20px;
	height: 20px;
	display: block;
	position: absolute;
	right: 3px;
	top: 3px;
	border-radius: 100%;
	font-size: 12px;
	text-align: center;
	line-height: 20px
}
.applyedFilterBox {
	width: 100%;
	overflow: hidden
}

@media (max-width: 576px) {
.applyedFilterBox {
	display: none
}
}
.applyedFilterBox ul {
	list-style: none;
	float: left;
	margin-right: -5px;
	margin-bottom: 0px
}
.applyedFilterBox ul li {
	font-family: "Poppins", sans-serif;
	position: relative;
	margin: 10px 5px 0px 0px;
	display: inline-block;
	border: 0;
	padding: 7px 28px 7px 10px;
	border-radius: 20px;
	background: #D8D9DF;
	color: #0B1E34;
	font-size: 12px;
	float: left
}
.applyedFilterBox ul .resetFilter {
	font-size: 21px;
	position: absolute;
	top: 2px;
	right: 6px;
	cursor: pointer
}
.applyedFilterBox .clear_filter {
	margin-top: 15px;
	margin-left: 5px;
	display: inline-block;
	font-size: 13px;
	color: #4F8FFC;
	text-decoration: underline;
	cursor: p
}
.loadingfilter {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.2);
	z-index: 7;
	display: flex;
	align-items: center;
	justify-content: center
}
.loadingfilter .loader_icon {
	width: 70px;
	height: 70px;
	background-color: #fff;
	border-radius: 100%;
	/* background: url() center center no-repeat */
}
.loadingfilter .circle-loader {
	width: 70px;
	height: 70px
}
.resultsBox {
	margin-bottom: 10px;
	box-shadow: 0 0px 6px #0000004d;
	border-radius: 5px;
	background: #fff;
	position: relative;
	font-family: "Poppins", sans-serif;
	color: #1f1f1f
}
.resultsBox .block_left {
	padding: 15px;
	height: 100%;
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	justify-content: space-evenly
}
.resultsBox .block_left.addresults {
	height: 100%;
	display: flex;
	align-items: center
}

@media (max-width: 1299px) {
.resultsBox .block_left {
	padding: 10px
}
}

@media (max-width: 576px) {
.resultsBox .airline-detail {
	padding-bottom: 15px
}
}
.resultsBox .airline-detail img {
	width: 24px;
	float: left;
	margin-right: 7px
}
.resultsBox .airline-detail .name {
	font-size: 12px;
	color: #1f1f1f;
	margin-left: 34px;
	line-height: 31px
}
.resultsBox .airline-detail .name strong {
	font-weight: 600;
	font-size: 13px
}

@media (max-width: 1299px) {
.resultsBox .airline-detail .name strong {
	font-size: 12px
}
}
.resultsBox .airline-detail.multiple_logo .logo_c {
	display: flex;
	align-items: center
}
.resultsBox .airline-detail.multiple_logo img {
	float: none;
	width: 28px;
	display: inline-block
}
.resultsBox .airline-detail.multiple_logo .name {
	display: block;
	margin: 0px
}
.resultsBox .leg-details {
	margin-top: -6px
}
.resultsBox .leg-details .city {
	width: 70px;
	float: left
}
.resultsBox .leg-details .city .time {
	color: #353541;
	font-size: 15px;
	font-weight: 600
}
.resultsBox .leg-details .city .time strong {
	font-weight: 600
}

@media (max-width: 576px) {
.resultsBox .leg-details .city .time {
	font-size: 14px;
	line-height: 15px
}
}
.resultsBox .leg-details .city .time strong {
	position: relative
}
.resultsBox .leg-details .city .next_day {
	font-size: 11px;
	margin-top: -12px;
	color: #fe2029;
	font-style: italic;
	font-weight: 600
}
.resultsBox .leg-details .city .code {
	font-size: 12px;
	position: relative;
	display: inline-block
}
.resultsBox .leg-details .city .code:hover .tooltipcity {
	opacity: 1;
	display: block
}
.resultsBox .leg-details .city sup {
	font-weight: 700
}
.resultsBox .leg-details .highlight {
	background: #ffd230;
	padding: 1px 5px;
	border-radius: 3px;
	font-weight: 600
}
.resultsBox .leg-details .alternetdate {
	position: absolute;
	font-size: 12px;
	white-space: nowrap
}
.resultsBox .leg-devider {
	height: 20px
}

@media (max-width: 576px) {
.resultsBox .leg-devider {
	height: 10px
}
}
.resultsBox .connnecting-block {
	padding: 17px 10px 24px 24px;
	float: left;
	width: calc(100% - 140px)
}
.resultsBox .connnecting-block .leg-points {
	border-top: 1px dashed #3c78f1;
	height: 1px;
	position: relative;
	text-align: center;
	font-size: 12px;
	color: #5b5b5b
}
.resultsBox .connnecting-block .leg-points:before {
	content: "";
	width: 8px;
	height: 8px;
	display: block;
	position: absolute;
	left: 0px;
	top: -5px;
	background: #353541;
	border-radius: 100%
}
.resultsBox .connnecting-block .leg-points:after {
	position: absolute;
	height: 16px;
	display: block;
	content: "";
	left: auto;
	right: -3px;
	top: -9px;
	/* background: url() no-repeat right center; */
	width: 14px
}
.resultsBox .connnecting-block .leg-points .stop_div {
	display: inline-block;
	width: 70px;
	margin-top: -25px;
	position: relative;
	font-family: "Poppins", sans-serif
}
.resultsBox .connnecting-block .leg-points .destination2_code {
	margin-top: 9px;
	font-weight: 700;
	padding: 4px;
	border-radius: 2px;
	color: #353541
}
.resultsBox .connnecting-block .leg-points .destination2_code img {
	display: none
}
.resultsBox .connnecting-block .leg-points .destination2_code.selftransfer {
	background: #ffe8e8
}
.resultsBox .connnecting-block .leg-points .destination2_code.selftransfer img {
	display: inline-block
}
.resultsBox .connnecting-block .leg-points .layovertime {
	padding-bottom: 5px
}
.resultsBox .connnecting-block .leg-points .stop_icon {
	position: absolute;
	left: 50%;
	top: 20px;
	transform: translateX(-50%);
	cursor: pointer;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background: #a1a1a1;
	border-radius: 100%
}
.resultsBox .price-section {
	padding: 15px 15px 15px 0;
	font-size: 12px;
	color: #353541;
	font-weight: 400;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between
}

@media (max-width: 576px) {
.resultsBox .price-section {
	padding: 14px 10px;
	display: block;
	border-top: 1px solid #e5ebf0
}
}
.resultsBox .price-section a {
	width: 100%;
	display: block
}

@media (max-width: 576px) {
.resultsBox .price-section a {
	width: auto;
	display: inline-block
}
}
.resultsBox .price-section .providertext {
	color: #3c78f1;
	font-weight: 600
}
.resultsBox .price-section .left_mob {
	width: 100%
}

@media (max-width: 576px) {
.resultsBox .price-section .left_mob {
	float: left;
	width: calc(96% - 104px)
}
}

@media (max-width: 359px) {
.resultsBox .price-section .left_mob {
	width: calc(96% - 87px)
}
}

@media (max-width: 1023px) {
.resultsBox .price-section .price_mob {
	float: left;
	text-align: right;
	width: 100%
}
}

@media (max-width: 576px) {
.resultsBox .price-section .price_mob {
	float: right;
	text-align: right;
	width: auto
}
}

@media (max-width: 576px) {
.resultsBox .price-section .price_mob .per-person {
	font-size: 11px;
	line-height: 11px
}
}

@media (max-width: 576px) {
.resultsBox .price-section .float-start-sm {
	float: left;
	text-align: left
}
}
.resultsBox .price-section .fClass {
	color: #1f1f1f;
	font-weight: 500;
	margin-bottom: 5px
}

@media (max-width: 576px) {
.resultsBox .price-section .fClass {
	margin-top: 12px;
	float: right;
	width: 130px;
	display: none
}
}
.resultsBox .price-section .price {
	font-size: 26px;
	color: #353541;
	font-weight: 700;
	line-height: 26px
}

@media (max-width: 479px) {
.resultsBox .price-section .price {
	font-size: 20px
}
}

@media (max-width: 359px) {
.resultsBox .price-section .price {
	font-size: 16px
}
}
.resultsBox .price-section .selectBtn {
	margin: 10px 0;
	width: 140px;
	display: inline-block;
	padding: 7px 5px;
	text-align: center;
	font-size: 15px;
	background: #3c78f1;
	color: #fff;
	border: 2px solid #3c78f1;
	cursor: pointer;
	border-radius: 5px;
	font-weight: 600;
	text-decoration: none
}

@media (max-width: 1299px) {
.resultsBox .price-section .selectBtn {
	width: 100%
}
}

@media (max-width: 1199px) {
.resultsBox .price-section .selectBtn {
	width: 140px
}
}

@media (max-width: 1023px) {
.resultsBox .price-section .selectBtn {
	width: 104px;
	padding: 5px;
	margin: 0px
}
}

@media (max-width: 359px) {
.resultsBox .price-section .selectBtn {
	width: 90px
}
}
.resultsBox .price-section .selectBtn.selected {
	color: #3c78f1;
	background: #fff
}
.resultsBox #fltprice {
	text-align: right
}
.resultsBox .depart-flight {
	padding-top: 20px;
	width: 100%
}

@media (max-width: 576px) {
.resultsBox .depart-flight {
	padding-top: 2px
}
}
.resultsBox .flightDetail {
	font-size: 13px;
	color: #4f8ffc;
	text-decoration: none
}
.resultsBox .border-right {
	border-right: 1px solid #e5ebf0
}

@media (max-width: 576px) {
.resultsBox .rslogo {
	width: 90px
}
}
.resultsBox .site_content {
	color: #353541;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%
}
.resultsBox .site_content h4 {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 11px
}

@media (max-width: 576px) {
.resultsBox .site_content h4 {
	margin: 10px 0px
}
}
.resultsBox .site_content p {
	font-size: 12px;
	margin: 0px 0 10px 0
}
.resultsBox .site_content .add_link {
	color: #1b75bc
}
.resultsBox .site_content .add_link a {
	color: #1b75bc;
	text-decoration: none
}
.more_results {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #4f8ffc;
	background: #fff;
	padding: 13px;
	font-size: 16px;
	font-weight: 500;
	color: #4f8ffc;
	text-align: center
}
.searchComparehome {
	padding: 15px;
	overflow: hidden;
	margin: 0px 0 14px 0;
	background: #fff;
	box-shadow: 0 0px 6px #0000004d;
	border-radius: 5px
}
.searchComparehome h2 {
	font-size: 18px;
	font-weight: 700;
	color: #353541;
	text-align: center
}
.searchComparehome p {
	color: #353541;
	text-align: center;
	font-size: 12px
}
.searchComparehome ul {
	list-style: none;
	margin: 0px;
	padding: 0;
	flex-flow: wrap
}
.searchComparehome ul li {
	margin: 10px 0 10px 0;
	font-size: 14px;
	font-weight: 500;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between
}
.searchComparehome ul img {
	height: 37px
}
.searchComparehome label {
	background: #fff;
	padding: 1px;
	float: left;
	position: relative;
	width: calc(100% - 120px);
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center
}
.searchComparehome .view_button {
	font-size: 14px;
	color: #040504;
	font-weight: 600;
	background: #ffd230;
	border-radius: 5px;
	padding: 10px;
	width: calc(46% - 8px);
	display: block;
	float: left;
	margin-left: 8px;
	text-decoration: none;
	text-align: center
}
.comparebutton {
	margin-top: 15px;
	width: 100%;
	background: #ffd230;
	border: none;
	font-size: 15px;
	color: #040504;
	text-align: center;
	cursor: pointer;
	border-radius: 6px;
	padding: 13px 10px;
	outline: none;
	font-weight: 600
}

@media (max-width: 576px) {
.comparebutton {
	display: none
}
}
#scrollBottomtop {
	width: 40px;
	height: 40px;
	background: #ffd230;
	border: none;
	border-radius: 100%;
	position: fixed;
	right: 10px;
	bottom: 10px;
	font-size: 29px;
	color: #040504
}
.ulcompare_list {
	width: 100%;
	list-style: none;
	margin: 0px;
	padding: 8px 10px 8px 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 3px;
	margin-top: 7px
}

@media (max-width: 576px) {
.ulcompare_list {
	overflow-x: auto;
	padding-top: 0px;
	margin: 0 0 10px 0
}
}
.ulcompare_list li {
	padding-right: 18px;
	white-space: nowrap;
	position: relative
}

@media (max-width: 576px) {
.ulcompare_list li {
	border: 1px solid #353541;
	border-radius: 5px;
	padding: 0px;
	margin-right: 7px
}
}

@media (max-width: 576px) {
.ulcompare_list li.last {
	padding-right: 16px
}
}
.ulcompare_list li a {
	font-size: 13px;
	color: #3c78f1;
	font-weight: 600;
	text-decoration: none
}

@media (max-width: 576px) {
.ulcompare_list li a {
	font-size: 11px;
	padding: 7px 10px;
	display: block
}
}
.ulcompare_list li a span {
	font-weight: 400;
	color: #333
}
.ulcompare_list .bi-arrow-right-short {
	font-weight: 800;
	font-size: 22px;
	line-height: 19px;
	position: absolute
}

@media (max-width: 576px) {
.ulcompare_list .bi-arrow-right-short {
	top: 5px
}
}
.flight_detail_link {
	padding-right: 11px;
	position: relative;
	display: inline-block;
	margin-top: 10px;
	font-size: 12px;
	color: #353541;
	font-weight: 600;
	text-decoration: none
}
.flight_detail_link:after {
	position: absolute;
	right: 0px;
	top: 4px;
	content: "";
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-left: 7px solid #555;
	border-bottom: 5px solid transparent
}

@media (max-width: 576px) {
.flight_detail_link {
	position: absolute;
	left: 13px
}
}
.total_results {
	font-weight: 600
}
.total_results a {
	text-decoration: none
}
.total_results span {
	color: #3c78f1
}
.matrixAirline {
	margin-bottom: 10px;
	padding: 10px;
	width: 100%;
	background: #e6ebfe;
	border-radius: 5px;
	box-shadow: 0px 0px 6px #0000004d
}
.matrixAirline h3 {
	margin-bottom: 5px;
	font-size: 18px;
	color: #1a1a1a;
	font-weight: 600
}
.matrixAirline ul {
	list-style: none;
	display: flex;
	padding: 0px;
	margin-bottom: 0px;
	margin-right: -15px
}
.matrixAirline ul li {
	padding: 10px;
	width: 16.6%;
	margin-right: 15px;
	background: #ffffff;
	border-radius: 20px;
	text-align: center
}

@media (max-width: 576px) {
.matrixAirline ul li {
	width: auto
}
}

@media (max-width: 576px) {
.matrixAirline ul li .items {
	width: 112px
}
}
.matrixAirline ul li img {
	width: 35px;
	display: inline-block;
	margin-bottom: 6px
}
.matrixAirline ul li strong {
	font-size: 18px;
	color: #1a1a1a;
	display: block
}
.matrixAirline ul li p {
	font-size: 13px;
	color: #6d6d6d;
	margin: 0px
}
#comparePopup .searchComparehome {
	box-shadow: none
}
.no__resultcompare {
	border-radius: 5px;
	box-shadow: 0px 0px 6px #00000029;
	margin: auto;
	width: 504px
}

@media (max-width: 576px) {
.no__resultcompare {
	width: 95%
}
}
.no__resultcompare .headerCompare {
	background: #3c78f1;
	border-radius: 5px 5px 0px 0px;
	text-align: center;
	color: #fff;
	padding: 22px
}
.no__resultcompare h2 {
	font-size: 18px;
	font-weight: 700;
	color: #fff;
	text-align: center
}
.no__resultcompare p {
	margin-bottom: 0px;
	color: #fff
}
.no__resultcompare .searchComparehome {
	box-shadow: none
}
.scrollLoader {
	display: none;
	align-items: center;
	justify-content: center;
	flex-direction: column
}

@media (max-width: 576px) {
.scrollLoader {
	display: flex
}
}
.scrollLoader .loader_icon {
	width: 50px;
	height: 50px;
	background-size: 17px;
	background-color: #fff;
	border-radius: 100%
}
.scrollLoader .circle-loader {
	border: 2px solid #eeeeee;
	border-left-color: #4f8ffc;
	border-top-color: #4f8ffc;
	width: 50px;
	height: 50px
}
.selftransfer_title {
	color: #FF1D1D !important;
	display: block;
	font-size: 13px;
	padding: 3px 0
}
.detail-stop img {
	display: none
}
.detail-stop.selftransfer_detailsort {
	background: #ffe8e8;
	padding: 4px;
	border-radius: 2px;
	color: #353541;
	margin-top: 2px;
	display: inline-block
}
.detail-stop.selftransfer_detailsort img {
	margin-right: 5px;
	display: inline-block
}
.selftransfer_detail {
	padding: 10px;
	background: #FFE8E8;
	border-radius: 50px;
	text-align: center;
	margin: 10px 0
}
.selftransfer_detail strong {
	color: #FF1D1D;
	display: block;
	font-size: 13px;
	text-align: center
}
.selftransfer_detail p {
	margin: 0px
}
.selftransfer_popup {
	position: relative;
	width: 615px;
	margin: 0px auto;
	background: #fff;
	border-radius: 10px
}

@media (max-width: 576px) {
.selftransfer_popup {
	width: 95%
}
}
.selftransfer_popup .topSection {
	text-align: center;
	padding: 15px;
	background: #FFE8E8;
	border-radius: 10px 10px 0 0;
	color: #353541
}
.selftransfer_popup .topSection img {
	width: 55px;
	margin-bottom: 5px
}
.selftransfer_popup .topSection h4 {
	font-size: 22px;
	font-weight: 600
}

@media (max-width: 479px) {
.selftransfer_popup .topSection h4 {
	font-size: 16px
}
}
.selftransfer_popup .topSection small {
	font-size: 12px;
	display: block
}

@media (max-width: 479px) {
.selftransfer_popup .topSection small {
	font-size: 11px
}
}
.selftransfer_popup .midSection {
	font-size: 13px;
	color: #353541;
	padding: 15px
}

@media (max-width: 479px) {
.selftransfer_popup .midSection {
	font-size: 12px;
	padding: 10px
}
}
.selftransfer_popup .midSection ul {
	list-style: none;
	padding: 15px
}

@media (max-width: 576px) {
.selftransfer_popup .midSection ul {
	padding: 10px
}
}

@media (max-width: 479px) {
.selftransfer_popup .midSection ul {
	padding: 0px
}
}
.selftransfer_popup .midSection ul li {
	padding: 6px 0;
	position: relative
}

@media (max-width: 576px) {
.selftransfer_popup .midSection ul li {
	padding: 7px 0 7px 33px
}
}
.selftransfer_popup .midSection ul li img {
	margin-right: 6px
}

@media (max-width: 576px) {
.selftransfer_popup .midSection ul li img {
	position: absolute;
	left: 0px;
	top: 5px
}
}
.selftransfer_popup .midSection .info {
	padding: 10px;
	border: 1px solid #C4C4C4;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between
}

@media (max-width: 576px) {
.selftransfer_popup .midSection .info {
	padding: 10px 10px 10px 42px;
	position: relative
}
}

@media (max-width: 479px) {
.selftransfer_popup .midSection .info {
	flex-flow: wrap
}
}
.selftransfer_popup .midSection .info img {
	margin-right: 6px
}

@media (max-width: 576px) {
.selftransfer_popup .midSection .info img {
	position: absolute;
	left: 10px;
	top: 10px
}
}
.selftransfer_popup .midSection .info p {
	margin: 0px
}
.selftransfer_popup .midSection .info a {
	background: #0080FF;
	color: #fff;
	border-radius: 5px;
	text-decoration: none;
	display: inline-block;
	padding: 8px 15px;
	font-size: 15px;
	font-weight: 500;
	margin-left: 9px
}

@media (max-width: 479px) {
.selftransfer_popup .midSection .info a {
	margin-left: 0px;
	margin-top: 10px
}
}
.selftransfer_popup .bottomSection {
	padding: 10px;
	background: #D7E3FC;
	border-radius: 0 0 10px 10px;
	text-align: center;
	font-size: 11px;
	color: #353541
}
.selftransfer_popup .bottomSection a {
	color: #0080FF;
	text-decoration: none
}
.selftransfer_popup .close__popupself {
	width: 38px;
	text-align: center;
	display: inline-block;
	height: 27px;
	position: absolute;
	right: 7px;
	top: 7px;
	color: #353541;
	font-size: 23px
}
.compare_strip {
	background: #0080FF;
	color: #fff;
	text-align: center;
	padding: 9px 0px
}
.compare_strip h3 {
	font-size: 24px;
	font-weight: 300;
	margin: 0px
}

@media (max-width: 576px) {
.compare_strip h3 {
	font-size: 16px
}
}
.compare_strip h3 strong {
	font-weight: 700
}
.compare_strip .back_button {
	color: #fff;
	text-decoration: none;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 14px;
	display: flex;
	align-items: center
}

@media (max-width: 576px) {
.compare_strip .back_button {
	left: 6px;
	top: -5px
}
}
.compare_strip .back_button .bi-arrow-left-short {
	font-size: 29px;
	line-height: 28px;
	float: left
}
.detailCntr {
	padding-right: 20px;
	width: calc(100% - 300px);
	float: left
}

@media (max-width: 1199px) {
.detailCntr {
	width: calc(100% - 270px)
}
}

@media (max-width: 1023px) {
.detailCntr {
	width: 100%;
	padding-right: 0px
}
}
.sort-detail {
	padding: 0 0 15px 0px;
	font-size: 13px;
	color: #202020
}

@media (max-width: 576px) {
.sort-detail {
	font-size: 12px
}
}
.sort-detail .airline_logo {
	width: 26px;
	margin-right: 10px;
	float: left;
	margin-top: 5px
}
.sort-detail strong {
	font-weight: 500
}
.resultDetailBox {
	padding: 10px 10px 0 10px;
	margin-bottom: 15px;
	font-size: 13px;
	border: 1px solid #707070;
	border-radius: 5px;
	background: #E1EAFC;
	position: relative;
	font-family: "Poppins", sans-serif;
	color: #353541
}

@media (max-width: 576px) {
.resultDetailBox {
	font-size: 12px
}
}
.resultDetailBox .airline_logohead {
	text-align: center
}
.resultDetailBox .airline_logohead img {
	width: 20px;
	display: inline-block;
	margin: 0 2px
}
.resultDetailBox .airline_logohead .name {
	display: block;
	font-size: 12px
}
.resultDetailBox .expend_icon {
	position: absolute;
	right: 31px;
	top: 27px;
	font-size: 24px;
	color: #0080FF;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	cursor: pointer
}

@media (max-width: 1023px) {
.resultDetailBox .expend_icon {
	top: 0px
}
}

@media (max-width: 576px) {
.resultDetailBox .expend_icon {
	right: 12px
}
}
.resultDetailBox .legdetail {
	display: flex;
	padding: 7px 0px;
	justify-content: space-between
}
.resultDetailBox .city {
	width: auto
}

@media (max-width: 576px) {
.resultDetailBox .city {
	width: 100px
}
}
.resultDetailBox .city.right-align {
	text-align: right;
	position: relative
}
.resultDetailBox .city.right-align .next_day {
	font-size: 10px;
	color: red;
	position: absolute;
	right: 0;
	top: -10px;
	font-style: italic;
	font-weight: 600
}
.resultDetailBox .time {
	font-size: 18px;
	font-weight: 600;
	line-height: 23px;
	display: block
}

@media (max-width: 576px) {
.resultDetailBox .time {
	font-size: 16px
}
}
.resultDetailBox .code {
	margin-top: 3px;
	font-size: 13px;
	color: #353541;
	display: block;
	line-height: 16px
}

@media (max-width: 576px) {
.resultDetailBox .code {
	font-size: 12px;
	display: inline-block
}
}
.resultDetailBox .connnecting-block {
	width: calc(100% - 240px);
	padding: 0 20px;
	position: relative;
	margin-top: 9px
}

@media (max-width: 576px) {
.resultDetailBox .connnecting-block {
	width: calc(100% - 145px);
	padding: 0 15px
}
}
.resultDetailBox .connnecting-block .leg-seprator {
	border-top: 1px dashed #707070;
	margin: 5px 0px;
	width: 100%;
	display: block;
	position: relative
}
.resultDetailBox .connnecting-block .leg-seprator:before {
	content: '';
	width: 9px;
	height: 9px;
	display: block;
	position: absolute;
	left: 0;
	top: -5px;
	background: #353541;
	border-radius: 100%
}
.resultDetailBox .connnecting-block .leg-seprator:after {
	content: '';
	width: 22px;
	height: 22px;
	display: block;
	position: absolute;
	right: -10px;
	top: -13px;
	/* background: url() no-repeat 0 4px */
}
.resultDetailBox .connnecting-block .stops {
	width: 100%;
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	display: block;
	position: relative
}
.resultDetailBox .connnecting-block .stops:before {
	content: '';
	width: 5px;
	height: 5px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: -8px;
	background: #353541;
	border-radius: 100%
}
.resultDetailBox .flight_detail {
	padding: 5px 5px 5px 10px;
	background: #353541;
	border-radius: 3px;
	font-size: 11px;
	color: #fff;
	text-decoration: none;
	display: block;
	margin: 0 auto -10px auto;
	width: 95px;
	z-index: 1;
	position: relative
}
.resultDetailBox .mobileExpend {
	display: none;
	width: auto;
	margin: 0px -10px
}
.resultDetailBox .expend_detail {
	overflow: hidden;
	position: relative;
	border-radius: 0px 0px 10px 10px;
	padding: 10px 12px 0 12px;
	background: #fff
}
.resultDetailBox .expend_detail .class_strip {
	display: flex;
	align-items: center;
	justify-content: center
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .class_strip {
	font-size: 10px
}
}
.resultDetailBox .expend_detail .class_strip b {
	margin-right: 5px
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .class_strip b {
	margin-right: 3px
}
}
.resultDetailBox .expend_detail .class_strip .inline_tooltip {
	margin-left: 10px;
	display: inline-block;
	position: relative
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .class_strip .inline_tooltip {
	margin-left: 5px
}
}
.resultDetailBox .expend_detail .class_strip .tooltipBox {
	width: 245px;
	z-index: 4
}
.resultDetailBox .expend_detail .class_strip .basiceconomy:hover .tooltipBox {
	display: block
}
.resultDetailBox .expend_detail .airline_code {
	white-space: nowrap;
	font-size: 12px
}
.resultDetailBox .expend_detail .top_strip {
	background: #F4F7FE;
	color: #0080FF;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	margin: 0 -10px
}
.resultDetailBox .expend_detail .layover {
	text-align: center;
	padding-bottom: 10px
}
.resultDetailBox .expend_detail .layover .inner {
	background: #E6ECFF;
	font-size: 13px;
	color: #0080FF;
	font-weight: 500;
	border-radius: 20px;
	padding: 8px 20px;
	min-width: 324px;
	display: inline-block
}
.resultDetailBox .expend_detail .left {
	margin: 20px 15px 35px 110px
}
.resultDetailBox .expend_detail .airline_info {
	border-left: 2px solid #DFDFDF;
	min-height: 75px;
	position: relative;
	margin-left: -5px;
	padding-left: 18px;
	padding-bottom: 20px
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .airline_info {
	padding-bottom: 15px
}
}
.resultDetailBox .expend_detail .airline_info:before {
	content: '';
	width: 20px;
	height: 20px;
	background: #0080FF;
	border-radius: 100%;
	position: absolute;
	left: -11px;
	top: 0px;
	border: 4px solid #ffff
}
.resultDetailBox .expend_detail .airline_info:after {
	content: '';
	width: 17px;
	height: 17px;
	/* background: url() left top no-repeat; */
	border-radius: 100%;
	position: absolute;
	left: -10px;
	bottom: -19px;
	z-index: 1;
	transform: rotate(90deg)
}
.resultDetailBox .expend_detail .right_space {
	position: relative
}
.resultDetailBox .expend_detail .right_space .flight_s {
	position: absolute;
	left: -70px
}
.resultDetailBox .expend_detail .bottom_location {
	padding-left: 14px
}
.resultDetailBox .expend_detail .bottom_location .flight_s {
	position: absolute;
	left: -74px
}
.resultDetailBox .expend_detail .right {
	margin-top: 12px
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .right {
	width: 100%;
	margin-bottom: 12px
}
}
.resultDetailBox .expend_detail .airline-logoname {
	font-size: 13px;
	display: flex;
	justify-content: space-between
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .airline-logoname {
	font-size: 12px;
	align-items: center
}
}
.resultDetailBox .expend_detail .airline-logoname .airline_logo {
	width: 26px;
	margin-right: 10px;
	float: left
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .airline-logoname .airline_logo {
	margin-top: 5px
}
}
.resultDetailBox .expend_detail .airline-logoname .name {
	font-weight: 500;
	font-size: 14px
}

@media (max-width: 576px) {
.resultDetailBox .expend_detail .airline-logoname .name {
	display: inline-block;
	margin-top: 8px;
	font-size: 13px
}
}
.explore_other {
	padding-top: 20px
}
.explore_other h3 {
	font-size: 22px;
	font-weight: 300;
	padding-bottom: 10px
}

@media (max-width: 576px) {
.explore_other h3 {
	font-size: 16px;
	padding-bottom: 5px
}
}
.explore_other h3 strong {
	font-weight: 700
}
.explore_other ul {
	padding: 0px;
	list-style: none
}
.explore_other ul li {
	padding: 15px;
	margin-bottom: 15px;
	border: 1px solid #E6E6E6;
	border-radius: 5px;
	background: #F5F5F5;
	position: relative;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	font-weight: 600;
	color: #353541;
	display: flex;
	align-items: center;
	justify-content: space-between
}

@media (max-width: 576px) {
.explore_other ul li {
	font-size: 13px
}
}

@media (max-width: 576px) {
.explore_other ul li img {
	width: 80px
}
}
.explore_other ul .price {
	font-size: 21px;
	font-weight: 700;
	margin-right: 10px
}

@media (max-width: 1023px) {
.explore_other ul .price {
	font-size: 18px
}
}

@media (max-width: 576px) {
.explore_other ul .price {
	font-size: 16px
}
}
.explore_other ul .selectBtn {
	width: 140px;
	display: inline-block;
	padding: 7px 5px;
	text-align: center;
	font-size: 15px;
	background: #0080FF;
	color: #fff;
	border: 2px solid #0080FF;
	cursor: pointer;
	border-radius: 5px;
	font-weight: 600;
	text-decoration: none
}

@media (max-width: 1023px) {
.explore_other ul .selectBtn {
	width: 104px;
	font-size: 14px;
	padding: 5px
}
}

@media (max-width: 576px) {
.explore_other ul .selectBtn {
	width: 80px
}
}
.explore_other ul .selectBtn.selected, .explore_other ul .selectBtn:hover {
	color: #0080FF;
	background: #fff
}
.flight_detail_popup {
	max-width: 1012px;
	margin: 0px auto;
	background: #fff;
	border-radius: 10px
}

@media (max-width: 1023px) {
.flight_detail_popup {
	width: 95%
}
}

@media (max-width: 576px) {
.flight_detail_popup {
	width: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	border-radius: 0px
}
}
.flight_detail_popup .head {
	position: relative;
	font-size: 20px;
	padding: 15px;
	background: #0080FF;
	border-radius: 10px 10px 0 0;
	color: #fff;
	font-weight: 700
}

@media (max-width: 576px) {
.flight_detail_popup .head {
	font-size: 16px;
	border-radius: 0px
}
}
.flight_detail_popup .innerblock {
	padding: 10px
}

@media (max-width: 576px) {
.flight_detail_popup .innerblock {
	padding: 10px 10px 60px 10px;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	background: #fff
}
}
.flight_detail_popup .title_head {
	color: #353541;
	display: flex;
	font-size: 13px;
	justify-content: space-between
}
.flight_detail_popup .title_head h3 {
	font-size: 15px
}

@media (max-width: 576px) {
.flight_detail_popup .title_head h3 {
	font-size: 13px;
	padding-bottom: 5px
}
}

@media (max-width: 479px) {
.flight_detail_popup .title_head h3 {
	padding-bottom: 0px;
	margin: 0px
}
}
.flight_detail_popup .title_head h3 small {
	font-size: 13px;
	font-weight: 400
}
.flight_detail_popup .title_head h3 .depart-icon {
	margin-right: 5px;
	width: 18px;
	transform: rotate(-50deg)
}
.flight_detail_popup .title_head h3 .return-icon {
	margin-right: 5px;
	width: 18px;
	transform: rotate(-130deg)
}
.flight_detail_popup .title_head strong {
	font-size: 15px;
	font-weight: 600
}
.flight_detail_popup .title_head .title {
	display: flex
}

@media (max-width: 479px) {
.flight_detail_popup .title_head .title {
	flex-direction: column
}
}
.flight_detail_popup .title_head .totalTrip {
	margin-left: 10px
}

@media (max-width: 479px) {
.flight_detail_popup .title_head .totalTrip {
	margin-left: 23px
}
}
.popup_Box.flightDetailpopup .close__popup {
	width: 30px;
	text-align: center;
	display: inline-block;
	height: 30px;
	position: absolute;
	right: 10px;
	top: 10px;
	color: #fff;
	font-size: 27px
}
.popup_Box.flightDetailpopup.popup_open {
	right: 0px
}

@media (max-width: 576px) {
.popup_Box.flightDetailpopup.popup_open {
	transition: all 0.5s
}
.popup_Box.flightDetailpopup.popup_open .flight_detail_popup {
	display: block
}
}
.popup_Box.flightDetailpopup .boxholder {
	margin-right: -10px
}

@media (max-width: 1023px) {
.popup_Box.flightDetailpopup .boxholder {
	margin-right: 0px
}
}
.popup_Box.flightDetailpopup .boxholder .itenary_column {
	width: calc(50% - 10px);
	float: left;
	margin-right: 10px
}
.popup_Box.flightDetailpopup .boxholder .itenary_column.itenary_column-full {
	width: calc(100% - 10px)
}

@media (max-width: 1023px) {
.popup_Box.flightDetailpopup .boxholder .itenary_column {
	width: 100%;
	margin-right: 0px;
	margin-bottom: 7px
}
}
.text-green {
	color: #3AB54A
}
.tooltipBox {
	display: none;
	width: 440px;
	position: absolute;
	top: 37px;
	left: 60px;
	background: #000000;
	padding: 10px 12px;
	font-size: 12px;
	color: #F3F3F3;
	border-radius: 5px;
	z-index: 1
}

@media (max-width: 576px) {
.tooltipBox.nearby_airport {
	left: -114px
}
}
.tooltipBox.nearby_airport:before, .tooltipBox.nearby_airport:after {
	content: ''
}

@media (max-width: 576px) {
.tooltipBox.nearby_airport:before, .tooltipBox.nearby_airport:after {
	left: 198px
}
}

@media (max-width: 576px) {
.tooltipBox {
	width: 222px;
	left: 0px;
	font-size: 10px
}
}

@media (max-width: 359px) {
.tooltipBox {
	width: 150px
}
}
.tooltipBox:after, .tooltipBox:before {
	content: "";
	position: absolute;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	top: -10px;
	left: 40px;
	border-bottom: 10px solid #000000
}

@media (max-width: 576px) {
.tooltipBox:after, .tooltipBox:before {
	left: 84px
}
}
.tooltipBox:after {
	border-bottom: 11px solid #000000;
	z-index: 1
}
.tooltipBox p {
	margin: 5px 0px;
	line-height: 18px
}

@media (max-width: 576px) {
.tooltipBox p {
	line-height: 16px
}
}
.tooltipBox .close_tooltip {
	font-size: 23px;
	position: absolute;
	right: 5px;
	top: 0px;
	color: #333;
	cursor: pointer;
	display: none
}
.tooltipBox.aircraft_tooltip {
	top: 26px;
	left: -186px
}

@media (max-width: 479px) {
.tooltipBox.aircraft_tooltip {
	left: -60px !important
}
}
.tooltipBox.tooltip_right {
	left: inherit !important;
	right: -13px;
	top: 23px
}
.tooltipBox.tooltip_right:before, .tooltipBox.tooltip_right:after {
	left: inherit;
	right: 7px
}
.direct_flight {
	position: relative;
	font-size: 12px;
	margin-top: 25px
}
.direct_flight .text-blue {
	font-weight: 600
}
.direct_flight:hover .tooltipBox {
	display: block
}
.stopTooltip-custom .tooltipBox {
	width: max-content;
	left: 50%;
	top: 32px;
	text-align: left;
	transform: translateX(-50%)
}

@media (max-width: 576px) {
.stopTooltip-custom .tooltipBox {
	width: 200px;
	font-size: 10px;
	padding: 6px;
	z-index: 2
}
}
.stopTooltip-custom .tooltipBox:after, .stopTooltip-custom .tooltipBox:before {
	left: 50%;
	transform: translateX(-50%)
}
.stopTooltip-custom strong {
	color: #f3f3f3
}
.stop_tooltip_hover:hover .tooltipBox {
	display: block
}
.fare_family_main {
	display: inline-block
}
.fare_family_main .fare_familytooltip {
	font-weight: 400;
	left: inherit;
	right: 0px;
	top: 25px;
	font-size: 10px;
	width: 211px;
	white-space: normal
}

@media (max-width: 479px) {
.fare_family_main .fare_familytooltip {
	left: inherit;
	right: -9px
}
}
.fare_family_main .fare_familytooltip:before, .fare_family_main .fare_familytooltip:after {
	right: 13px;
	left: inherit
}
.fare_family_main .fare_familytooltip ul {
	padding: 0 13px;
	list-style: auto
}
.fare_family_main:hover .fare_familytooltip {
	display: block
}
.tooltipcity {
	position: absolute;
	opacity: 0;
	display: none;
	top: 20px;
	left: 50%;
	background: #000000;
	padding: 6px 13px;
	font-size: 11px;
	color: #F3F3F3;
	border-radius: 5px;
	z-index: 1;
	transform: translateX(-50%);
	white-space: nowrap;
	border: 1px solid #000000
}
.tooltipcity:after, .tooltipcity:before {
	content: "";
	position: absolute;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	top: -6px;
	left: 50%;
	border-bottom: 5px solid #000000;
	transform: translateX(-50%)
}
.tooltipcity:after {
	border-bottom: 5px solid #000000;
	z-index: 1
}
.popup_Box {
	background-color: rgba(0,0,0,0.75);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	overflow-y: auto
}
.popup_Box .center-block {
	width: 100%;
	height: 100%;
	z-index: 1001;
	display: table;
	table-layout: fixed
}
.popup_Box .outer {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	padding: 40px 0
}
.popup_Box .close__popup {
	width: 38px;
	text-align: center;
	display: inline-block;
	height: 27px;
	position: absolute;
	right: 10px;
	top: 14px;
	color: #000000
}
.session__expire {
	width: 600px;
	border-radius: 8px;
	padding: 40px 50px;
	text-align: center;
	background: #fff;
	margin: 0px auto
}

@media (max-width: 576px) {
.session__expire {
	width: 460px;
	padding: 40px 27px
}
}

@media (max-width: 479px) {
.session__expire {
	width: 95%
}
}

@media (max-width: 359px) {
.session__expire {
	width: 95%;
	padding: 30px 10px
}
}
.session__expire img {
	margin-bottom: 30px
}

@media (max-width: 479px) {
.session__expire img {
	width: 121px
}
}
.session__expire .heading {
	color: #1F1F1F;
	margin-bottom: 15px;
	font-weight: 700
}

@media (max-width: 479px) {
.session__expire .heading {
	font-size: 14px
}
}
.session__expire p {
	color: #5B5B5B;
	margin-bottom: 35px
}

@media (max-width: 479px) {
.session__expire p {
	font-size: 12px
}
}
.session__expire button {
	background: #CED0D4;
	color: #333;
	font-size: 15px;
	width: 167px;
	text-align: center;
	padding: 12px 5px;
	border: 0;
	border-radius: 3px;
	font-weight: 600
}

@media (max-width: 479px) {
.session__expire button {
	font-size: 13px;
	width: 130px
}
}
.session__expire button.active {
	background: #355CEC;
	color: #fff;
	margin-right: 50px
}

@media (max-width: 479px) {
.session__expire button.active {
	margin-right: 20px
}
}

@media (max-width: 359px) {
.session__expire button.active {
	margin-right: 10px
}
}
.more_results {
	margin-bottom: 20px
}
.no__resultBox {
	border-radius: 8px;
	padding: 20px 50px;
	text-align: center;
	background: #fff;
	box-shadow: 0 4px 3px #00000005
}
.no__resultBox img {
	margin-bottom: 15px
}
.no__resultBox .heading {
	font-size: 16px;
	font-weight: 700;
	color: #1F1F1F;
	margin-bottom: 2px
}

@media (max-width: 479px) {
.no__resultBox .heading {
	font-size: 14px
}
}
.no__resultBox p {
	color: #5B5B5B;
	margin-bottom: 10px
}

@media (max-width: 479px) {
.no__resultBox p {
	font-size: 12px
}
}
.no__resultBox a.reset {
	color: #355CEC;
	text-decoration: underline
}
.no__resultBox .call-txt {
	padding: 18px 0 2px 0
}
.no__resultBox .call-txt b {
	color: #1F1F1F;
	display: block;
	margin-bottom: 10px
}

@media (max-width: 479px) {
.no__resultBox .call-txt b {
	font-size: 14px
}
}
.no__resultBox .call-txt a {
	background: #FFE44F;
	color: #222940;
	font-size: 17px;
	padding: 9px 10px;
	border-radius: 30px;
	font-weight: bold;
	display: inline-block;
	width: 202px;
	text-decoration: none
}

@media (max-width: 479px) {
.no__resultBox .call-txt a {
	font-size: 14px
}
}
.no__resultBox .call-txt a:hover {
	text-decoration: none
}
.no__resultBox .call-txt a img {
	margin: 0;
	vertical-align: middle
}
.no__resultBanner-container {
	margin: 30px 0
}
.no__resultBanner {
	padding: 25px;
	text-align: right;
	/* background: url() no-repeat left center #fff; */
	box-shadow: 0px 0px 6px #00000029;
	margin: auto;
	width: 504px;
	font-size: 16px
}

@media (max-width: 576px) {
.no__resultBanner {
	width: 95%;
	background: #fff;
	border-radius: 5px;
	text-align: center;
	padding: 21px
}
}
.col_desktop {
	display: block
}

@media (max-width: 576px) {
.col_desktop {
	display: none
}
}
.col_mobile {
	display: none
}

@media (max-width: 576px) {
.col_mobile {
	display: block
}
}
.no__resultBanner .heading {
	color: #1E44D2;
	margin-bottom: 10px;
	font-weight: 700
}

@media (max-width: 576px) {
.no__resultBanner .heading {
	font-size: 16px
}
}

@media (max-width: 479px) {
.no__resultBanner .heading {
	font-size: 14px
}
}
.no__resultBanner p {
	color: #333333;
	margin-bottom: 30px
}

@media (max-width: 576px) {
.no__resultBanner p {
	font-size: 14px
}
}

@media (max-width: 479px) {
.no__resultBanner p {
	font-size: 12px
}
}
.center-call-txt {
	width: 290px;
	display: inline-table;
	text-align: center
}

@media (max-width: 576px) {
.center-call-txt {
	width: auto;
	display: block
}
}
.no__resultBanner .call-txt {
	padding: 18px 0 2px 0
}

@media (max-width: 576px) {
.no__resultBanner .call-txt {
	padding: 0px
}
}
.no__resultBanner .call-txt b {
	color: #1F1F1F;
	display: block;
	margin-bottom: 15px;
	font-weight: normal
}
.no__resultBanner .call-txt a {
	background: #FFE44F;
	color: #222940;
	font-size: 24px;
	text-decoration: none;
	padding: 10px;
	border-radius: 30px;
	font-weight: bold;
	display: inline-block;
	width: 100%
}

@media (max-width: 576px) {
.no__resultBanner .call-txt a {
	font-size: 20px;
	padding: 9px 20px;
	white-space: nowrap
}
}

@media (max-width: 479px) {
.no__resultBanner .call-txt a {
	font-size: 18px;
	padding: 8px 25px
}
}

@media (max-width: 359px) {
.no__resultBanner .call-txt a {
	font-size: 16px;
	padding: 8px 20px
}
}
.no__resultBanner .call-txt a:hover {
	text-decoration: none
}
.no__resultBanner .call-txt a img {
	margin: 0;
	vertical-align: middle;
	width: 30px
}

@media (max-width: 479px) {
.no__resultBanner .call-txt a img {
	width: 23px
}
}
.no__resultBanner .offer-txt {
	color: #355CEC;
	font-size: 16px;
	padding: 30px 0 20px
}

@media (max-width: 479px) {
.no__resultBanner .offer-txt {
	font-size: 14px
}
}
.no__resultBanner .offer-txt .discount {
	color: #000
}

@media (max-width: 576px) {
.no__resultBanner .offer-txt .discount {
	font-size: 25px
}
}

@media (max-width: 479px) {
.no__resultBanner .offer-txt .discount {
	font-size: 20px
}
}
.no__resultBanner .offer-txt .discount span {
	color: #355CEC
}
.loading_block {
	overflow: hidden;
	max-width: 600px;
	margin: 0px auto;
	background: #fff;
	box-shadow: 0px 0px 6px #00000029;
	padding: 60px 0;
	text-align: center
}
.loading_block p {
	padding-top: 40px;
	font-size: 14px;
	font-weight: 600;
	color: #355CEC
}
.loading_block .loading_flight {
	height: 129px;
	position: relative
}
.loading_block .animation_div {
	position: absolute;
	left: -60px;
	top: 0px;
	animation-name: example;
	animation-duration: 8s;
	animation-iteration-count: infinite
}
.loading_block .loading_image {
	position: absolute;
	left: -75px;
	top: 0px
}
.loading_block .loading_back {
	width: 185px;
	height: 99px;
	position: absolute;
	left: -200px;
	top: 15px;
	background: #fff;
	background: -moz-linear-gradient(left, #fff 0%, #d9e7ff 100%);
	background: -webkit-linear-gradient(left, #fff 0%, #d9e7ff 100%);
	background: linear-gradient(to right, #fff 0%, #d9e7ff 100%)
}
@keyframes example {
0% {
left:-70px
}
100% {
left:800px
}
}
.compare_popup {
	width: 500px;
	background: #fff;
	margin: 0px auto;
	border-radius: 5px;
	overflow: hidden
}

@media (max-width: 576px) {
.compare_popup {
	width: 460px
}
}

@media (max-width: 479px) {
.compare_popup {
	width: 95%
}
}
.compare_popup .searchComparehome {
	box-shadow: none
}
.compare_popup .checkmark {
	position: absolute;
	left: -28px
}
.compare_popup label {
	margin-left: 33px
}
.compare_popup .head {
	padding: 20px;
	background: #0080FF;
	font-size: 25px;
	color: #fff;
	font-weight: 500;
	text-align: center;
	border-radius: 5px 5px 0px 0px;
	position: relative
}

@media (max-width: 576px) {
.compare_popup .head {
	font-size: 18px
}
}
.compare_popup .close__popup {
	font-size: 38px;
	color: #fff;
	height: 42px;
	top: -4px;
	right: 4px
}
.compare_popup .thanks {
	text-align: center;
	margin-bottom: -16px;
	margin-top: 11px
}
.compare_popup .thanks a {
	font-size: 17px;
	color: #6E6E6E;
	font-weight: 600;
	text-decoration: none
}
.minimix_popup {
	display: none;
	background: #353541;
	padding: 0px 10px 0px 0;
	box-shadow: 0 0 6px #00000029;
	font-size: 12px;
	color: #fff;
	position: fixed;
	left: 0px;
	bottom: 0px;
	width: 100%;
	align-items: center;
	justify-content: space-between
}

@media (max-width: 576px) {
.minimix_popup {
	display: flex
}
}
.minimix_popup p {
	margin: 0px
}
.minimix_popup .sponsor {
	display: block;
	opacity: 0.5
}
.minimix_popup .close__popup {
	font-size: 36px;
	padding: 10px;
	color: #fff
}
.minimix_popup .compareBtn {
	margin: 10px 0;
	width: 140px;
	display: inline-block;
	padding: 7px 5px;
	text-align: center;
	font-size: 15px;
	background: #0080FF;
	color: #fff;
	border: 2px solid #0080FF;
	cursor: pointer;
	border-radius: 5px;
	font-weight: 600
}
.contentCntr {
	padding-top: 30px
}

@media (max-width: 576px) {
.contentCntr {
	padding-top: 15px
}
}
.leftCntr {
	width: 250px;
	float: left
}

@media (max-width: 1199px) {
.leftCntr.show-component-mobile {
	position: fixed;
	left: -440px;
	top: 0;
	width: 300px;
	transition: all .5s;
	background: #fff;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 5
}
}

@media (max-width: 576px) {
.leftCntr.show-component-mobile {
	width: 100%;
	left: -200%
}
}
.leftCntr.show-component-mobile.open {
	left: 0;
	height: 100%;
	padding: 0;
	box-shadow: 0px 0px 8px #0000005c
}
.resultsCntr {
	width: calc(100% - 550px);
	float: left;
	padding: 0 20px
}

@media (max-width: 1299px) {
.resultsCntr {
	padding: 0 15px;
	width: calc(100% - 520px)
}
}

@media (max-width: 1199px) {
.resultsCntr {
	width: 100%;
	padding: 0px
}
}
.rightCntr {
	width: 300px;
	float: left
}

@media (max-width: 1299px) {
.rightCntr {
	width: 270px
}
}

@media (max-width: 1023px) {
.rightCntr {
	display: none
}
}
.msgBox {
	background: #3AB54A;
	border-radius: 20px;
	display: inline-block;
	position: relative;
	font-size: 12px;
	color: #fff;
	font-weight: 500;
	padding: 5px 12px
}
.msgBox .bi-info-circl {
	margin-left: 2px;
	cursor: pointer
}
.msgBox:hover .tooltipBox {
	display: block
}
.recentviewed {
	background: #CCDEF1;
	border-radius: 20px;
	display: inline-block;
	position: relative;
	font-size: 12px;
	color: #0C5DBA;
	font-weight: 500;
	padding: 5px 12px
}
.recentviewed .bi-info-circl {
	margin-left: 2px;
	cursor: pointer
}
.support-bottom-strip {
	height: 60px;
	display: none
}

@media (max-width: 576px) {
.support-bottom-strip {
	display: block
}
}
.support-bottom-strip .tele {
	font-size: 25px;
	font-weight: 700;
	line-height: 27px
}
.support-bottom-strip a {
	color: #fff;
	text-decoration: none;
	display: block;
	text-align: center;
	position: fixed;
	padding: 10px;
	bottom: 0;
	z-index: 1;
	width: 100%;
	background: #3AB54A;
	transition: all .5s ease 0s;
	font-size: 11px;
	color: #fff;
	font-family: "Montserrat", sans-serif
}
.support-bottom-strip .tele_block {
	position: relative;
	padding-left: 50px;
	display: inline-block
}
.support-bottom-strip img {
	position: absolute;
	left: 0px;
	top: 0px
}
.add_banner img {
	margin-bottom: 15px;
	max-width: 100%
}

@media (max-width: 576px) {
.add_banner img {
	width: 100%
}
}
.partnerBox {
	box-shadow: 0 4px 3px #00000005;
	border-radius: 5px;
	padding: 15px;
	background: #fff;
	position: relative;
	overflow: hidden;
	font-family: "Poppins", sans-serif;
	color: #1F1F1F
}

@media (max-width: 576px) {
.partnerBox {
	margin-bottom: 30px
}
}
.partnerBox h4 {
	font-size: 14px;
	color: #353541;
	font-weight: 600
}
.partnerBox p {
	font-size: 13px;
	color: #353541;
	font-weight: 400
}
.partnerBox .items {
	padding: 0 10px 0px 0
}
.partnerBox .label {
	width: 100%;
	padding: 10px 15px;
	float: left;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center
}
.partnerBox .label img {
	height: 35px;
	margin-right: 10px
}

@media (max-width: 576px) {
.partnerBox .label img {
	height: 25px
}
}

@media (max-width: 1199px) {
.partnerBox .slick-list.draggable {
	padding: 0 100px 0 0 !important
}
}
#go-top {
	position: fixed;
	right: 44px;
	bottom: 36px;
	width: 40px;
	text-align: center;
	z-index: 9;
	display: none;
	cursor: pointer;
	padding: 4px;
	background-color: #355CEC;
	color: #fff;
	font-size: 21px;
	height: 40px
}
.open-model, .open-model2 {
	overflow: hidden
}
.open-modeliphone.open-model {
	overflow: hidden
}

@media (max-width: 576px) {
.open-modeliphone.open-model {
	overflow: hidden;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%
}
}
.comrs_holder {
	width: 100%;
	height: calc(100vh - 245px);
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	position: relative;
	overflow-y: hidden;
	overflow-x: auto
}

@media (max-width: 1023px) {
.comrs_holder {
	height: 85vh
}
}
.comrs_holder .comsiteBlock {
	width: 100%;
	min-width: 400px;
	padding: 0 10px
}

@media (max-width: 576px) {
.comrs_holder .comsiteBlock {
	min-width: 345px;
	padding: 0 3px
}
}
.comrs_holder .comsiteBlock .block_head {
	background: #0080FF;
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	padding: 5px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between
}

@media (max-width: 576px) {
.comrs_holder .comsiteBlock .block_head {
	font-size: 11px;
	padding: 5px
}
}
.comrs_holder .comsiteBlock .block_head img {
	margin: 2px 6px;
	cursor: pointer;
	float: left
}
.comrs_holder .comsiteBlock .block_head a {
	float: left
}
.comrs_holder .partner_frame {
	background: #fff;
	width: 100%;
	height: calc(100% - 32px)
}

@media (max-width: 1023px) {
.comrs_holder .partner_frame {
	height: 85%
}
}
.comsiteBlock.fullwindow {
	display: block !important;
	width: 100%;
	height: 100%
}
.backmain_results {
	font-family: "Poppins", sans-serif;
	background: #0FF;
	border: none;
	font-size: 14px;
	color: #040504;
	text-align: center;
	border-radius: 5px;
	padding: 8px 10px;
	font-weight: 500;
	outline: none;
	cursor: pointer;
	margin-bottom: 15px;
	display: flex;
	text-decoration: none;
	align-items: center;
	width: 200px
}
.backmain_results .bi-arrow-left-short {
	font-size: 29px;
	line-height: 28px;
	float: left
}
.nopartners {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center
}
.nopartners .nopartners_wrapper2 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #fff;
	padding: 40px;
	border-radius: 5px
}
.nopartners .plus_icon {
	font-size: 50px;
	color: #0bc50b
}
.nopartners .nopartners_headline {
	font-size: 36px;
	font-weight: 700;
	color: #333
}
.nopartners .nopartners_subheadline {
	font-size: 24px;
	margin-top: 5px;
	color: #333
}
.nopartners .nopartners_button {
	margin-top: 20px;
	height: 60px;
	padding: 0 20px;
	font-size: 24px;
	font-weight: 700;
	background-color: #0080FF;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 5px
}
.mobile_header {
	background: #fff;
	padding: 8px 9px 0px 10px;
	font-weight: 600;
	margin-bottom: -5px;
	z-index: 2;
	position: relative
}
.desktopshow {
	display: block
}

@media (max-width: 576px) {
.desktopshow {
	display: none
}
}
.mobileshow {
	display: none
}

@media (max-width: 576px) {
.mobileshow {
	display: block
}
}
.dot {
	color: #0FF;
	width: 100px;
	height: 35px;
	display: inline-block;
--d:radial-gradient(farthest-side, currentColor 90%, #0000);
	background: var(--d), var(--d), var(--d);
	background-size: 22px 22px;
	background-repeat: no-repeat;
	animation: m 1s infinite alternate
}
@keyframes m {
0% {
background-position:calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
}
20% {
background-position:calc(0*100%/2) 0, calc(1*100%/2) 50%, calc(2*100%/2) 50%
}
40% {
background-position:calc(0*100%/2) 100%, calc(1*100%/2) 0, calc(2*100%/2) 50%
}
60% {
background-position:calc(0*100%/2) 50%, calc(1*100%/2) 100%, calc(2*100%/2) 0
}
80% {
background-position:calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 100%
}
100% {
background-position:calc(0*100%/2) 50%, calc(1*100%/2) 50%, calc(2*100%/2) 50%
}
}
.mobloadingDiv {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	background: #fff;
	height: 100%;
	border-radius: 5px;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column
}
.loadingtext {
	padding-top: 15px;
	font-size: 18px;
	color: #1F1F1F;
	font-weight: 500
}
.midpage {
	background: #f5f5f5;
	height: calc(100vh - 80px);
	display: flex;
	align-items: center
}
.midloadingDiv {
	background: #fff;
	width: 400px;
	border-radius: 5px;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 300px;
	margin: 0px auto;
	padding: 30px;
	text-align: center
}
.mid_container .show-component-mobile {
	background: #fff;
	padding: 10px
}
.mid_container .resultsBox {
	padding: 10px
}
.loader-box {
	position: relative;
	overflow: hidden
}
.loader-box:before {
	position: absolute;
	top: 0;
	left: -50%;
	z-index: 2;
	display: block;
	content: '';
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
	-webkit-animation: shine .75s infinite;
	animation: shine .75s infinite
}
.loader-box .img_responsive {
	width: 100%
}
.loader {
	margin: 0px;
	height: 4px;
	width: 100%;
	position: relative;
	overflow: hidden
}
.loader .loading_increse {
	position: absolute;
	height: 4px;
	background-color: #4F8FFC;
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem
}
.circle-loader {
	margin-bottom: 3.5em;
	border: 4px solid #EEEEEE;
	border-left-color: #4F8FFC;
	border-top-color: #4F8FFC;
	animation: loader-spin 1.2s infinite linear;
	position: relative;
	display: block;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	margin: 0px auto
}
.loader_icon {
	/* background: url() center center no-repeat; */
	width: 100px;
	height: 100px;
	margin: 0px auto 10px auto
}
.loading_block_content {
	font-size: 15px;
	color: #0080FF;
	text-align: center;
	padding-bottom: 30px;
	line-height: 25px
}
.loading_block_content .searching {
	padding-bottom: 10px
}
@keyframes loader-spin {
0% {
transform:rotate(0deg)
}
100% {
transform:rotate(360deg)
}
}
@-webkit-keyframes shine {
100% {
left:125%
}
}
@keyframes shine {
100% {
left:125%
}
}
.f_progrss_modal:after {
	content: "";
	display: block;
	position: absolute;
	top: 60px;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10;
	background: transparent
}
.ngProgressLiteBar {
	position: absolute;
	top: 62px;
	height: 4px;
	background-color: #4F8FFC;
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem
}

@media (max-width: 1199px) {
.ngProgressLiteBar {
	top: 178px
}
}

@media (max-width: 1023px) {
.ngProgressLiteBar {
	top: 110px
}
}

@media (max-width: 576px) {
.ngProgressLiteBar {
	top: 108px;
	background-color: #4F8FFC;
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)
}
}
.session_expBlock {
	text-align: center
}
.session__expire {
	text-align: center;
	background: #fff;
	padding: 20px;
	border-radius: 5px;
	margin: 0px auto;
	border-top: 1px solid #ccc
}
.session__expire .heading {
	font-size: 20px;
	margin: 10px 0px;
	color: #505050;
	font-weight: 700;
	display: block
}
.session__expire .icons {
	font-size: 50px;
	color: #0b60f4
}
.session__expire p.fs_18 {
	font-size: 16px
}
.session__expire .button {
	padding: 7px 20px;
	font-size: 16px;
	font-weight: 700;
	background-color: #0080FF;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 5px;
	display: inline-block
}
.session__expire img {
	max-width: 400px
}
.content_page_title2 {
	background-color: #0080FF;
	padding: 30px 15px;
	text-align: center;
	height: 135px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff
}

@media (max-width: 576px) {
.content_page_title2 {
	height: 80px
}
}
.content_page_title2 h1 {
	font-size: 40px;
	font-weight: 600;
	line-height: 44px;
	margin: 0px
}

@media (max-width: 576px) {
.content_page_title2 h1 {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center
}
}
.content_page_title2 h1 img {
	margin-right: 8px
}

@media (max-width: 576px) {
.content_page_title2 h1 {
	font-size: 21px;
	white-space: nowrap
}
}
.content_page_title2 h1:before {
	margin-right: 10px;
	display: none;
	width: 50px;
	height: 1px;
	content: "";
	vertical-align: middle;
	background: rgba(255,255,255,0.3)
}
.content_page_title2 h1:after {
	margin-left: 10px;
	display: none;
	width: 50px;
	height: 1px;
	content: "";
	vertical-align: middle;
	background: rgba(255,255,255,0.3)
}
.box-text2 h3 {
	color: #333;
	font-size: 20px;
	font-weight: 600
}

@media (max-width: 576px) {
.box-text2 h3 {
	font-size: 18px
}
}
.box-text2 .content-wrap {
	display: block;
	color: #5c6272
}
.box-text2 ul.list {
	list-style: none
}
.box-text2 ul.list li {
	position: relative
}
.box-text2 ul.list li:before {
	content: '';
	width: 6px;
	height: 6px;
	background: #ccc;
	position: absolute;
	left: -16px;
	top: 12px;
	border-radius: 100%
}
.box-text2 .left_image {
	float: left;
	padding: 13px 23px 20px 0
}
.box-text2 .right_image {
	float: right;
	padding: 13px 0px 20px 23px
}
.contactBox {
	padding-top: 30px
}
.contactBox h3 {
	padding-bottom: 10px
}
.contactBox .rightBlock {
	padding-left: 60px
}

@media (max-width: 1023px) {
.contactBox .rightBlock {
	padding-left: 0px
}
}
.contactBox .holder {
	display: flex;
	justify-content: space-between;
	padding: 50px
}

@media (max-width: 576px) {
.contactBox .holder {
	flex-direction: column;
	padding: 40px 0
}
}
.contactBox .block {
	padding: 15px;
	width: 100%;
	margin-bottom: 4px;
	text-align: center;
	background: #f7f7f7;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column
}
.contactBox .block .icons {
	font-size: 35px;
	width: 70px;
	height: 70px;
	border: 1px solid #ffd230;
	text-align: center;
	line-height: 70px;
	margin: 0 0 10px 0
}
.contactBox .block h4 {
	font-size: 20px
}
.contactBox .block p {
	margin-bottom: 0px
}
.contactBox .block a {
	color: #5c6272;
	font-size: 18px;
	font-weight: 600;
	text-decoration: none
}
.contactBox .block a.email {
	font-size: 18px
}

@media (max-width: 1023px) {
.contactBox .block a.email {
	font-size: 15px
}
}
.contactForm .form-label {
	font-weight: 600
}
.contactForm .form-control {
	padding: 10px 12px;
	border-radius: 0px
}
.contactForm .submitBtn {
	background-color: #ffd230;
	border-color: #ffd230;
	border-radius: 0px;
	padding: 10px 32px;
	color: #333;
	font-weight: 600;
	border: none
}
.contactForm .capcha-txt {
	color: #fff;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
	padding: 3px 20px 3px 12px;
	background: #3a3a3a;
	display: flex;
	align-items: center;
	justify-content: space-evenly
}
.F-progrss-modal:after {
	content: "";
	display: none;
	position: absolute;
	top: 0px;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10;
	height: 62px;
	background: transparent
}

@media (max-width: 576px) {
.F-progrss-modal:after {
	display: none
}
}
.sitemap-wrap {
	display: block;
	color: #5c6272;
	margin-bottom: 30px
}
.site_map_links {
	list-style: none;
	padding: 0px;
	margin: 0;
	overflow: hidden
}
.site_map_links li {
	width: 25%;
	float: left;
	line-height: 33px;
	padding-left: 15px
}

@media (max-width: 1023px) {
.site_map_links li {
	width: 33.3%
}
}

@media (max-width: 576px) {
.site_map_links li {
	width: 50%
}
}
.site_map_links li a {
	color: #333;
	position: relative;
	text-decoration: none;
	font-size: 14px
}

@media (max-width: 576px) {
.site_map_links li a {
	font-size: 13px
}
}
.site_map_links li a:before {
	content: '';
	width: 6px;
	height: 6px;
	background: #0080FF;
	position: absolute;
	left: -13px;
	top: 7px;
	border-radius: 100%
}
.float-end {
	float: right
}
.row_margin {
	clear: both
}
.d-sm-none {
	display: none
}

@media (max-width: 576px) {
.d-sm-none {
	display: block
}
}
.d-sm-inline-block {
	display: inline-block
}

@media (max-width: 576px) {
.d-sm-inline-block {
	display: none
}
}
.container-fluid .col-lg-6 {
	width: 50%;
	float: left
}

@media (max-width: 576px) {
.container-fluid .col-lg-6 {
	width: 100%
}
}
/* .mobile_search_popup .row {
	display: flex
} */
.partner_frame iframe {
	border: none
}

.animation_block {
	position: relative;
	border: 1px solid red;
	width: 370px;
	height: 182px;
	overflow: hidden;
	margin: 20px auto
}
.animation_block .slide {
	width: 100%;
	animation-name: example;
	animation-fill-mode: forwards;
	animation: example 2s infinite;
	animation-delay: 2s;
	position: absolute;
	left: -200px;
	top: 0px
}
@keyframes example {
from {
left:-200px
}
to {
left:100%
}
}
.tabcontent {
	display: none
}
.deal_content_block .resultsBox {
	border: 1px solid #DEDEDE;
	box-shadow: none
}
.deal_content_block .resultsBox .connnecting-block {
	padding: 17px 18px 24px 0px
}
.deal_content_block .resultsBox .price-section {
	padding: 12px 15px 6px 0
}
.deal_content_block .resultsBox .border-right {
	display: flex;
	align-items: center;
	justify-content: center
}
.deal_content_block .resultsBox .block_left {
	width: 100%
}

@media (max-width: 1199px) {
.deal_content_block .resultsBox .selectBtn {
	width: 95px
}
}

@media (max-width: 1023px) {
.deal_content_block .resultsBox .price-section {
	padding: 7px 10px;
	border-top: 1px solid #E5EBF0
}
.deal_content_block .resultsBox .left_mob {
	float: left;
	padding-top: 5px
}
.deal_content_block .resultsBox .price {
	font-size: 21px
}
.deal_content_block .resultsBox .border-right {
	border: none
}
}
.page_title2:before {
	display: none
}
ul.tabs {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid #ccc;
	padding-bottom: 8px;
	max-width: 766px;
	margin: 0px auto 30px
}

@media (max-width: 576px) {
ul.tabs {
	border: none;
	padding: 0px
}
}
ul.tabs li {
	padding: 0px 15px
}
ul.tabs li a {
	font-size: 16px;
	color: #353541;
	text-decoration: none;
	padding: 0 10px 8px 10px;
	border-bottom: 2px solid transparent
}

@media (max-width: 479px) {
ul.tabs li a {
	font-size: 13px;
	white-space: nowrap
}
}
ul.tabs li a.active {
	border-bottom: 2px solid #0080FF;
	color: #0080FF
}

@media (max-width: 576px) {
.deal-text {
	font-size: 12px
}
}
/**************main_body_css****************/

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	outline: none
}
input, button {
	font-family: "Poppins", sans-serif
}
focus {
	outline: none
}
body {
	font-family: "Poppins", sans-serif;
	font-size: 14px
}
#cntrmain {
	overflow: hidden;
	
}
.clearfix2 {
	clear: both;
	margin: 0px;
	padding: 0px
}
.container2 {
	max-width: 1240px;
	margin: 0px auto
}

@media (max-width: 1023px) {
.container2 {
	max-width: 100%;
	padding: 0px 10px
}
}
.container-fluid {
	padding-left: 10px;
	padding-right: 10px
}
.resultsbg {
	background: #F9F9F9
}
.resultsbg .container2 {
	max-width: 1320px;
	margin: 0px auto
}

@media (max-width: 1023px) {
.resultsbg .container2 {
	max-width: 100%;
	padding: 0px 10px
}
}
.resultsDetailBody {
	background: #F9F9F9
}
/*# sourceMappingURL=style.css.map */

.destination2 img {
    transition: .5s;
	border-radius: 5px;
}
.destination2 a:hover img {
    transform: scale(1.1);
	border-radius: 5px;
}
/*** Package ***/
.package-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}
.package-item img {
    transition: .5s;
}
.package-item:hover img {
    transform: scale(1.1);
}

/************leaving-x-From*************/

.leavto2 input[type="text"] {
	box-sizing: border-box;
	width: 100%;
	height: calc(54px + 2px);
	margin: 0 0 1em;
	padding: 13px 10px 16px 30px;
	border: 1px solid #ff660061;
	border-radius: 3px;
	background: #fff;
	font-size: 14px;
	resize: none;
	outline: none;
	padding-left: 30px;
	}

	.leavto2 input[type="text"]:focus {
	border-color: #ff6600;
	}

	.leavto2 input[type="text"]:focus + label[placeholder]:before {
	display: none;
	}

	/* .leavto2 input[type="text"]:focus + label[placeholder]:before,
	.leavto2 input[type="text"]:valid + label[placeholder]:before {
	transition-duration: .2s;
	transform: translate(0, -1.6em) scale(0.9, 0.9);
	font-weight: bold;
	padding: 1px 5px 0px 5px;
	} */

	.leavto2 input[type="text"]:valid {
	border-color: #ff660061;
	}

	/* .leavto2 input[type="text"]:valid + label[placeholder]:before {
	color: #ff6600;
	} */

	.leavto2 input[type="text"]:invalid + label[placeholder][alt]:before {
	content: attr(alt);
	}

	.leavto2 input[type="text"] + label[placeholder] {
	display: block;
	pointer-events: none;
	line-height: 1.25em;
	margin-top: calc(-55px - 2px);
	margin-bottom: calc((3em - 1em) + 2px);
	color: #ff6600;
	}

	.leavto2 input[type="text"] + label[placeholder]:before {
	content: attr(placeholder);
	display: inline-block;
	margin: 0 calc(0.5em + 2px);
	color: #0d6efd;
	white-space: nowrap;
	transition: 0.3s ease-in-out;
	background-image: linear-gradient(to bottom, #ffffff, #ffffff);
	background-size: 100% 5px;
	background-repeat: no-repeat;
	background-position: center;
	padding: 3px 20px 0px;
	}

/**********RoundxTrip**********/

.wrapper2 {
	display: inline-flex;
	height: 34px;
	width: 346px;
	align-items: center;
	border-radius: 5px;
	margin-bottom: 20px;
	}

	.wrapper2 .option {
	background: #fff;
	height: 100%;
	/*width: 100%;*/
	display: flex;
	align-items: center;
	margin: 0 1px;
	border-radius: 5px;
	cursor: pointer;
	padding: 0 10px;
	border: 1px solid #ff660061;
	transition: all 0.3s ease;
	}

	.wrapper2 .option .dot {
	height: 20px;
	width: 20px;
	background: #d9d9d9;
	border-radius: 50%;
	position: relative;
	}

	.wrapper2 .option .dot::before {
	position: absolute;
	content: "";
	top: 4px;
	left: 4px;
	width: 12px;
	height: 12px;
	background: #0069d9;
	border-radius: 50%;
	opacity: 0;
	transform: scale(1.5);
	transition: all 0.3s ease;
	}

	input[type="radio"] {
	display: none;
	}

	#option-1:checked:checked ~ .option-1,
	#option-2:checked:checked ~ .option-2 {
	border-color: #0080ff;
    background: #0080ff;
	}

	#option-1:checked:checked ~ .option-1 .dot,
	#option-2:checked:checked ~ .option-2 .dot {
	background: #fff;
	}

	#option-1:checked:checked ~ .option-1 .dot::before,
	#option-2:checked:checked ~ .option-2 .dot::before {
	opacity: 1;
	transform: scale(1);
	}

.wrapper2 .option span {
	font-size: 13px;
	color: #808080;
	}

	#option-1:checked:checked ~ .option-1 span,
	#option-2:checked:checked ~ .option-2 span {
	color: #fff;
	}

/***********BestxDeals****************/
	.deals-bg {
	float: left;
	width: 100%;
	background: #f9fafb;
	padding: 17px 0 35px;
	}

	.pt-5 {
	padding-top: 3rem !important;
	}

	.mb-5 {
	margin-bottom: 3rem !important;
	}

	.project-item2 {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 0 45px rgba(0, 0, 0, .07);
	}

	.project-item2 .project-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, .5);
	opacity: 0;
	padding-top: 60px;
	transition: .5s;
	}

	.project-item2:hover .project-overlay {
	opacity: 1;
	padding-top: 0;
	}

	.project-carousel .owl-nav {
	position: absolute;
	top: -55px;
	right: 0;
	display: flex;
	}

	.project-carousel .owl-nav .owl-prev,
	.project-carousel .owl-nav .owl-next {
	margin-left: 15px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--secondary);
	border-radius: 55px;
	box-shadow: 0 0 45px rgba(0, 0, 0, .15);
	font-size: 20px;
	transition: .5s;
	}

	.project-carousel .owl-nav .owl-prev:hover,
	.project-carousel .owl-nav .owl-next:hover {
	background: var(--primary);
	color: #fff;
	background-color: #0080FF;
	border-color: #0080FF;
	}
	@media (max-width: 768px) {
	.project-carousel .owl-nav {
	top: -35px;
	right: auto;
	left: 85%;
	transform: translateX(-50%);
	}

	.project-carousel .owl-nav .owl-prev,
	.project-carousel .owl-nav .owl-next {
	margin: 0 7px;
	width: 30px;
	height: 30px;
	font-size: 12px;
	}
	}
	
	.project-item2 .deals_img {
	position: relative;
	transition: all .7s;
	}

	.project-item2 .deals_heading2 {
	position: absolute;
	width: 100%;
	z-index: 9;
	padding: 0 14px;
	background-color: #24425ebd;
	bottom: 0px;
	padding-bottom: 8px;
	text-align: center;
	padding-top: 8px;
	}

	.deals_img:hover .deals_heading2 {
	background-color: aqua;
	}


	.deals_heading2 p {
	font-size: 15px !important;
	color: aliceblue;
	margin: 0;
	font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
	text-shadow: 1px 2px 2px #222;
	}

	.deals_heading2 span {
	font-size: 20px;
	color: white;
	margin-bottom: 10px;
	}
/***********BestxDeals*close***************/
