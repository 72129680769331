.aboutus {
    font-family: Arial, Helvetica, sans-serif;
    width: 94%;
    max-width: 1150px;
    margin: auto;
    color: #303030;
    margin-top: 55px;
    font-size: 15px;
    padding: 20px 0;
}
.blog-headPost {
    background-color: rgba(0, 33, 66, .4);
    color: #fff;
    height: 200px;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    text-shadow: 1px 2px 2px #222;
    
    background-position: center;
    background-size: cover;
}