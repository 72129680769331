p { letter-spacing:0.5px; line-height:22px }
ul li { letter-spacing:0.5px; line-height:22px; margin-bottom:5px; }
.blog-head {
    background-color: rgba(0, 33, 66, .4);
    color: #fff;
    height: 200px;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 40px;
    text-shadow: 1px 2px 2px #222;
    background-image: url(../images//about&term/terms-and-conditions-2-option.webp);
    background-position: center;
    background-size: cover;
}