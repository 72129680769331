@charset "utf-8";
/* CSS Document */
.dels-sction { width:100%; max-width:100%; margin-top: 0px;}
.best-cntnr { max-width:1150px; margin:0 auto;}	
.best-cntnr h1, h2, h3, h4 { text-align: left !important;}	
.best-cntnr p {letter-spacing:.5px; line-height:22px; color:#838383; text-align:justify; margin-bottom: 15px; }	

.full-width { width: 100%; float: left; margin-bottom: 20px;}	
	
.col-first {
  width: 75%;
  margin: 10px 0.65%;
  float: left;
  margin-top: 81px;
}

.col-first ul {padding-left: 18px;}
.col-first ul li { font-size: 17px; line-height: 22px;
  color: #838383;}

.col-first h1,h2,h3,h4 { margin-top: 0px !important;}	
.col-last {
  width: 22%;
  margin: 10px 0.65%;
  float: left;
}
	
.sidebox {margin-bottom: 15px;}
.sidebox img {width: 100%; border: 1px solid #615e5e;}
	
.side_airs {margin-bottom: 15px; margin-top: 30px;}
.side_airs h4 { margin-bottom: 10px;}	
.side_airs ul { margin: 0px; padding: 0px;}
.side_airs ul li { margin: 0px; padding: 0px; list-style-type: none;}
.side_airs ul li a {text-decoration: none; border-bottom: 1px solid #ddd; padding: 5px 5px; font-size: 16px;
  color: #4b4c4c; display: flex; justify-content: space-between; margin-bottom: 5px;}
	

.cntrbox {display: flex; justify-content: space-between;}
.middle-img { float: left; width: 49%;}
.middle-img img { width: 100%; border: 1px solid #615e5e;}	
.threeimg {margin-bottom: 20px; display: flex; justify-content: space-between;}
.threeimg img {width: 100%;}
	
	
@media only screen and (max-width:767px){
.best-cntnr { width:100%; padding:0 15px; }
	
.col-first {
  width: 100% !important;
  margin: 10px 0.65%;
  float: left;
}
.col-last {
  width: 100% !important;
  margin: 10px 0.65%;
  float: left;
}
	
.bottomright {  
  width: 68% !important;	
}	
       
}

/* Style the tab */
.tabs_heading { padding: 20px; color: #fff; border-bottom: 1px solid #fff; font-size: 22px;}
.tabgcolor { background-color: #f6f6f6; padding: 15px;}	
	
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #2276b2;
  width: 25%;
  min-height: 430px;
  max-height: 100%;
}

.tab button {
  display: block;
  background-color: inherit;
  color: #fff;
  padding: 12px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
}

.tab button.selected {
  display: block;
  background-color: #18b1ed;
  color: #fff;
  padding: 12px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
}




.tab button:hover {
  background-color: #31bbf2;
}


.tab button.active {
  background-color: #18b1ed;
}

.tabcontent {
  float: left;
  padding: 0px 12px;
  width: 75%;
  border-left: none;
  max-height: 100%;	
}
.citydate h3 {color: #fff;}
.citydate p {color: #fff;}

.maintabs { position: relative;}

.bottomright {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 18px;
  background-color: #2c2c2d87;
  padding: 10px;
  width: 35%;	
}
.dismer { font-size: 14px;}
.dismer span { color: #2276b2;}	
	
/*********faer***********/	
.cityfaremain {display: flex;}
.city-fare {padding: 0px 10px;}
.city-fare p { margin: 0px; font-size: 16px; color: #fff;}	
	
.citydate {padding: 0px 10px; border-right: 1px solid #fff;}
.citydate p { margin: 0px; font-size: 16px;}
	
.linkstab img {width: 12px; height: 13px; }
.tbarow img {width: 12px; height: 19px; float: right;}

/**********/
.links-row .socAdd p i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
}


